import { Component } from '@angular/core';
import { OperationService } from 'src/app/shared/service/operation/operation.service';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';

@Component({
  selector: 'app-listing-site',
  templateUrl: './listing-site.component.html',
  styleUrl: './listing-site.component.scss'
})
export class ListingSiteComponent {

  siteItems:any =[];
  user_reference:string;
  isLoading:boolean = false;
  constructor(
    private OperationService:OperationService,
    private localStorage:LocalStorageService
  ){
    this.user_reference = this.localStorage.getUsers()?.data?.user?.reference;

  }

  ngOnInit(){
    this.getSite();
  }
  getSite(){
    this.isLoading = true;
    const requestData={
      "user_reference":this.user_reference
    }
    this.OperationService.getSite(requestData).subscribe(
      (response:any)=>{
        this.isLoading = false;
        this.siteItems = response.data
        console.log("la reponse du webservice",this.siteItems);
        
      },
      (error)=>{
        console.log("une erreur est survenu",error);
        
      },
    )
  }
}
