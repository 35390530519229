<header style="background-color: #F2E6D6;" id="header" class="header sticky-top">
  <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0 d-flex align-items-center">
    <!-- Logo à gauche -->
    <a routerLink="/suivi/public" href="javascript: void(0);" class="navbar-brand p-0">
      <img style="height: 100px;" src="../../../assets/images/zero.png" alt="Logo">
    </a>

    <!-- Bouton Burger pour Mobile -->
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="fa fa-bars"></span>
    </button>

    <!-- Liens à droite -->
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <div class="navbar-nav ms-auto py-0">
        <a href="javascript: void(0);" class="nav-item nav-link" id="grass"> Accueil</a>
        <a href="javascript: void(0);" class="nav-item nav-link" routerLink="/signup"  id="grass">S'inscrire</a>
      </div>
      <!-- Bouton Se connecter -->
      <a routerLink="/login" href="javascript: void(0);" class="btn py-2 px-4 ms-lg-3"
        style="background-color: #6A00B8; color: white;" id="grass">Se connecter</a>
    </div>
  </nav>
</header>

<section style="background-color: #F2E6D6; height: auto;" id="hero" class="section hero light-background px-2">
  <div class="container">
    <div class="row gy-4 d-flex flex-column-reverse flex-lg-row align-items-center justify-content-between">
      <!-- Texte -->
      <div class="col-12 col-lg-6 d-flex flex-column justify-content-center text-left text-lg-start">
        <h1 class="mb-3">Transformez votre <br>vision en réalité.</h1>
        <p style="color: black; font-size: 20px; margin-bottom: 1.5rem;">
          Nous vous accompagnons gratuitement sur vos projets les plus ambitieux, avec une rémunération basée uniquement
          sur les résultats. Ensemble, faisons de votre succès notre priorité et relevons chaque défi avec détermination
          !
        </p>
        <div class="d-flex justify-content-between justify-content-lg-start">
          <a routerLink="/login" href="javascript: void(0);" class="btn py-2 px-4"
            style="background-color: #6A00B8; color: white;">Se connecter</a>
        </div>
      </div>

      <!-- Image -->
      <div class="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end">
        <img src="../../../assets/images/space-rocket-launch-start-shuttle.png" class="img-fluid animated w-75 w-lg-100"
          alt="Rocket Launch">
      </div>
    </div>
  </div>
</section>