import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { map, filter, scan } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { LocalStorageService } from '../storage/localstorage.service';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})

export class ParameterService {


  constructor(private http: HttpClient) {

  }

//collecte prive
  getPays(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'params/pays'}`);
  }

  getvilleByPays(id:any): Observable<any> {
    return this.http.get(environment.apiUrl + `params/villes/${id}`);
  }
  addProjet(item: any): Observable<any> {
    //console.log("webservice");

    return this.http.post(environment.apiUrl + 'operation/projet', item);
  }
  editProjet(id: any, item: any): Observable<any> {
    return this.http.post(environment.apiUrl + `operation/change-projet/${id}`, item);
  }

  //end
//collecte public

getStatistiqueProjet(item:any): Observable<any> {
    return this.http.post(`${environment.apiUrl + 'params/statistiques'}`,item);
  }
  //user
getUserNonVerife(item:any): Observable<any> {
  return this.http.post(`${environment.apiUrl + 'admin/users-not-verified'}`,item);
}

//validate user

validateUser(est_valide: any ,reference:any): Observable<any> {

  const requestData = {
    "user_id" :reference,
    "est_valide":est_valide
  }
  return this.http.post(environment.apiUrl + `admin/users-validation`,requestData);
}
//end

}



//end

