<!-- Hero Section -->
<app-public-header></app-public-header>
<br><br>
<section id="about" class="section about">

    <div class="container">

        <div class="row gy-3">
            <!-- Colonne de gauche avec l'image -->
            <div class="col-lg-6 d-flex flex-column text-center px-4">
              <div class="about-content">
                <img src="../../../assets/images/imageZII.png" alt="" class="img-fluid">
              </div>
            </div>
          
            <!-- Colonne de droite avec le texte et le bouton aligné à droite -->
            <div class="col-lg-6 d-flex flex-column justify-content-center">
              <h3 style="font-weight: bold; margin-bottom: 25px;">ACCOMPAGNEMENT</h3>
          
              <p class="text-left" style="font-size: 20px;">
                Explication des services offerts : conseils stratégiques, accompagnement financier, mise en relation avec des banques partenaires et fonds privés.
              </p>
          
              <!-- Bouton aligné à droite -->
              <div class="d-flex justify-content-end">
                <a routerLink="/login" href="javascript: void(0);" class="btn py-2 px-4" 
                   style="background-color: #6A00B8; color: white; width: 180px;">Découvrir</a>
              </div>
            </div>
          </div>
          
    </div>


</section><!-- /About Section -->
<br><br>
<!-- Services Section -->
<section class="how-it-works text-center py-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2 class="section-title">Comment ça marche ?</h2>
                <p class="section-description">
                    Chez Zéro Investissement, nous comprenons que chaque projet est unique et nécessite un
                    accompagnement adapté.
                    C’est pourquoi nous offrons quatre options de soumission de projet, chacune conçue pour répondre à
                    des besoins spécifiques.
                </p>
                <p class="project-options">
                    <strong>( Ma Franchise - Incubateur - Mon Site Web/Appli - Marketplace )</strong>
                </p>
                <a href="javascript: void(0);" routerLink="/login" class="btn btn-primary btn-purple mt-3">
                    Soumettre ma candidature <span class="arrow">→</span>
                </a>
            </div>
        </div>
    </div>
</section>

<br><br>
<!-- Services Section -->
<section id="services" class="services section light-background">
    <section class="testimonials py-5">
        <h2 class="d-flex justify-content-center mb-3" id="grass">Faites comme eux !</h2><br>

        <div class="container">
            <p-carousel [value]="testimonials" [numVisible]="3" [numScroll]="3" class="custom-carousel"
                [circular]="true" [responsiveOptions]="responsiveOptions">
                <ng-template pTemplate="item" let-testimonial>
                    <div class="testimonial-card uniform-card p-4">
                        <div class="testimonial-quote">
                            <i class="fa fa-quote-left"></i>
                        </div>
                        <p class="testimonial-text">
                            {{ testimonial.text }}
                        </p>
                        <hr>
                        <div class="testimonial-author d-flex align-items-center">
                            <img [src]="testimonial.img" [alt]="testimonial.name" class="testimonial-author-img">
                            <div class="author-details ms-3">
                                <h5>{{ testimonial.name }}</h5>
                                <p>{{ testimonial.role }}</p>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-carousel>

        </div>
    </section>
</section>


<br><br>
<app-public-footer></app-public-footer>