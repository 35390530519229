import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';

@Component({
  selector: 'app-soumission-projet',
  templateUrl: './soumission-projet.component.html',
  styleUrl: './soumission-projet.component.scss'
})
export class SoumissionProjetComponent {
  statsProjet: any[];
  user_reference: any;
  constructor(private router :Router,
    private paramService :ParameterService,
    private localStorage:LocalStorageService 
  )
  {
    this.user_reference = this.localStorage.getUsers()?.data?.user?.reference;

  }
  
  ngOnInit(): void {
   

    this.getStatistiqueProjet(this.user_reference);
  }

  goToSoumission(){

    this.router.navigate(['/page/soumission']);
  }


  getStatistiqueProjet(reference:any){

    this.paramService.getStatistiqueProjet(reference).subscribe(
      (response:any)=>{
        console.log("la reponse du webservie",response);
        
      },
      (error)=>{
        console.log("une errer est surven ",error);
        
      }
    )
    
  }
}
