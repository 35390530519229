import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }


  login(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'auth/login', item);
  }
  checkEmail(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'auth/identite', item);
  }


  resetverifyEmail(item:any): Observable<any>{
    return this.http.post(environment.apiUrl + 'auth/url-verify ',item);
  }

  register(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'auth/register', item);
    // return this.http.post(environment.apiUrl + 'signin',item);
  }


  reset(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + `auth/reset-password`, item);
  }



}
