import { Component } from '@angular/core';

@Component({
  selector: 'app-public-dashboard',
  templateUrl: './public-dashboard.component.html',
  styleUrl: './public-dashboard.component.scss'
})
export class PublicDashboardComponent {

  testimonials: any[] = [
    {
      text: "J'avais toujours rêvé de faire grandir mon restaurant en franchise, mais je n'avais ni le réseau ni les fonds pour le faire.",
      img: '../../../assets/images/profil.png',
      name: 'Oberon Shaw, MCH',
      role: 'Créateur de la franchise BistroNomique'
    },
    {
      text: "Grâce à Zéro Investissement, j'ai pu transformer une simple idée en un projet concret et rentable.",
      img: '../../../assets/images/profil.png',
      name: 'Marion D.',
      role: 'Fondatrice de GreenWave (Startup écologique)'
    },
    {
      text: "Zéro Investissement a pris en charge toute la création de mon appli, depuis la conception jusqu'au lancement.",
      img: '../../../assets/images/profil.png',
      name: 'Oberon Shaw, MCH',
      role: 'Fondateur de TechZen (Application mobile)'
    }
  ];  responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '768px',
      numVisible: 2,
      numScroll: 2
    },
    {
      breakpoint: '560px',
      numVisible: 1,
      numScroll: 1
    }
  ];
}
