import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from '../../../core/services/auth.service';
import { first } from 'rxjs/operators';
import { UserProfileService } from '../../../core/services/user.service';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { registerUser } from 'src/app/shared/models/register';
import { MessageService } from 'primeng/api';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ParameterService } from 'src/app/shared/service/params/param';
import intlTelInput from 'intl-tel-input';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, AfterViewInit {

  signupForm: UntypedFormGroup;
  submitted = false;
  error = '';
  successmsg = false;
  step1Form: FormGroup;
  step2Form: FormGroup;
  currentStep = 1; 
  paysItems :any =[];
  villeItems :any =[];
  item : registerUser = new registerUser();
  passwordFieldType: string = 'password';
  passwordFieldTypeConfirm: string = 'password';

  constructor(
     private route: ActivatedRoute,
     private router: Router, 
     private authService:AuthService,
     private messageService :MessageService,
     private ngxService : NgxUiLoaderService,
     private paramService :ParameterService

   ) { }

  


  @ViewChild('numeroInput') numeroInput!: ElementRef;
  codeDial: string = '';

  ngAfterViewInit(): void {
    const inputElement = this.numeroInput.nativeElement;

    // Initialise intlTelInput avec l'écouteur d'événement pour récupérer l'indicatif
    const iti = intlTelInput(inputElement, {
      initialCountry: 'fr', // france par défaut
      separateDialCode: true,
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/19.2.12/js/utils.js'
    });

    // Récupérer l'indicatif initial du pays sélectionné
    this.codeDial = iti.getSelectedCountryData().dialCode;
    console.log('Indicatif initial du Bénin:', this.codeDial);

    // Ajout d'un écouteur pour les changements de pays
    inputElement.addEventListener('countrychange', () => {
      this.codeDial = iti.getSelectedCountryData().dialCode;
      console.log('Indicatif du pays sélectionné:', this.codeDial);
    });
  }
 

  ngOnInit() {

    this.getPays();

  }
  getPays(){

    this.paramService.getPays().subscribe(

      (response:any)=>{
        console.log("la reponse du webservice",);
        this.paysItems = response.data
      },
      (error)=>{
        console.error("une erreur est survenu",error);
        
      }
    )
  }
  onChangePays(event: any) {
    const selectedPaysId = event.target.value; 
    this.getVilleByPays(selectedPaysId);  
  }
  getVilleByPays(paysId:number){

    this.paramService.getvilleByPays(paysId).subscribe(
      (response:any)=>{
        this.villeItems = response.data;
        console.log("une erreur est survenu",response);
        
      },
      (error)=>{
        console.log("une erreur est survenu",error);
        
      }
    )
  }
  onSubmit(){

    if (!this.item.canSend()) {
      this.messageService.add({ severity: 'info', summary: 'zeroInvestissement', detail: this.item.getErrorMessage() });
      return;
    }

    if (this.item.canSend()) {
      this.ngxService.start();

      this.authService.register(this.item).subscribe(

        (response:any)=>{
          this.messageService.add({ severity: 'success', summary: 'zeroInvestissement', detail: response.message });
          this.ngxService.stop();
          this.router.navigate(['/login']);

          console.log("la reponse du web service", response.message);
          
        },
        (error)=>{
          console.log("une erreur est survenu",error);
          this.messageService.add({ severity: 'error', summary: 'zeroInvestissement', detail: error });
          this.ngxService.stop();
        }
      )
    }
  }

  togglePasswordVisibility() {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  }
  togglePasswordVisibilityConfirm() {
    this.passwordFieldTypeConfirm = this.passwordFieldTypeConfirm === 'password' ? 'text' : 'password';
  }
}
