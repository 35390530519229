
export class MarketPlaceBusiness {

    description_rapide :any
    attrait_business :any
    autre_attrait_business:any
    representation_business_animal:any
    niveau_conviction:any
    source_enthousiasme:any
    autre_source_enthousiasme:any
    motivation_entrepreneuriat :any
    autre_motivation_entrepreneuriat :any
    motivation_principale:any
    autre_motivation_principale:any
    approche_entrepreneuriale :any
    cle_reussite:any
    planification_achat:any
    source_financement:any
    budget_maximum:any
    option_financement_zi:any
    premiere_action_demarrage:any
    autre_premiere_action_demarrage:any
    canaux_marketing:any
    autre_canaux_marketing:any
    objectif_principal_12_mois:any
    autre_objectif_principal_12_mois:any
    budget_publicitaire:any
    choix_priorite:any
    autre_choix_priorite:any
    besoin_formation:any
    modalite_formation:any
    competences_necessaires:any
    super_pouvoir:any
    autre_competences_necessaires:any
    type_suivi:any
    disponibilite_hebdomadaire:any
    seuil_temps_rentabilite:any
    disponibilite_plan_croissance:any
    question_mentor_entrepreneur:any
    titre_ouvrage_succes:any
    premiere_action_succes:any
    user_reference:any
    


    private errorMessage: string;

    constructor() {
        this.errorMessage = "";
    }

    canSend(): boolean {
        // Vérification des champs obligatoires
        if (!this.description_rapide || this.description_rapide.trim() === "") {
            this.errorMessage = "Quel business avez-vous choisi sur la marketplace ? (Nom et description rapide)";
            return false;
        }

        if (!this.attrait_business || this.attrait_business.trim() === "") {
            this.errorMessage = "Qu’est-ce qui vous a attiré dans ce business en particulier ?";
            return false;
        }

        // if (!this.autre_attrait_business || this.autre_attrait_business.trim() === "") {
        //     this.errorMessage = "";
        //     return false;
        // }

        if (!this.representation_business_animal || this.representation_business_animal.trim() === "") {
            this.errorMessage = "Si ce business clé en main était un animal, lequel serait-il et pourquoi ?";
            return false;
        }

        if (!this.niveau_conviction || !this.niveau_conviction) {
            this.errorMessage = "Sur une échelle de 1 à 10, à quel point êtes-vous convaincu que ce business est fait pour vous ?";
            return false;
        }

        if (!this.source_enthousiasme || this.source_enthousiasme.trim() === "") {
            this.errorMessage = "Qu’est-ce qui vous enthousiasme le plus à l’idée de prendre en main ce business ?";
            return false;
        }

        // // Vérification de l'excitation
        // if (!this.autre_source_enthousiasme || this.autre_source_enthousiasme.trim() === "") {
        //     this.errorMessage = "Votre entreprise cartonne, mais quelle est la plus grande réussite dont vous êtes fier ?";
        //     return false;
        // }

        // Vérification du plan B
        if (!this.motivation_entrepreneuriat || this.motivation_entrepreneuriat.trim() === "") {
            this.errorMessage = "Pourquoi avez-vous décidé de vous lancer dans l'entrepreneuriat ?";
            return false;
        }

        // Vérification du mode de rentabilité
        if (!this.autre_motivation_entrepreneuriat) {
            this.errorMessage = "Qu’est-ce qui vous motive le plus dans ce business clé en main spécifique ?";
            return false;
        }

        // // Vérification du public cible
        if (!this.motivation_principale) {
            this.errorMessage = "Pourquoi avez-vous décidé de vous lancer dans l'entrepreneuriat ?"
        }
        // if (!this.autre_motivation_principale) {
        //     this.errorMessage = "";
        //     return false;
        // }
        if (!this.approche_entrepreneuriale) {
            this.errorMessage = "Si vous deviez décrire votre approche entrepreneuriale en 3 mots, ce serait…";
            return false;
        }


        // Vérification de la stratégie marketing
        if (!this.cle_reussite || this.cle_reussite.trim() === "") {
            this.errorMessage = "Qu’est-ce qui, selon vous, va vous permettre de réussir avec ce business ?";
            return false;
        }

        // Vérification de la plateforme sociale
        if (!this.planification_achat || !this.planification_achat) {
            this.errorMessage = "Avez-vous déjà planifié comment financer l’achat de ce business clé en main ?";
            return false;
        }

        // Vérification de l'engagement
        if (!this.source_financement) {
            this.errorMessage = "Si vous avez besoin de financement, comment pensez-vous y accéder ?";
            return false;
        }

        //  Vérification des disponibilités
        if (!this.budget_maximum) {
            this.errorMessage = "Quel est votre budget maximum pour l’achat de ce business ? ";
            return false;
        }

        // Vérification du soutien
        if (!this.option_financement_zi) {
            this.errorMessage = "Seriez-vous intéressé par une option de financement proposée par ZeroInvestissement.com ?";
            return false;
        }

        if (!this.premiere_action_demarrage) {
            this.errorMessage = "Quelle est la première action que vous allez entreprendre une fois le business acquis?";
            return false;
        }
        // if (!this.autre_premiere_action_demarrage) {
        //     this.errorMessage = "Quelles sont les valeurs clés de votre entreprise ? ";
        //     return false;
        // }

        if (!this.canaux_marketing) {
            this.errorMessage = "Comment comptez-vous faire connaître ce business à vos futurs clients ?";
            return false;
        }

        // if (!this.autre_canaux_marketing) {
        //     this.errorMessage = "En quoi ces valeurs seront-elles essentielles pour vos futurs franchisés ?";
        //     return false;
        // }
        if (!this.objectif_principal_12_mois) {
            this.errorMessage = "Que recherchez-vous chez un futur franchisé ?";
            return false;
        }

        // if (!this.autre_objectif_principal_12_mois) {
        //     this.errorMessage = "Quel est votre objectif principal avec ce business clé en main dans les 12 prochains mois ?";
        //     return false;
        // }
        if (!this.budget_publicitaire) {
            this.errorMessage = "Quel budget publicitaire initial prévoyez-vous pour ce business ?";
            return false;
        }

        if (!this.choix_priorite) {
            this.errorMessage = "Si vous pouviez donner un coup de boost immédiat à une partie du business, laquelle serait-ce ?";
            return false;
        }
        // if (!this.autre_choix_priorite) {
        //     this.errorMessage = "";
        //     return false;
        // }
        if (!this.besoin_formation) {
            this.errorMessage = ">Avez-vous besoin d'une formation pour mieux comprendre le secteur du business que vous avez choisi ?";
            return false;
        }

        if (!this.modalite_formation) {
            this.errorMessage = "Comment préférez-vous suivre la formation associée à ce business ?";
            return false;
        }

        if (!this.competences_necessaires) {
            this.errorMessage = "Quelles compétences spécifiques aimeriez-vous développer avant de vous lancer";
            return false;
        }

        // if (!this.autre_competences_necessaires) {
        //     this.errorMessage = "";
        //     return false;
        // }

        if (!this.type_suivi) {
            this.errorMessage = "Quel type de suivi attendez-vous de ZeroInvestissement.com après le lancement ?";
            return false;
        }



        if (!this.disponibilite_hebdomadaire) {
            this.errorMessage = "Combien de temps par semaine êtes-vous prêt à consacrer au développement de ce business ?";
            return false;
        }
        if (!this.seuil_temps_rentabilite) {
            this.errorMessage = "Combien de temps vous donnez-vous pour rendre ce business rentable";
            return false;
        }
        if (!this.disponibilite_plan_croissance) {
            this.errorMessage = "Avez-vous un plan de croissance pour ce business une fois lancé ?";
            return false;
        }
        if (!this.question_mentor_entrepreneur) {
            this.errorMessage = "Si vous aviez accès à un mentor entrepreneur, que lui demanderiez-vous en priorité ?";
            return false;
        }

        if (!this.super_pouvoir) {
            this.errorMessage = "Si votre business clé en main avait un superpouvoir, lequel serait-il et pourquoi ?";
            return false;
        }

        if (!this.titre_ouvrage_succes) {
            this.errorMessage = "Quel serait le titre d’un livre sur votre aventure entrepreneuriale avec ce business ?";
            return false;
        }

        if (!this.premiere_action_succes) {
            this.errorMessage = "Imaginez que dans 5 ans, votre business est un énorme succès. Quelle serait la première chose que vous feriez ?";
            return false;
        }
        // Si toutes les vérifications sont passées
        this.errorMessage = ""; // Réinitialiser le message d'erreur
        return true;
    }

    getErrorMessage(): string {
        return this.errorMessage;
    }
}
