<footer class="footer">
  <div class="container">
    <div class="row align-items-center"> <!-- Ajout d'align-items-center -->
      <!-- Section gauche : Logo et description -->
      <div class="col-lg-4 col-md-6">
        <img src="../../../assets/images/zero.png" alt="Zero Investissement" class="footer-logo "> <!-- Ajout de mb-3 pour espacement -->
        <p>
          Zeroinvestissement est la première plateforme dédiée à propulser les projets d'entrepreneurs sans frais d'entrée.
        </p>
      </div>

      <!-- Section centrale : Liens -->
      <div class="col-lg-4 col-md-6">
        <h5 class="mb-3">Liens</h5> <!-- Ajout de mb-3 pour espacement -->
        <ul class="footer-links">
          <li><a style="font-size: 20px;"routerLink="/login" href="javascript: void(0);">Se connecter</a></li>
          <li><a style="font-size: 20px;"routerLink="/signup"  href="javascript: void(0);">S'inscrire</a></li>
          <li><a style="font-size: 20px;" href="javascript: void(0);">Centre d'aide</a></li>
        </ul>
      </div>

      <!-- Section droite : Appel à l'action -->
      <div class="col-lg-4 col-md-12 text-lg-right text-md-left"> <!-- Ajout de text-lg-right et text-md-left pour aligner sur grand écran -->
        <h5 class="mb-3">Prêt à transformer votre projet ?</h5> <!-- Ajout de mb-3 -->
        <p>Vous êtes à un clic de faire de votre vision une réalité.</p>
        <a routerLink="/login" href="javascript: void(0);"class="btn btn-dark">Se connecter <span class="arrow">→</span></a>
      </div>
    </div>

    <div class="footer-bottom text-center">
      <hr>
      <p style="font-size: 20px;">©2024 ZeroInvestissement</p>
    </div>
  </div>
</footer>
