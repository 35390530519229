<div class="container-fluid ">
    <h4 id="upper" class="titre"> Questionnaire 2 POLE Marketplace Business Clé en Main

    </h4>
    <div>
      <button routerLink="/page/liste-marketplace-business" type="button" id="retour" class="btn btn-secondary"
      style="right: 10px; width: 100px; position: fixed; z-index: 1000;">
      <i class="pi pi-arrow-left" style="color: white; font-size: 0.8rem;"></i> Retour
  </button>
    </div>

    <html lang="fr">

    <body>
        <p id="paragraphe" class="text-left">
            Bienvenue dans l’univers <strong>Marketplace Achat</strong> de <strong>ZeroInvestissement.com</strong> !
            Vous êtes à deux doigts de trouver le
            <strong>business clé en main</strong> qui vous correspond parfaitement sur notre marketplace. Avec des
            concepts validés et
            testés, vous avez l’opportunité de choisir un projet déjà prêt à l’emploi. Mais nous ne faisons pas que
            vendre des business prêts à l'emploi, nous sélectionnons soigneusement chaque candidature. Notre objectif
            est de travailler main dans la main avec les entrepreneurs sélectionnés. Une fois votre projet validé, nous
            vous accompagnons tout au long de l’aventure, en nous concentrant particulièrement sur l’aspect commercial
            et la co-gestion partielle du business. Ensemble, nous maximisons les revenus générés et nous nous
            rémunérons uniquement via un pourcentage sur ces revenus. Quand vous gagnez, nous gagnons ensemble ! <br>
            Répondez à ces questions pour que nous puissions mieux comprendre pourquoi vous avez choisi ce business et
            comment nous pouvons vous aider à maximiser vos chances de succès !



        <form>
            <h2><span class="emoji">🚀</span>Pourquoi avoir choisi ce Business Clé en Main ?
            </h2>
            <div class="question">
                <label for="projet-nom">Quel business avez-vous choisi sur la marketplace ? (Nom et description
                    rapide)</label>
                <textarea [(ngModel)]="item.description_rapide" class="form-control" type="text" id="projet-nom"
                    name="projet-nom" rows="3"></textarea>
            </div>
            <div class="question">
                <label>Qu’est-ce qui vous a attiré dans ce business en particulier ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.attrait_business" type="radio" id="secteur-activite"
                        name="attrait_business" value="Le secteur d’activité" class="me-2">
                    <label for="secteur-activite">Le secteur d’activité</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.attrait_business" type="radio" id="modele-affaires" name="attrait_business"
                        value="Le modèle d’affaires" class="me-2">
                    <label for="modele-affaires">Le modèle d’affaires</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.attrait_business" type="radio" id="potentiel-rentabilite"
                        name="attrait_business" value="Le potentiel de rentabilité" class="me-2">
                    <label for="potentiel-rentabilite">Le potentiel de rentabilité</label>
                </div>

                <div class=" mb-2">
                    <label for="autre">Autre :</label>
                    <textarea [(ngModel)]="item.autre_attrait_business" class="form-control ms-2" rows="3" type="text"
                        id="autre-input" name="autre_attrait_business"></textarea>
                </div>
            </div>

            <div class="question">
                <label for="secteur">Si ce business clé en main était un animal, lequel serait-il et pourquoi ?
                </label>
                <textarea [(ngModel)]="item.representation_business_animal" class="form-control" type="text"
                    id="secteur" name="projet-nom" rows="3"></textarea>
            </div>

            <div class="question">
                <label>Sur une échelle de 1 à 10, à quel point êtes-vous convaincu que ce business est fait pour vous
                    ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.niveau_conviction" type="radio" id="conviction1" name="niveau_conviction"
                        value="1" class="me-2">
                    <label for="conviction1">1 : Juste curieux</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.niveau_conviction" type="radio" id="conviction5" name="niveau_conviction"
                        value="5" class="me-2">
                    <label for="conviction5">5 : Ça pourrait marcher</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.niveau_conviction" type="radio" id="conviction10" name="niveau_conviction"
                        value="10" class="me-2">
                    <label for="conviction10">10 : C’est exactement ce que je cherchais !</label>
                </div>
            </div>

            <div class="question">
                <label for="secteur">Qu’est-ce qui vous enthousiasme le plus à l’idée de prendre en main ce business
                    ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.source_enthousiasme" type="radio" id="enthousiasme1"
                        name="source_enthousiasme" value="generer_revenus" class="me-2">
                    <label for="enthousiasme1">Sa capacité à générer des revenus rapidement</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.source_enthousiasme" type="radio" id="enthousiasme2"
                        name="source_enthousiasme" value="defi_entrepreneurial" class="me-2">
                    <label for="enthousiasme2">Le défi entrepreneurial</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.source_enthousiasme" type="radio" id="enthousiasme3"
                        name="source_enthousiasme" value="innover" class="me-2">
                    <label for="enthousiasme3">La possibilité d’innover dans ce secteur</label>
                </div>

                <div class="mb-2">
                    <label for="enthousiasme-autre" class="ms-2">Autre</label>

                    <textarea [(ngModel)]="item.autre_source_enthousiasme" class="form-control" type="text"
                        id="enthousiasme-autre" rows="3" name="autre_source_enthousiasme"
                        placeholder="Autre"> </textarea>
                </div>
            </div>
            <h2><span class="emoji">🎯</span>Votre Motivation et Ambitions </h2>
            <div class="question">
                <label>Pourquoi avez-vous décidé de vous lancer dans l'entrepreneuriat ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.motivation_entrepreneuriat" type="radio" id="motivation1"
                        name="motivation_entrepreneuriat" value="propre_patron" class="me-2">
                    <label for="motivation1">Devenir mon propre patron</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.motivation_entrepreneuriat" type="radio" id="motivation2"
                        name="motivation_entrepreneuriat" value="diversifier_revenus" class="me-2">
                    <label for="motivation2">Diversifier mes sources de revenus</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.motivation_entrepreneuriat" type="radio" id="motivation3"
                        name="motivation_entrepreneuriat" value="controle_entierement" class="me-2">
                    <label for="motivation3">Lancer un projet que je contrôle entièrement</label>
                </div>

                <div class=" mb-2">
                    <label for="motivation-autre" class="ms-2">Autre</label>
                    <textarea [(ngModel)]="item.autre_motivation_entrepreneuriat" class="form-control" type="text"
                        id="motivation-autre" rows="3" name="autre_motivation_entrepreneuriat"
                        placeholder="Autre"> </textarea>
                </div>


            </div>

            <div class="question">
                <label>Qu’est-ce qui vous motive le plus dans ce business clé en main spécifique ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.motivation_principale" type="radio" id="motivation1"
                        name="motivation_principale" value="potentiel_croissance" class="me-2">
                    <label for="motivation1">Le potentiel de croissance</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.motivation_principale" type="radio" id="motivation2"
                        name="motivation_principale" value="absence_risque" class="me-2">
                    <label for="motivation2">L’absence de risque de démarrage</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.motivation_principale" type="radio" id="motivation3"
                        name="motivation_principale" value="investir_pleinement" class="me-2">
                    <label for="motivation3">La possibilité de m’y investir pleinement</label>
                </div>

                <div class=" mb-2">
                    <label for="motivation-autre" class="ms-2">Autre</label>
                    <textarea rows="3" [(ngModel)]="item.autre_motivation_principale" class="form-control" type="text"
                        id="motivation-autre" name="autre_motivation_principale" placeholder="Autre">
                    </textarea>
                </div>

            </div>

            <div class="question">
                <label>Si vous deviez décrire votre approche entrepreneuriale en 3 mots, ce serait…</label>
                <textarea [(ngModel)]="item.approche_entrepreneuriale" class="form-control" type="text" id="secteur"
                    name="secteur" rows="3"></textarea>
            </div>

            <div class="question">
                <label>Qu’est-ce qui, selon vous, va vous permettre de réussir avec ce business ?</label>
                <textarea [(ngModel)]="item.cle_reussite" class="form-control" type="text" id="secteur" name="secteur"
                    placeholder="(Vos forces, votre expérience, vos compétences spécifiques)" rows="3"></textarea>

            </div>
            <h2><span class="emoji">🛠️</span>Financement : Parlons Argent !</h2>
            <div class="question">
                <label>Avez-vous déjà planifié comment financer l’achat de ce business clé en main ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.planification_achat" type="radio" id="pret-bancaire"
                        name="planification_achat" value="fonds_disponibles" class="me-2">
                    <label for="pret-bancaire"> Oui, j’ai déjà les fonds disponibles
                    </label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.planification_achat" type="radio" id="investissement-personnel"
                        name="planification_achat" value="financement_partiel" class="me-2">
                    <label for="investissement-personnel">Oui, j’ai prévu un financement partiel</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.planification_achat" type="radio" id="partenaires-investisseurs"
                        name="planification_achat" value="solutions_financement" class="me-2">
                    <label for="partenaires-investisseurs">Non, je cherche des solutions de financement</label>
                </div>
            </div>
            <div class="question">
                <label>Si vous avez besoin de financement, comment pensez-vous y accéder ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.source_financement" type="radio" id="pret-bancaire"
                        name="source_financement" value="pret_bancaire" class="me-2">
                    <label for="pret-bancaire">Prêt bancaire</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.source_financement" type="radio" id="investissement-personnel"
                        name="source_financement" value="investissement" class="me-2">
                    <label for="investissement-personnel">Investissement personnel</label>
                </div>
                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.source_financement" type="radio" id="partenaires-investisseurs"
                        name="source_financement" value="partenaires" class="me-2">
                    <label for="partenaires-investisseurs">Partenaires ou investisseurs externes</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.source_financement" type="radio" id="chercher-options"
                        name="source_financement" value="aider" class="me-2">
                    <label for="chercher-options">Je cherche des options, pouvez-vous m’aider ?</label>
                </div>
            </div>

            <div class="question">
                <label>Quel est votre budget maximum pour l’achat de ce business ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.budget_maximum" type="radio" id="moins-10k" name="budget_maximum"
                        value="moins_10k" class="me-2">
                    <label for="moins-10k">Moins de 10 000 €</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.budget_maximum" type="radio" id="entre-10k-50k" name="budget_maximum"
                        value="10k_50k" class="me-2">
                    <label for="entre-10k-50k">Entre 10 000 € et 50 000 €</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.budget_maximum" type="radio" id="plus-50k" name="budget_maximum"
                        value="plus_50k" class="me-2">
                    <label for="plus-50k">Plus de 50 000 €</label>
                </div>
            </div>
            <div class="question">
                <label>Seriez-vous intéressé par une option de financement proposée par ZeroInvestissement.com ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.option_financement_zi" type="radio" id="oui-financement-zi"
                        name="option_financement_zi" value="oui" class="me-2">
                    <label for="oui-financement-zi">Oui, je suis ouvert à cette idée</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.option_financement_zi" type="radio" id="non-financement-zi"
                        name="option_financement_zi" value="non" class="me-2">
                    <label for="non-financement-zi">Non, je préfère trouver une solution de mon côté</label>
                </div>
            </div>

            <h2><span class="emoji">🎨</span>4.Stratégie et Gestion</h2>
            <div class="question">
                <label for="premiere_action_demarrage">Quelle est la première action que vous allez entreprendre une
                    fois le business acquis ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.premiere_action_demarrage" type="radio" id="lancer_promotion"
                        name="premiere_action_demarrage" value="lancer_promotion" class="me-2">
                    <label for="lancer_promotion">Lancer une campagne de promotion</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.premiere_action_demarrage" type="radio" id="revoir_strategie"
                        name="premiere_action_demarrage" value="revoir_strategie" class="me-2">
                    <label for="revoir_strategie">Revoir la stratégie marketing</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.premiere_action_demarrage" type="radio" id="optimiser_operations"
                        name="premiere_action_demarrage" value="optimiser_operations" class="me-2">
                    <label for="optimiser_operations">Optimiser les opérations internes</label>
                </div>

                <div class="mb-2">
                    <label for="autre_action">Autre</label>
                    <textarea rows="3" [(ngModel)]="item.autre_premiere_action_demarrage" class="form-control me-2"
                        type="text" id="autre_action" name="autre_action" placeholder="Autre"> </textarea>
                </div>

            </div>
            <div class="question">
                <label for="canaux_marketing">Comment comptez-vous faire connaître ce business à vos futurs clients
                    ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.canaux_marketing" type="radio" id="publicite_digitale"
                        name="canaux_marketing" value="publicite_digitale" class="me-2">
                    <label for="publicite_digitale">Publicité digitale (Google, Facebook, etc.)</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.canaux_marketing" type="radio" id="reseaux_sociaux" name="canaux_marketing"
                        value="reseaux_sociaux" class="me-2">
                    <label for="reseaux_sociaux">Réseaux sociaux (Instagram, TikTok, LinkedIn)</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.canaux_marketing" type="radio" id="bouche_a_oreille"
                        name="canaux_marketing" value="bouche_oreille" class="me-2">
                    <label for="bouche_a_oreille">Bouche-à-oreille</label>
                </div>

                <div class=" mb-2">
                    <label for="autre_canaux">Autre</label>
                    <textarea rows="3" [(ngModel)]="item.autre_canaux_marketing" class="form-control me-2" type="text"
                        id="autre_canaux" name="autre_canaux" placeholder="Autre">
                </textarea>
                </div>
            </div>
            <div class="question">
                <label for="objectif_principal_12_mois">Quel est votre objectif principal avec ce business clé en main
                    dans les 12 prochains mois ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.objectif_principal_12_mois" type="radio" id="generer_chiffre_affaires"
                        name="objectif_principal_12_mois" value="generer_chiffre_affaires" class="me-2">
                    <label for="generer_chiffre_affaires">Générer un chiffre d’affaires de X €</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.objectif_principal_12_mois" type="radio" id="fideler_clients"
                        name="objectif_principal_12_mois" value="fideliser_clients" class="me-2">
                    <label for="fideler_clients">Fidéliser un grand nombre de clients</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.objectif_principal_12_mois" type="radio" id="etendre_activite"
                        name="objectif_principal_12_mois" value="etendre_activite" class="me-2">
                    <label for="etendre_activite">Étendre l’activité dans plusieurs régions</label>
                </div>

                <div class="mb-2">
                    <label for="autre_objectif">Autre</label>
                    <textarea rows="3" [(ngModel)]="item.autre_objectif_principal_12_mois" class="form-control me-2"
                        type="text" id="autre_objectif" name="autre_objectif" placeholder="Autre">
            </textarea>
                </div>
            </div>
            <div class="question">
                <label for="budget_publicitaire">Quel budget publicitaire initial prévoyez-vous pour ce business
                    ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.budget_publicitaire" type="radio" id="moins_500" name="budget_publicitaire"
                        value="moins_500" class="me-2">
                    <label for="moins_500">Moins de 500 €</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.budget_publicitaire" type="radio" id="500_a_2000"
                        name="budget_publicitaire" value="500_a_2000" class="me-2">
                    <label for="500_a_2000">500 € à 2000 €</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.budget_publicitaire" type="radio" id="plus_2000" name="budget_publicitaire"
                        value="plus_2000" class="me-2">
                    <label for="plus_2000">Plus de 2000 €</label>
                </div>
            </div>
            <div class="question">
                <label for="choix_priorite">Si vous pouviez donner un coup de boost immédiat à une partie du business,
                    laquelle serait-ce ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.choix_priorite" type="radio" id="boost_ventes" name="choix_priorite"
                        value="ventes" class="me-2">
                    <label for="boost_ventes">Les ventes</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.choix_priorite" type="radio" id="boost_notoriete" name="choix_priorite"
                        value="notoriété" class="me-2">
                    <label for="boost_notoriete">La notoriété</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.choix_priorite" type="radio" id="boost_service_client"
                        name="choix_priorite" value="service_client" class="me-2">
                    <label for="boost_service_client">Le service client</label>
                </div>

                <div class="mb-2">
                    <label for="autre_choix_priorite">Autre</label>
                    <textarea rows="3" [(ngModel)]="item.autre_choix_priorite" class="form-control me-2" type="text"
                        id="autre_choix_priorite" name="autre_choix_priorite" placeholder="Autre">
          </textarea>
                </div>
            </div>


            <h2><span class="emoji">💻</span>Formation et Support
            </h2>

            <div class="question">
                <label for="besoin_formation">Avez-vous besoin d'une formation pour mieux comprendre le secteur du
                    business que vous avez choisi ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.besoin_formation" type="radio" id="formation_oui" name="besoin_formation"
                        value="oui" class="me-2">
                    <label for="formation_oui">Oui, je suis prêt à me former à 100 %</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.besoin_formation" type="radio" id="formation_partiel"
                        name="besoin_formation" value="certains_aspects" class="me-2">
                    <label for="formation_partiel">Oui, mais seulement sur certains aspects</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.besoin_formation" type="radio" id="formation_non" name="besoin_formation"
                        value="non" class="me-2">
                    <label for="formation_non">Non, je maîtrise déjà bien ce secteur</label>
                </div>
            </div>

            <div class="question">
                <label for="modalite_formation">Comment préférez-vous suivre la formation associée à ce business
                    ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.modalite_formation" type="radio" id="modules_en_ligne"
                        name="modalite_formation" value="modules_en_ligne" class="me-2">
                    <label for="modules_en_ligne">Modules en ligne à mon rythme</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.modalite_formation" type="radio" id="webinaires_ateliers"
                        name="modalite_formation" value="webinaires_pratiques" class="me-2">
                    <label for="webinaires_ateliers">Webinaires et ateliers pratiques</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.modalite_formation" type="radio" id="coaching_direct"
                        name="modalite_formation" value="sessions_coaching" class="me-2">
                    <label for="coaching_direct">Sessions de coaching en direct</label>
                </div>
            </div>

            <div class="question">
                <label for="competences_necessaires">Quelles compétences spécifiques aimeriez-vous développer avant de
                    vous lancer ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.competences_necessaires" type="radio" id="marketing_digital"
                        name="competences_necessaires" value="marketing_digital" class="me-2">
                    <label for="marketing_digital">Marketing digital</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.competences_necessaires" type="radio" id="gestion_operations"
                        name="competences_necessaires" value="gestion_operations" class="me-2">
                    <label for="gestion_operations">Gestion des opérations</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.competences_necessaires" type="radio" id="relation_client"
                        name="competences_necessaires" value="relation_client" class="me-2">
                    <label for="relation_client">Relation client</label>
                </div>

                <div class="mb-2">
                    <label for="autre_competences">Autre</label>
                    <textarea [(ngModel)]="item.autre_competences_necessaires" class="form-control"
                        id="autre_competences" name="autre_competences" rows="3"
                        placeholder="Autre compétences..."></textarea>
                </div>
            </div>
            <div class="question">
                <label for="type_suivi">Quel type de suivi attendez-vous de ZeroInvestissement.com après le lancement
                    ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.type_suivi" type="radio" id="suivi_hebdomadaire" name="type_suivi"
                        value="suivi_hebdomadaire" class="me-2">
                    <label for="suivi_hebdomadaire">Suivi hebdomadaire pour des conseils stratégiques</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.type_suivi" type="radio" id="suivi_mensuel" name="type_suivi"
                        value="suivi_mensuel" class="me-2">
                    <label for="suivi_mensuel">Suivi mensuel pour évaluer les performances</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.type_suivi" type="radio" id="suivi_besoins" name="type_suivi"
                        value="suivi_besoins" class="me-2">
                    <label for="suivi_besoins">Suivi selon mes besoins spécifiques</label>
                </div>
            </div>
            <h2><span class="emoji">📈</span>Engagement et Croissance
            </h2>
            <div class="question">
                <label>Combien de temps par semaine êtes-vous prêt à consacrer au développement de ce business ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.disponibilite_hebdomadaire" type="radio" id="temps_plein"
                        name="disponibilite_hebdomadaire" value="temps_plein" class="me-2">
                    <label for="temps_plein">Temps plein (40h+)</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.disponibilite_hebdomadaire" type="radio" id="temps_partiel"
                        name="disponibilite_hebdomadaire" value="temps_partiel" class="me-2">
                    <label for="temps_partiel">Temps partiel (20-30h)</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.disponibilite_hebdomadaire" type="radio" id="soirees_weekend"
                        name="disponibilite_hebdomadaire" value="soirees__weekends" class="me-2">
                    <label for="soirees_weekend">Soirées et week-ends</label>
                </div>
            </div>

            <div class="question">
                <label>Combien de temps vous donnez-vous pour rendre ce business rentable ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.seuil_temps_rentabilite" type="radio" id="3_mois"
                        name="seuil_temps_rentabilite" value="3_mois" class="me-2">
                    <label for="3_mois">3 mois ou moins</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.seuil_temps_rentabilite" type="radio" id="6_12_mois"
                        name="seuil_temps_rentabilite" value="6_12_mois" class="me-2">
                    <label for="6_12_mois">6 à 12 mois</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.seuil_temps_rentabilite" type="radio" id="plus_de_12_mois"
                        name="seuil_temps_rentabilite" value="plus" class="me-2">
                    <label for="plus_de_12_mois">Je suis prêt à attendre plus si nécessaire</label>
                </div>
            </div>
            <div class="question">
                <label>Avez-vous un plan de croissance pour ce business une fois lancé ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.disponibilite_plan_croissance" type="radio" id="plan_extention"
                        name="disponibilite_plan_croissance" value="oui" class="me-2">
                    <label for="plan_extention">Oui, je compte l’étendre rapidement</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.disponibilite_plan_croissance" type="radio" id="plan_stabilisation"
                        name="disponibilite_plan_croissance" value="non" class="me-2">
                    <label for="plan_stabilisation">Non, je préfère d’abord stabiliser les opérations</label>
                </div>
            </div>

            <div class="question">
                <label>Si vous aviez accès à un mentor entrepreneur, que lui demanderiez-vous en priorité ?
                </label>
                <textarea [(ngModel)]="item.question_mentor_entrepreneur" class="form-control" type="text" id="couleurs"
                    name="couleurs" placeholder="" rows="3"></textarea>

            </div>
            <h2><span class="emoji">🔧</span>Et pour finir, amusons-nous un peu !
            </h2>
            <div class="question">
                <label>Si votre business clé en main avait un superpouvoir, lequel serait-il et pourquoi ?

                </label>
                <textarea [(ngModel)]="item.super_pouvoir" class="form-control" type="text" id="couleurs"
                    name="couleurs" placeholder="" rows="3"></textarea>

            </div>
            <div class="question">
                <label>Quel serait le titre d’un livre sur votre aventure entrepreneuriale avec ce business ?
                </label>
                <textarea [(ngModel)]="item.titre_ouvrage_succes" class="form-control" type="text" id="couleurs"
                    name="couleurs" placeholder="" rows="3"></textarea>

            </div>
            <div class="question">
                <label>Imaginez que dans 5 ans, votre business est un énorme succès. Quelle serait la première chose que
                    vous feriez ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.premiere_action_succes" type="radio" id="voyage"
                        name="premiere_action_succes" value="voyage" class="me-2">
                    <label for="voyage">Un voyage autour du monde</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.premiere_action_succes" type="radio" id="reinvestir"
                        name="premiere_action_succes" value="projets" class="me-2">
                    <label for="reinvestir">Réinvestir dans d’autres projets</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.premiere_action_succes" type="radio" id="fete"
                        name="premiere_action_succes" value="fete" class="me-2">
                    <label for="fete">Organiser une fête incroyable pour célébrer</label>
                </div>
            </div>

        </form>
        <br>
        <!-- <p id="paragraphe" class="text-left"> <strong> Merci pour vos réponses !</strong></p>
        <p id="paragraphe" class="text-left">
            Notre équipe analysera vos réponses, notamment en ce qui concerne le financement, et nous vous contacterons
            sous 10 jours ouvrés pour valider votre acquisition du business clé en main. Ensemble, nous allons
            transformer ce projet en un succès entrepreneurial ! ZeroInvestissement.com est prêt à vous accompagner de
            la première étape jusqu’au sommet. </p>

        <p id="paragraphe" class="text-left"><strong>Pourquoi ce questionnaire ?</strong></p>
        <p id="paragraphe" class="text-left">
            Ce questionnaire nous aide à comprendre votre capacité à financer l’achat du business clé en main et à
            anticiper vos besoins en termes de soutien financier. Nous voulons vous offrir une solution adaptée pour
            garantir que vous avez les meilleures chances de réussir.



        </p> -->
        <div class="d-flex justify-content-center mt-4">
            <button (click)="onSubmit()" type="submit" id="but" class="btn btn-primary">Soumettre mon projet <i
                    class="pi pi-send" style="color: white; font-size: 0.8rem;"></i></button>
        </div>
    </body>

    </html>

</div>
<p-toast position="top-right" />
<ngx-ui-loader></ngx-ui-loader>
<p-scrollTop styleClass="custom-scrolltop" [threshold]="100" icon="pi pi-arrow-up" />
