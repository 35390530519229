import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { environment } from '../environments/environment';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initFirebaseBackend } from './authUtils';

import { LocalStorageService } from './shared/service/storage/localstorage.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PagesModule } from './admin/pages/pages.module';
import { LayoutsModule } from './admin/layouts/layouts.module';
import { JwtInterceptor } from './admin/core/helpers/jwt.interceptor';
import { ErrorInterceptor } from './admin/core/helpers/error.interceptor';
import { FakeBackendInterceptor } from './admin/core/helpers/fake-backend';
import { PublicModule } from './public/public.module';
import { AdminModule } from './admin/admin/admin.module';
import { PublicLayoutComponent } from './public/public-layout/public-layout.component';
import { PublicHeaderComponent } from './public/public-header/public-header.component';
import { PublicFooterComponent } from './public/public-footer/public-footer.component';
import { PublicDashboardComponent } from './public/public-dashboard/public-dashboard.component';
import { CarouselModule } from 'primeng/carousel';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { OperationService } from './shared/service/operation/operation.service';

if (environment.defaultauth === 'firebase') {
  initFirebaseBackend(environment.firebaseConfig);
} else {
  // tslint:disable-next-line: no-unused-expression
  FakeBackendInterceptor;
}

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    PublicLayoutComponent,
    PublicHeaderComponent,
    PublicFooterComponent,
    PublicDashboardComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    PagesModule,
    LayoutsModule,
    FormsModule,
    PublicModule,
    AdminModule,
    CarouselModule ,
    ProgressSpinnerModule ,
    NgxIntlTelInputModule,
    ProgressSpinnerModule ,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: FakeBackendInterceptor, multi: true },
    LocalStorageService,ConfirmationService,MessageService, OperationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
