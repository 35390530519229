<div *ngIf="afficherMessage === 1" class="container mt-4">
    <div class="card shadow-lg p-4">
        <div class="row g-4">
            <!-- Texte et boutons -->
            <div class="col-md-12 d-flex flex-column justify-content-center">
                <p id="paragraphe" class="text-left"><strong>Merci d’avoir joué le jeu !
                    </strong></p>
                <p id="paragraphe" class="text-left">Nous allons analyser votre projet avec attention et vous recevrez
                    une
                    réponse sous 10 jours ouvrés. Nous adorons les idées créatives, rentables et qui ont du punch ! Si
                    votre
                    projet est sélectionné, préparez-vous à embarquer dans une aventure extraordinaire avec
                    ZeroInvestissement.com.


                </p>
                <p id="paragraphe" class="text-left"> <strong>Pourquoi ce questionnaire ?</strong></p>
                <p id="paragraphe" class="text-left">
                    Parce que nous voulons être sûrs de bien comprendre votre vision, vos motivations et surtout le
                    potentiel de
                    votre idée. Et comme nous adorons la créativité, vos réponses amusantes nous donneront un vrai
                    aperçu de qui
                    vous êtes, et pourquoi votre projet mérite d’être propulsé par notre équipe. Plus nous vous
                    connaissons,
                    mieux nous pourrons vous accompagner vers le succès !
                </p>
                <div class="mt-3 d-flex align-items-center">
                    <button (click)="redirectToList()" class="btn btn-primary me-2 d-flex align-items-center"
                        style="background-color: #30384a; color: white;">
                        continuer
                        <i class="pi pi-angle-right ms-2" style="font-size: 1.6rem"></i>
                    </button>

                    <!-- <button class="btn btn-outline-secondary d-flex align-items-center"
              style="background-color: #6a00b8; color: white;">
              Plus de détails
              <i class="pi pi-globe ms-2" style="font-size: 1.6rem"></i>
            </button> -->
                </div>

            </div>

            <!-- Image -->
            <!-- <div class="col-md-6 d-flex justify-content-center align-items-center">
          <img src="../../../assets/images/rename.png" class="img-fluid rounded" alt="Coming Soon"
            style="max-height: 400px;">
        </div> -->
        </div>
    </div>
</div>


<div *ngIf="afficherMessage === 2" class="container mt-4">
    <div class="card shadow-lg p-4">
        <div class="row g-4">
            <!-- Texte et boutons -->
            <div class="col-md-12 d-flex flex-column justify-content-center">

                <p id="paragraphe" class="text-left mb-4 mt-4"> <strong> Merci d’avoir partagé votre vision !</strong>
                </p>
                <p id="paragraphe" class="text-left mb-4 mt-4"> Notre équipe d’experts analysera votre projet avec
                    attention et
                    vous recevrez une réponse sous <strong>15 jour
                        ouvrée</strong> .
                    Si votre modèle est validé, <strong> ZeroInvestissement.com</strong> vous accompagnera
                    pour faire de votre entreprise
                    la prochaine grande franchise, sans investissement de votre part. On est prêts à vous propulser vers
                    le
                    succès, et vous ?
                </p>
                <p id="paragraphe" class="text-left"> <strong>Pourquoi ce questionnaire ?</strong> </p>

                <p id="paragraphe" class="text-left">Nous voulons nous assurer que votre entreprise est prête à franchir
                    un
                    cap, que votre modèle est duplicable,
                    et que vous partagez nos valeurs. Plus on en sait sur vous et votre vision, mieux on pourra vous
                    accompagner
                    pour transformer votre réussite locale en succès national… voire mondial !</p><br>
                <div class="mt-3 d-flex align-items-center">
                    <button (click)="redirectToList()" class="btn btn-primary me-2 d-flex align-items-center"
                        style="background-color: #30384a; color: white;">
                        continuer
                        <i class="pi pi-angle-right ms-2" style="font-size: 1.6rem"></i>
                    </button>

                    <!-- <button class="btn btn-outline-secondary d-flex align-items-center"
              style="background-color: #6a00b8; color: white;">
              Plus de détails
              <i class="pi pi-globe ms-2" style="font-size: 1.6rem"></i>
            </button> -->
                </div>

            </div>

            <!-- Image -->
            <!-- <div class="col-md-6 d-flex justify-content-center align-items-center">
          <img src="../../../assets/images/rename.png" class="img-fluid rounded" alt="Coming Soon"
            style="max-height: 400px;">
        </div> -->
        </div>
    </div>
</div>


<div *ngIf="afficherMessage === 3" class="container mt-4">
    <div class="card shadow-lg p-4">
        <div class="row g-4">
            <!-- Texte et boutons -->
            <div class="col-md-12 d-flex flex-column justify-content-center">
                <p id="paragraphe" class="text-left"> <strong> Merci pour vos réponses !</strong></p>
                <p id="paragraphe" class="text-left">
                    Notre équipe analysera vos réponses, notamment en ce qui concerne le financement, et nous vous contacterons
                    sous 10 jours ouvrés pour valider votre acquisition du business clé en main. Ensemble, nous allons
                    transformer ce projet en un succès entrepreneurial ! ZeroInvestissement.com est prêt à vous accompagner de
                    la première étape jusqu’au sommet. </p>
        
                <p id="paragraphe" class="text-left"><strong>Pourquoi ce questionnaire ?</strong></p>
                <p id="paragraphe" class="text-left">
                    Ce questionnaire nous aide à comprendre votre capacité à financer l’achat du business clé en main et à
                    anticiper vos besoins en termes de soutien financier. Nous voulons vous offrir une solution adaptée pour
                    garantir que vous avez les meilleures chances de réussir.
        
        
        
                </p>
                <div class="mt-3 d-flex align-items-center">
                    <button (click)="redirectToList()" class="btn btn-primary me-2 d-flex align-items-center"
                        style="background-color: #30384a; color: white;">
                        continuer
                        <i class="pi pi-angle-right ms-2" style="font-size: 1.6rem"></i>
                    </button>

                    <!-- <button class="btn btn-outline-secondary d-flex align-items-center"
              style="background-color: #6a00b8; color: white;">
              Plus de détails
              <i class="pi pi-globe ms-2" style="font-size: 1.6rem"></i>
            </button> -->
                </div>

            </div>

            <!-- Image -->
            <!-- <div class="col-md-6 d-flex justify-content-center align-items-center">
          <img src="../../../assets/images/rename.png" class="img-fluid rounded" alt="Coming Soon"
            style="max-height: 400px;">
        </div> -->
        </div>
    </div>
</div>


<div *ngIf="afficherMessage === 4" class="container mt-4">
    <div class="card shadow-lg p-4">
        <div class="row g-4">
            <!-- Texte et boutons -->
            <div class="col-md-12 d-flex flex-column justify-content-center">
                <p id="paragraphe" class="text-left"><strong>Merci de nous avoir soumis votre projet !</strong></p>
                <p id="paragraphe" class="text-left">
                    Notre équipe analysera votre candidature et vous recevrez une réponse sous <strong>10 jour ouvrée</strong>.
                    Si votre business
                    est sélectionné, il sera publié sur notre marketplace et mis en vente.
                    <strong>ZeroInvestissement.com</strong> est prêt à
                    vous accompagner pour que votre projet rencontre son futur propriétaire idéal !!!!!!!
        
        
        
                </p>
                <div class="mt-3 d-flex align-items-center">
                    <button (click)="redirectToList()" class="btn btn-primary me-2 d-flex align-items-center"
                        style="background-color: #30384a; color: white;">
                        continuer
                        <i class="pi pi-angle-right ms-2" style="font-size: 1.6rem"></i>
                    </button>

                    <!-- <button class="btn btn-outline-secondary d-flex align-items-center"
              style="background-color: #6a00b8; color: white;">
              Plus de détails
              <i class="pi pi-globe ms-2" style="font-size: 1.6rem"></i>
            </button> -->
                </div>

            </div>

            <!-- Image -->
            <!-- <div class="col-md-6 d-flex justify-content-center align-items-center">
          <img src="../../../assets/images/rename.png" class="img-fluid rounded" alt="Coming Soon"
            style="max-height: 400px;">
        </div> -->
        </div>
    </div>
</div>

<div *ngIf="afficherMessage === 5" class="container mt-4">
    <div class="card shadow-lg p-4">
        <div class="row g-4">
            <!-- Texte et boutons -->
            <div class="col-md-12 d-flex flex-column justify-content-center">
                <p id="paragraphe" class="text-left"> <strong> Merci d’avoir partagé votre projet avec nous ! </strong> <br>
                    Nous allons analyser votre dossier et vous recevrez un retour sous 7 jour ouvré. Si votre projet est
                    sélectionné, nous organiserons un entretien pour discuter en détail de vos besoins spécifiques et de la
                    manière dont ZeroInvestissement.com peut vous accompagner dans cette aventure. Préparez-vous à atteindre de
                    nouveaux sommets !
    
    
    
                </p>
                <p id="paragraphe" class="text-left"> <strong>Pourquoi ce questionnaire ? </strong> <br>
                    Ce questionnaire est conçu pour nous permettre de bien comprendre votre projet, vos ambitions, et les défis
                    auxquels vous faites face. Cela nous aide à identifier comment nous pouvons vous soutenir au mieux et vous
                    aider à transformer vos idées en un succès commercial durable.
    
    
    
    
                </p>
                <div class="mt-3 d-flex align-items-center">
                    <button (click)="redirectToList()" class="btn btn-primary me-2 d-flex align-items-center"
                        style="background-color: #30384a; color: white;">
                        continuer
                        <i class="pi pi-angle-right ms-2" style="font-size: 1.6rem"></i>
                    </button>

                    <!-- <button class="btn btn-outline-secondary d-flex align-items-center"
              style="background-color: #6a00b8; color: white;">
              Plus de détails
              <i class="pi pi-globe ms-2" style="font-size: 1.6rem"></i>
            </button> -->
                </div>

            </div>

            <!-- Image -->
            <!-- <div class="col-md-6 d-flex justify-content-center align-items-center">
          <img src="../../../assets/images/rename.png" class="img-fluid rounded" alt="Coming Soon"
            style="max-height: 400px;">
        </div> -->
        </div>
    </div>
</div>