<div class="container vh-100 d-flex align-items-center justify-content-center">
  <div class="card text-center" style="width: 300px;">
    <div style="background-color: #6a00b8;" class="card-header h5 text-white">Réinitialisation du mot de passe</div>
    <div class="card-body px-5">
      <p class="card-text py-2">
        Entrez votre adresse e-mail et nous vous enverrons un e-mail contenant des instructions pour réinitialiser votre mot de passe.
      </p>
      <div data-mdb-input-init class="form-outline">
        <label class="form-label" for="typeEmail">Email </label>

        <input [(ngModel)]="email" name="email" type="email" id="typeEmail" class="form-control my-3" />
      </div>
      <a (click)="onSubmit()" style="background-color: #6a00b8; color: white;"  href="javascript:void(0);" data-mdb-ripple-init class="btn  w-100">Réinitialiser </a>
      <div class="d-flex justify-content-between mt-4">
        <a class="" href="javascript:void(0);" routerLink="/login">Se connecter</a>
        <a class="" href="javascript:void(0);" routerLink="/signup">S'inscrire</a>
      </div>
    </div>
  </div>
</div>
<p-toast position="top-center" />
<ngx-ui-loader></ngx-ui-loader>