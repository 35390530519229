import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MessageService } from 'primeng/api';
import { MarketPlaceBusiness } from 'src/app/shared/models/market_place_business';
import { OperationService } from 'src/app/shared/service/operation/operation.service';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';

@Component({
  selector: 'app-marketbusness',
  templateUrl: './marketbusness.component.html',
  styleUrl: './marketbusness.component.scss'
})
export class MarketbusnessComponent {
  item: MarketPlaceBusiness = new MarketPlaceBusiness()

  constructor(
    private parameterService: ParameterService,
    private operationService: OperationService,
    private messageService: MessageService,
    private localStorage: LocalStorageService,
    private ngxloaderService: NgxUiLoaderService,
    private router: Router


  ) {

    this.item.user_reference = this.localStorage.getUsers()?.data?.user?.reference;
  }
  onSubmit() {
    // this.router.navigate(['/page/projet-success']);


    this.localStorage.saveInfoMessage(3);

    console.log("this.item", this.item);

    if (!this.item.canSend()) {
      this.messageService.add({ severity: 'info', summary: 'zeroInvestissement', detail: this.item.getErrorMessage() });
      return;
    }

    if (this.item.canSend()) {
      this.ngxloaderService.start();
      this.operationService.addProjetMarketPlaceBusiness(this.item).subscribe(
        (response: any) => {
          console.log("la reponse du webservice", response);
          this.messageService.add({ severity: 'success', summary: 'zeroInvestissement', detail: response.message });
          this.ngxloaderService.stop();
          this.localStorage.saveInfoMessage(3);
          this.item = new MarketPlaceBusiness();
          setTimeout(() => {
            this.router.navigate(['/page/projet-success']);
          }, 2000); // 90000 ms = 90 secondes
        },
        (error) => {
          console.error("une erreur est survenu", error);
          this.messageService.add({ severity: 'error', summary: 'zeroInvestissement', detail: error });

          this.ngxloaderService.stop();

        }
      )
    }
  }
}
