import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MessageService } from 'primeng/api';
import { ResetUser } from 'src/app/shared/models/reset';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss'
})


export class ChangePasswordComponent {

  resetItem: ResetUser = new ResetUser();
  showPassword: boolean = false;
  showPassword2: boolean = false;

  token:any;
  constructor(
    private localstorage: LocalStorageService,
    private authService: AuthService,

    private messageService: MessageService,
    private router: Router,
    private loaderService :NgxUiLoaderService,
    private route :ActivatedRoute
  ){

  }
  reference:any;
  afficherFormulair:boolean =false;
  ngOnInit(){
    console.log(" this.reference", this.reference);

    console.log("4 ");
    
    this.route.paramMap.subscribe(params => {
      this.token = params.get('token');
      console.log("Token récupéré :", this.token);
      // Vous pouvez maintenant utiliser `this.token` comme nécessaire
    });
    
    this.checkToken();
  }

  resetPassword() {

    console.log("appele");

    if (!this.resetItem.canSave()) {

      this.messageService.add({ severity: 'info', summary: 'zeroInvestissement', detail: this.resetItem.getErrorMessage() });


    }
    if (this.resetItem.canSave()) {

      this.resetItem.token =this.token;
      this.loaderService.start();
      this.authService.reset(this.resetItem ).subscribe(

        (response) => {

          //console.log("la reponse du webservice", response);

          
          if (response.status === "success") {

            this.messageService.add({ severity: 'success', summary: 'zeroInvestissement', detail: response.message });

           setTimeout(() => {
               this.router.navigate(['/login']);
           }, 5000);

           this.loaderService.stop();

          }

          if (response.status === "error") {

            this.messageService.add({ severity: 'error', summary: 'zeroInvestissement', detail: response.message });

            this.loaderService.stop();

          }

        },
        (error) => {
          //console.log("une erreur est survenu", error);
          this.messageService.add({ severity: 'error', summary: 'zeroInvestissement', detail: "une erreur est survenu" });
          this.loaderService.stop();

        }

      )
    }

  }

  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }


  toggleShowPassword2(): void {
    this.showPassword2 = !this.showPassword2;
  }


  checkToken(){
    console.log("this.token",this.token);
    
    // this.loaderService.start();

    const requestData = {
      "token":this.token
    }
    this.authService.resetverifyEmail(requestData).subscribe(
      (response:any)=>{

        console.log("la reponse du webservice",response);
        // this.loaderService.stop();

        if (response.status ==="success") {
          this.afficherFormulair = true;

        }
      }, 
      (error:any)=>{

        console.log("une erreur est survenu",error);
        // this.loaderService.stop();
        if (error.error && error.error.message) {
          this.messageService.add({ severity: 'error', summary: 'zeroInvestissement', detail: error.error.message });
        }
      }
    )
  }
}

