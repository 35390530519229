import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './admin/account/auth/login/login.component';
import { SignupComponent } from './admin/account/auth/signup/signup.component';
import { LayoutComponent } from './admin/layouts/layout/layout.component';
import { PublicLayoutComponent } from './public/public-layout/public-layout.component';
import { PublicDashboardComponent } from './public/public-dashboard/public-dashboard.component';
import { PasswordresetComponent } from './admin/account/auth/passwordreset/passwordreset.component';
import { ChangePasswordComponent } from './admin/account/auth/change-password/change-password.component';
import { PremiereConnexionComponent } from './admin/account/auth/premiere-connexion/premiere-connexion.component';


const routes: Routes = [

  { path: 'login', component: LoginComponent },
  {
    path: 'signup',
    component: SignupComponent
  },

  {
    path: 'reset-password',
    component: PasswordresetComponent
  },
  { path: 'change-password/:token', component: ChangePasswordComponent  },
  { path: 'bienvenue', component: PremiereConnexionComponent }, 

  { path: 'account', loadChildren: () => import('./admin/account/account.module').then(m => m.AccountModule) },
  // tslint:disable-next-line: max-line-length
  { path: 'page', component: LayoutComponent, loadChildren: () => import('././admin/pages/pages.module').then(m => m.PagesModule) },

  { path: '',  component: PublicDashboardComponent }, 

  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: '',
    component: PublicLayoutComponent,
    children: [ 
      {
        path: '',
        loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
      }]
  },

  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
exports: [RouterModule]
})
export class AppRoutingModule { }
