import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 2,
        label: 'Accueil',
        icon: 'ri-dashboard-line',
        badge: {
            variant: 'success',
            text: '',
        },
        link: 'dashboard'
    },
 
    // {
    //     id: 5,
    //     label: 'Statistique',
    //     icon: 'ri-store-2-line',
    //     link: '/page/projet-soumis',

    //     // subItems: [
    //     //     {
    //     //         id: 6,
    //     //         label: 'Liste des utilisateurs',
    //     //         parentId: 5
    //     //     },
          
         
    //     // ]
    // },
    {
        id: 13,
        label: 'Soumettre un projet',
        icon: 'ri-mail-send-line',
        link: '/page/soumission',

       
    },
    {
        id: 5,
        label: 'Mes demandes',
        icon: 'ri-store-2-line',
        link: '/page/projet-soumis',

        subItems: [
            {
                id: 6,
                label: 'Mon site/Appli',
                parentId: 5,
                link: '/page/liste-fun-site',

            },
          
            // {
            //     id: 7,
            //     label: 'Ma franchise',
            //     parentId: 5,
            //     link: '/page/liste-franchise',
            // },
            // {
            //     id: 8,
            //     label: 'Marketplace Business',
            //     parentId: 5,
            //     link: '/page/liste-marketplace-business',

            // },
            // {
            //     id: 9,
            //     label: 'Marketplace Dépot',
            //     parentId: 5,
            //     link: '/page/liste-marketplace-depot',

            // },
            // {
            //     id: 10,
            //     label: 'Incubateur',
            //     parentId: 5,
            //     link: '/page/liste-incubateur',
            // },
          
         
        ]
    },
    
    
    {
        id: 23,
        label: '',
        icon: 'ri-profile-line',
        isTitle: true,

    },

 
    // {
    //     id: 18,
    //     label: 'Paramètre',
    //     icon: 'ri-settings-line',
       
    // },  
    // {
    //     id: 19,
    //     label: 'Mon compte',
    //     icon: 'ri-user-line',
    //     link: '/page/profil',

       
    // },
    // {
    //     id: 20,
    //     label: 'Déconnexion',
    //     icon: 'ri-logout-box-r-line',
       
    // },
  
  
   
  
];
