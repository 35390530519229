
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {


    public getUsers(): any {
        return this.readFromSession("ZERO_INVESTISSEMENT_USER_TO_OPERATION");
    };
    public saveUsers(obj: any): void {
        // // //console.log("l'obejt",obj);

        this.saveToSession("ZERO_INVESTISSEMENT_USER_TO_OPERATION", obj);
    }


    
    public getInfoMessage(): any {
        return this.readFromSession("ZERO_INVESTISSEMENT_MESSAGE_TO_OPERATION");
    };
    public saveInfoMessage(obj: any): void {
        // // //console.log("l'obejt",obj);

        this.saveToSession("ZERO_INVESTISSEMENT_MESSAGE_TO_OPERATION", obj);
    }

    private readFromSession(key: string): any {
        let result = null;
        try {
            const json: any = localStorage.getItem(key);
            if (json) {
                const decoded = decodeURIComponent(escape(atob(json)));
                result = JSON.parse(decoded);
            }
        } catch (e) {
            //console.error("Error", e);
        }
        return result;
    }

    private saveToSession(key: string, value: any): void {

        //console.log("j'appelle le localstorage");

        try {
            const stringified = btoa(unescape(encodeURIComponent(JSON.stringify(value))));
            localStorage.setItem(key, stringified);

            //console.log("value",value);

        } catch (e) {
            //console.error("Error", e);
        }
    }



}