<div class="container-fluid">

  <h4 id="upper">SOUMISSION DE PROJET</h4>

  <div>
    <div class="container py-5">
      <div class="row text-center">

        <!-- Card 1 -->
        <div class="col-lg-3 col-md-6 mb-2">
          <div class="card h-100 p-3 shadow">
            <img src="../../../../assets/images/gradient-ui-ux-landing-page-template.png" alt="Icon 1"
              class="card-img-top img-fluid">
            <div class="card-body d-flex flex-column">
              <h5 class="card-title">Mon site/Appli</h5>
              <p class="card-text">Créez votre site web ou application mobile sans frais initiaux, on s'occupe de tout.
              </p>
              <a routerLink="/page/fun-site" href="javascript: void(0);" class="btn btn-primary mt-auto">Soumettre mon
                projet</a>
            </div>
          </div>
        </div>

        <!-- Card 2 -->
        <div class="col-lg-3 col-md-6 mb-2">
          <div class="card h-100 p-3 shadow">
            <img src="../../../../assets/images/isometric-b2b-illustration.png" alt="Icon 2"
              class="card-img-top img-fluid">
            <div class="card-body d-flex flex-column">
              <h5 class="card-title">Ma franchise</h5>
              <p class="card-text">Étendez votre entreprise en franchise, sans coûts, avec notre accompagnement complet.
              </p>
              <a routerLink="/page/franchise" href="javascript: void(0);" class="btn btn-primary mt-auto">Soumettre ma
                candidature</a>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 mb-2">
          <div class="card h-100 p-3 shadow">
            <img style="height: 230px;" src="../../../../assets/images/business-concept-with-rocket.png" alt="Icon 4" class="card-img-top img-fluid">
            <div class="card-body d-flex flex-column">
              <h5 class="card-title">Marketplace</h5>
              <p class="card-text">Développez votre idée ou entreprise avec notre incubateur sans contraintes
                financières.</p>
              <a routerLink="/page/marketplace-business" href="javascript: void(0);" class="btn btn-primary mt-auto">Soumettre ma
                candidature</a>
            </div>
          </div>
        </div>
        <!-- Card 3 -->
        <!-- <div class="col-lg-3 col-md-6 mb-2">
          <div class="card h-100 p-3 shadow">
            <img style="height: 240px;" src="../../../../assets/images/business-concept-with-rocket.png" alt="Icon 3"
              class="card-img-top img-fluid">
            <div class="card-body d-flex flex-column">
              <h5 class="card-title">Marketplace</h5>
              <p class="card-text">Développez votre idée ou entreprise avec notre incubateur sans contraintes
                financières.</p>
                <a routerLink="/page/marketplace-business" href="javascript: void(0);" class="btn btn-primary">Soumettre un
                  projet</a>
              <div class="d-flex justify-content-center mt-auto">
                <a routerLink="/page/marketplace-business" href="javascript: void(0);" class="btn btn-primary">Soumettre un
                  projet</a>
                <a routerLink="/page/marketplace" href="javascript: void(0);" class="btn btn-primary">Choisir un
                  projet</a> 
              </div> 
            </div>
          </div>
        </div> -->

        <!-- Card 4 -->
        <div class="col-lg-3 col-md-6 mb-2">
          <div class="card h-100 p-3 shadow">
            <img src="../../../../assets/images/business.png" alt="Icon 4" class="card-img-top img-fluid">
            <div class="card-body d-flex flex-column">
              <h5 class="card-title">Incubateur</h5>
              <p class="card-text">Développez votre idée ou entreprise avec notre incubateur sans contraintes
                financières.</p>
              <a routerLink="/page/incubateur" href="javascript: void(0);" class="btn btn-primary mt-auto">Soumettre ma
                candidature</a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
