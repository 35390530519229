export class Incubateur {
  nom_projet: any;
  description_projet: any;
  stade_projet: any;
  presentation_projet_film: any;
  mission_principale: any;
  genese_idee: any;
  passion_principale: any;
  autre_passion_principale: any;
  force_principale :any
  obstacle_principal: any;
  objectif_principal: any;
  autre_objectif_principal: any;
  seuil_temps_rentabilite: any;
  strategie_rentabilite: any;
  montant_levee_fonds: any;
  evolution_croissance_12mois: any;
  capacite_scalabilite: any;
  secteur_projet: any;
  autre_secteur_projet: any;
  marche_cible: any;
  caracteristiques_principales: any;
  obstacle_croissance: any;
  disponibilite_etude_marche: any;
  principaux_concurents: any;
  clients_existants: any;
  strategie_marketing: any;
  autre_strategie_marketing: any;
  existence_levee_fonds: any;
  budget_initial: any;
  financement_illimite: any;
  action_cle_croissance: any;
  autre_action_cle_croissance: any;
  strategie_croissance: any;
  autre_strategie_croissance: any;
  domaine_soutien_strategique: any;
  autre_domaine_soutien_strategique: any;
  ouverture_cogestion: any;
  autre_ouverture_cogestion: any;
  partenaires_reseau: any;
  autre_partenaires_reseaux: any;
  impact_projet: any;
  valeurs_fondamentales: any;
  autre_valeurs_fondamentales: any;
  traduction_valeurs_quotidien: any;
  impact_recherche: any;
  personnage_dessin_anime: any;
  sypnopsis_serie_tv: any;
  documentaire_reussite: any;
  condition_celebration_sucess: any;
  message_futurs_clients: any;
  user_reference: any;

  private errorMessage: string;

  constructor() {
      this.errorMessage = "";
  }

  canSend(): boolean {
      // Vérification des champs obligatoires
      if (!this.nom_projet || this.nom_projet.trim() === "") {
          this.errorMessage = "Veuillez renseigner le nom de votre projet.";
          return false;
      }

      if (!this.description_projet || this.description_projet.trim() === "") {
          this.errorMessage = "Veuillez fournir une description pour votre projet.";
          return false;
      }

      if (!this.stade_projet || this.stade_projet.trim() === "") {
          this.errorMessage = "Veuillez indiquer le stade de développement de votre projet.";
          return false;
      }

      if (!this.presentation_projet_film || this.presentation_projet_film.trim() === "") {
        this.errorMessage = "Si votre projet était un film, quel en serait le titre et pourquoi ?";
        return false;
    }
      if (!this.mission_principale || this.mission_principale.trim() === "") {
          this.errorMessage = "Veuillez indiquer la mission principale de votre projet.";
          return false;
      }

      if (!this.genese_idee || this.genese_idee.trim() === "") {
          this.errorMessage = "Veuillez décrire la genèse de votre idée.";
          return false;
      }

      if (!this.passion_principale || this.passion_principale.trim() === "") {
          this.errorMessage = "Veuillez indiquer quelle est votre passion principale.";

          return false;
        }

        if (!this.force_principale || this.force_principale.trim() === "") {
            this.errorMessage = "Veuillez indiquer votre force principale.";
            return false;
        }

        if (!this.obstacle_principal || this.obstacle_principal.trim() === "") {
            this.errorMessage = "Veuillez indiquer l'obstacle principal que vous rencontrez.";
            return false;
        }

        if (!this.objectif_principal || this.objectif_principal.trim() === "") {
            this.errorMessage = "Veuillez indiquer votre objectif principal.";
            return false;
        }

        if (!this.seuil_temps_rentabilite) {
            this.errorMessage = "Veuillez indiquer le seuil de temps pour atteindre la rentabilité.";
            return false;
        }

        if (!this.strategie_rentabilite || this.strategie_rentabilite.trim() === "") {
            this.errorMessage = "Veuillez indiquer votre stratégie de rentabilité.";
            return false;
        }

        if (!this.montant_levee_fonds) {
            this.errorMessage = "Veuillez indiquer le montant de levée de fonds envisagé.";
            return false;
        }
        if (!this.evolution_croissance_12mois) {
            this.errorMessage = "Comment voyez-vous la croissance de votre projet sur les 12 prochains mois ?";
            return false;
        }
        if (!this.capacite_scalabilite) {
            this.errorMessage = "Pourquoi pensez-vous que votre projet peut se développer à grande échelle ?";
            return false;
        }

      
        if (!this.secteur_projet || this.secteur_projet.trim() === "") {
            this.errorMessage = "Veuillez indiquer le secteur de votre projet.";
            return false;
        }

        if (!this.marche_cible || this.marche_cible.trim() === "") {
            this.errorMessage = "Veuillez indiquer votre marché cible.";
            return false;
        }

        if (!this.caracteristiques_principales || this.caracteristiques_principales.trim() === "") {
            this.errorMessage = "uelles sont les trois principales caractéristiques qui rendent votre projet unique et compétitif ?";
            return false;
        }


        if (!this.obstacle_croissance || this.obstacle_croissance.trim() === "") {
            this.errorMessage = "Veuillez indiquer l'obstacle principal à votre croissance.";
            return false;
        }

        if (!this.disponibilite_etude_marche) {
            this.errorMessage = "Disposez-vous d'une étude de marché ? Si oui, veuillez l'indiquer.";
            return false;
        }

        if (!this.principaux_concurents || this.principaux_concurents.trim() === "") {
            this.errorMessage = "Veuillez indiquer vos principaux concurrents.";
            return false;
        }

        if (!this.clients_existants) {
            this.errorMessage = "Veuillez indiquer si vous avez des clients existants.";
            return false;
        }

        if (!this.strategie_marketing || this.strategie_marketing.trim() === "") {
            this.errorMessage = "Veuillez indiquer votre stratégie marketing.";
            return false;
        }
        if (!this.existence_levee_fonds || this.existence_levee_fonds.trim() === "") {
            this.errorMessage = "Avez-vous déjà levé des fonds pour ce projet ?";
            return false;
        }


        if (!this.budget_initial || this.budget_initial.trim() === "") {
            this.errorMessage = "Quel budget initial avez-vous prévu pour ce projet ?";
            return false;
        }


        if (!this.financement_illimite || this.financement_illimite.trim() === "") {
            this.errorMessage = "Si vous aviez accès à un financement illimité, quelle serait la première  chose que vous feriez pour faire grandir votre projet ?";
            return false;
        }
        if (!this.action_cle_croissance || this.action_cle_croissance.trim() === "") {
            this.errorMessage = "De quoi avez-vous le plus besoin pour accélérer la croissance de votre projet ?";
            return false;
        }

        if (!this.strategie_croissance || this.strategie_croissance.trim() === "") {
            this.errorMessage = "Dans quel domaine avez-vous le plus besoin de notre soutien stratégique ?";
            return false;
        }

        if (!this.ouverture_cogestion || this.ouverture_cogestion.trim() === "") {
            this.errorMessage = "Êtes-vous ouvert(e) à la co-gestion de votre projet avec notre équipe d’experts pour maximiser son potentiel ?";
            return false;
        }

        if (!this.partenaires_reseau || this.partenaires_reseau.trim() === "") {
            this.errorMessage = "Quelles connexions ou partenaires aimeriez-vous avoir dans votre réseau ?";
            return false;
        }

        if (!this.impact_projet || this.impact_projet.trim() === "") {
            this.errorMessage = "En quoi votre projet a-t-il un impact positif (social, environnemental, économique) ?";
            return false;
        }
        if (!this.valeurs_fondamentales || this.valeurs_fondamentales.trim() === "") {
            this.errorMessage = "Veuillez indiquer les valeurs fondamentales de votre projet.";
            return false;
        }

        if (!this.traduction_valeurs_quotidien || this.traduction_valeurs_quotidien.trim() === "") {
            this.errorMessage = "Comment ces valeurs se traduisent-elles dans votre quotidien professionnel ?";
            return false;
        }

        if (!this.impact_recherche || this.impact_recherche.trim() === "") {
            this.errorMessage = "Si votre projet devenait une référence mondiale, quel impact aimeriez-vous qu'il ait sur la société ?";
            return false;
        }
        if (!this.sypnopsis_serie_tv || this.sypnopsis_serie_tv.trim() === "") {
            this.errorMessage = "Imaginez que votre projet soit transformé en série TV. Quel en serait le synopsis ?";
            return false;
        }

        if (!this.documentaire_reussite || this.documentaire_reussite.trim() === "") {
            this.errorMessage = "Quel serait le titre du documentaire Netflix sur la réussite de votre projet ?";
            return false;
        }
        if (!this.message_futurs_clients || this.message_futurs_clients.trim() === "") {
            this.errorMessage = "Si votre projet pouvait parler, que dirait-il à ses futurs clients en un seul slogan ?";
            return false;
        }
        // Si toutes les vérifications sont passées
        this.errorMessage = ""; // Réinitialiser le message d'erreur
        return true;
    }

    getErrorMessage(): string {
        return this.errorMessage;
    }
}
