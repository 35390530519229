import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from '../../../core/services/auth.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-passwordreset',
  templateUrl: './passwordreset.component.html',
  styleUrls: ['./passwordreset.component.scss']
}) 

/**
 * Reset-password component
 */
export class PasswordresetComponent implements OnInit, AfterViewInit {


  email :any;
  constructor(
    private route: ActivatedRoute,
     private router: Router, 
     private authService:AuthService,
     private ngxService : NgxUiLoaderService,
     private messageService : MessageService,

    
    ) { }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
  }

  onSubmit() {
    if (!this.email) {
      this.messageService.add({ severity: 'info', summary: 'zeroInvestissement', detail: "Veuillez renseigner votre adresse email"});
      return;
    }
    this.ngxService.start();
    const requestData ={
      "email_address":this.email
    }
    this.authService.reset(requestData).subscribe(

      (response:any)=>{
        this.ngxService.stop();
        console.log("la reponse  du webservice",response);
        this.messageService.add({ severity: 'success', summary: 'zeroInvestissement', detail: response.message });

      },
      (error)=>{
        this.ngxService.stop();
        console.log("une erreur est survenu",error);
        this.messageService.add({ severity: 'error', summary: 'zeroInvestissement', detail:error });

      }
    )
  }
}
