<div class="container d-flex justify-content-center align-items-center min-vh-100 mt-4">
    <div class="card shadow-lg p-4">
      <div class="row g-4">
        <!-- Texte et boutons -->
        <div class="col-md-6 d-flex flex-column justify-content-center align-items-center">
          <h2 class="card-title" id="font"> <strong id="font">Bienvenue chez Zéro Investissement !</strong></h2>
          <p class="card-text mb-3" id="fontp">
            Découvrez comment on vous accompagne à chaque étape de votre aventure entrepreneuriale. Lancez votre
            <strong>business</strong> avec notre plateforme et l’appui de nos <strong>partenaires</strong> de choc ! Cette vidéo vous fera explorer tous
            <strong>nos services et fonctionnalités</strong> conçus pour booster la croissance de votre entreprise. Suivez les étapes et
            mettez toutes les chances de votre côté pour cartonner !
          </p>
          <div class="mt-3 d-flex align-items-center">
            <button routerLink="/page/dashboard" class="btn btn-primary me-2 d-flex align-items-center"
              style="background-color: #30384a; color: white;">
              Passer cette étape
              <i class="pi pi-angle-right ms-2" style="font-size: 1.6rem"></i>
            </button>
          </div>
        </div>
  
        <!-- Image -->
        <div class="col-md-6 d-flex justify-content-center align-items-center">
          <img src="../../../assets/images/rename.png" class="img-fluid rounded" alt="Coming Soon"
            style="max-height: 400px;">
        </div>
      </div>
    </div>
  </div>
  