import { Component } from '@angular/core';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { OperationService } from 'src/app/shared/service/operation/operation.service';

@Component({
  selector: 'app-listing-incubateur',
  templateUrl: './listing-incubateur.component.html',
  styleUrl: './listing-incubateur.component.scss'
})
export class ListingIncubateurComponent {

  items:any =[];
  user_reference:any ;
  isLoading:boolean = false;

  constructor(
    private OperationService:OperationService,
    private localStorage:LocalStorageService
  ){
    this.user_reference = this.localStorage.getUsers()?.data?.user?.reference;

  }

  ngOnInit(){
    this.getListeProjet();
  }
  getListeProjet(){
    this.isLoading = true;

    const requestData={
      "user_reference":this.user_reference
    }
    this.OperationService.getIncubateur(requestData).subscribe(
      (response:any)=>{
        this.isLoading = false;

        this.items = response.data;
        console.log("la reponse du webservice",response);
        
      },
      (error)=>{
        console.log("une erreur est survenu",error);
        
      },
    )
  }
}
