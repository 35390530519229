
export class MarketPlaceDepot{

    nom_projet_entreprise :any
    elevator_pitch:any
    description_plat_prefere:any
    mission_principale:any
    autre_mission_principale:any
    secteur_activite:any
    explication_potentiel:any
    capacite_scalabilite:any
    potentiel_nombre_clients:any
    chiffre_affaires:any
    caracteristique_cle:any
    fonctionnalite_cle:any
    necessite_competences_specifiques:any
    outils_numeriques_existants:any
    disponibilite_equipe:any
    explication_unicite_business:any
    montant_cession:any
    contenu_cession:any
    slogan_business:any
    disponibilite_accompagnement_acheteur:any
    existence_partenaire:any
    resume_business:any
    pitch_30s:any
    prochain_projet:any
    
    user_reference:any
    


    private errorMessage: string;

    constructor() {
        this.errorMessage = "";
    }

    canSend(): boolean {
        // Vérification des champs obligatoires
        if (!this.nom_projet_entreprise || this.nom_projet_entreprise.trim() === "") {
            this.errorMessage = "Nom de votre projet ou entreprise (et pourquoi ce nom vous semble parfait ?)";
            return false;
        }

        if (!this.elevator_pitch || this.elevator_pitch.trim() === "") {
            this.errorMessage = "En une phrase, vendez-nous votre business comme si vous parliez à quelqu'un dans l'ascenseur";
            return false;
        }

        if (!this.description_plat_prefere || this.description_plat_prefere.trim() === "") {
            this.errorMessage = "Si votre projet était un plat, lequel serait-il et pourquoi ?";
            return false;
        }

        if (!this.mission_principale || this.mission_principale.trim() === "") {
            this.errorMessage = "Quelle est la mission principale de votre business ?";
            return false;
        }

        // if (!this.autre_mission_principale || !this.secteur_activite) {
        //     this.errorMessage = "";
        //     return false;
        // }

        if (!this.secteur_activite || this.secteur_activite.trim() === "") {
            this.errorMessage = "Dans quel secteur d’activité opère votre business et pourquoi l’avez-vous choisi ?";
            return false;
        }

        // Vérification de l'excitation
        // if (!this.services_phares || this.services_phares.trim() === "") {
        //     this.errorMessage = "Quels sont les 3 produits/services phares de votre entreprise qui la rendent unique ?";
        //     return false;
        // }

        // Vérification du plan B
        if (!this.explication_potentiel || this.explication_potentiel.trim() === "") {
            this.errorMessage = "Qu’est-ce qui fait de votre business une machine à succès ?";
            return false;
        }

        // Vérification du mode de rentabilité
        if (!this.capacite_scalabilite) {
            this.errorMessage = "Quel chiffre d’affaires annuel ce projet a-t-il déjà généré (ou peut-il générer) ?";
            return false;
        }

        // // Vérification du public cible
        if (!this.potentiel_nombre_clients) {
            this.errorMessage = "Pourquoi avez-vous décidé de vous lancer dans l'entrepreneuriat ?"
        }
        if (!this.chiffre_affaires) {
            this.errorMessage = "";
            return false;
        }
        if (!this.caracteristique_cle) {
            this.errorMessage = "Si votre business était une licorne, en quoi serait-elle magique ?";
            return false;
        }


        // Vérification de la stratégie marketing
        if (!this.fonctionnalite_cle || this.fonctionnalite_cle.trim() === "") {
            this.errorMessage = "Quelles sont les 3 fonctionnalités clés de votre business qui le rendent irrésistible ?";
            return false;
        }

        // Vérification de la plateforme sociale
        if (!this.necessite_competences_specifiques || !this.necessite_competences_specifiques) {
            this.errorMessage = " Faut-il des compétences spécifiques pour gérer ce business, ou est-il prêt à être pris en main par n’importe qui ?";
            return false;
        }

        // Vérification de l'engagement
        if (!this.outils_numeriques_existants) {
            this.errorMessage = "Avez-vous déjà des outils ou technologies en place (site web, logiciels, plateformes) ?";
            return false;
        }

        //  Vérification des disponibilités
        if (!this.disponibilite_equipe) {
            this.errorMessage = "Avez-vous une équipe qui fonctionne déjà ou tout est prêt à être repris par le futur propriétaire ? ";
            return false;
        }

        // Vérification du soutien
        if (!this.explication_unicite_business) {
            this.errorMessage = "Qu’est-ce qui rend votre business unique et irrésistible pour un acheteur potentiel ?";
            return false;
        }

        if (!this.montant_cession) {
            this.errorMessage = "Quel est le prix que vous avez en tête pour vendre ce projet ? ";
            return false;
        }
        if (!this.contenu_cession) {
            this.errorMessage = "Que comprend exactement l’achat ?";
            return false;
        }

        if (!this.slogan_business) {
            this.errorMessage = "Si votre business devait apparaître dans une pub télé, quel serait le slogan ? ";
            return false;
        }

        if (!this.disponibilite_accompagnement_acheteur) {
            this.errorMessage = "Seriez-vous prêt à accompagner l’acheteur avec une formation initiale ou des conseils ?";
            return false;
        }
        if (!this.existence_partenaire) {
            this.errorMessage = "Avez-vous déjà des partenaires, fournisseurs, ou collaborateurs avec qui l'acheteur pourrait travailler ?";
            return false;
        }

        if (!this.resume_business) {
            this.errorMessage = "Si vous deviez résumer votre business en 3 mots, quels seraient-ils ?";
            return false;
        }
        if (!this.pitch_30s) {
            this.errorMessage = "Imaginez que vous deviez convaincre Elon Musk d’acheter votre business en 30 secondes, que lui diriez-vous ?";
            return false;
        }

        if (!this.prochain_projet) {
            this.errorMessage = ">Quel serait votre prochain projet si vous vendiez celui-ci ? (Racontez-nous votre prochain rêve entrepreneurial)";
            return false;
        }
       
        // Si toutes les vérifications sont passées
        this.errorMessage = ""; // Réinitialiser le message d'erreur
        return true;
    }

    getErrorMessage(): string {
        return this.errorMessage;
    }
}
