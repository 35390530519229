<div *ngIf="afficherFormulair === false" class="d-flex flex-column align-items-center justify-content-center vh-100">
    <div class=" flex justify-content-center ">
        <div class="three col">
            <div class="loader" id="loader-1"></div>
        </div>
    </div>
    <div class="text-center">
            <p class="lead">Vérification en cours, veuillez patienter...</p>
    </div>
</div>
<div class="mainDiv d-flex align-items-center justify-content-center vh-100" *ngIf="afficherFormulair ===true">
    <div class="cardStyle p-4">
        <form action="" method="post" name="signupForm" id="signupForm">
            <img src="../../../../../assets/images/zero.png" id="signupLogo" class="img-fluid mx-auto d-block" />
            <h2 class="formTitle text-center">
                Modifier votre mot de passe
            </h2>

            <div class="inputDiv mb-3">
                <label class="inputLabel" for="password">Nouveau mot de passe</label>
                <div class="input-group">
                    <input type="password" id="password" name="password" [(ngModel)]="resetItem.new_password" required
                        [type]="showPassword ? 'text' : 'password'" class="form-control">
                    <span class="input-group-text" (click)="toggleShowPassword()" style="pointer-events: auto;">
                        <i [ngClass]="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                    </span>
                </div>
            </div>

            <div class="inputDiv mb-3">
                <label class="inputLabel" for="confirmPassword">Confirmer le mot de passe</label>
                <div class="input-group">
                    <input type="password" id="confirmPassword" name="confirmPassword"
                        [(ngModel)]="resetItem.confirm_password" required [type]="showPassword2 ? 'text' : 'password'"
                        class="form-control">
                    <span class="input-group-text" (click)="toggleShowPassword2()" style="pointer-events: auto;">
                        <i [ngClass]="showPassword2 ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                    </span>
                </div>
            </div>

            <div class="buttonWrapper text-center">
                <button type="submit" id="submitButton" (click)="resetPassword()"
                    class="submitButton pure-button pure-button-primary btn btn-primary">
                    <span>Continuer</span>
                </button>
            </div>
        </form>
    </div>
</div>



<p-toast position="top-center" />
<ngx-ui-loader></ngx-ui-loader>