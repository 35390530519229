import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { PagesRoutingModule } from './pages-routing.module';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgbDropdownModule, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DndModule } from 'ngx-drag-drop';
import { NgPipesModule } from 'ngx-pipes';
import { SimplebarAngularModule } from 'simplebar-angular';

import { DashboardComponent } from './dashboard/dashboard.component';

import { RouterModule } from '@angular/router';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PaginatorModule } from 'primeng/paginator';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { WidgetModule } from 'src/app/shared/widget/widget.module';
import { IconsModule } from './icons/icons.module';
import { FranchiseComponent } from './projet-soumis/franchises/franchise/franchise.component';
import { IncubateurComponent } from './projet-soumis/incubateurs/incubateur/incubateur.component';
import { MarketbusnessComponent } from './projet-soumis/marketb/marketbusness/marketbusness.component';
import { MarketplaceComponent } from './projet-soumis/marketD/marketplace/marketplace.component';
import { SoumissionProjetComponent } from './projet-soumis/soumission-projet/soumission-projet.component';
import { SoumissionComponent } from './projet-soumis/soumission/soumission.component';
import { UIModule } from './ui/ui.module';

import { ScrollTopModule } from 'primeng/scrolltop';
import { SiteComponent } from './projet-soumis/sites/site/site.component';
import { ListingSiteComponent } from './projet-soumis/sites/listing-site/listing-site.component';
import { ListingFranchiseComponent } from './projet-soumis/franchises/listing-franchise/listing-franchise.component';
import { ListingMarketDComponent } from './projet-soumis/marketD/listing-market-d/listing-market-d.component';
import { ListingMarketbComponent } from './projet-soumis/marketb/listing-marketb/listing-marketb.component';
import { ListingIncubateurComponent } from './projet-soumis/incubateurs/listing-incubateur/listing-incubateur.component';
import { PageIntermediaireComponent } from './projet-soumis/sites/page-intermediaire/page-intermediaire.component';
import { PremiereConnexionComponent } from '../account/auth/premiere-connexion/premiere-connexion.component';



@NgModule({
  declarations: [
     DashboardComponent,
     IncubateurComponent, 
     SoumissionComponent,
     SoumissionProjetComponent,
     SiteComponent,
     FranchiseComponent,
     MarketplaceComponent,
    MarketbusnessComponent,
    ListingSiteComponent,
    ListingFranchiseComponent,
    ListingMarketDComponent,
    ListingMarketbComponent,
    ListingIncubateurComponent,
    PageIntermediaireComponent,
    PremiereConnexionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    UiModule,
    UIModule,
    NgPipesModule,
    NgbNavModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgApexchartsModule,
    DndModule,
    FullCalendarModule,
    IconsModule,
    LeafletModule,
    WidgetModule,
    SimplebarAngularModule,
    PaginatorModule,
    ConfirmDialogModule,
    ToastModule,
    NgxUiLoaderModule,
    RouterModule,
    TabViewModule,
    ScrollTopModule
  ],
  providers: [
  ]
})
export class PagesModule { }
