import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';

@Component({
  selector: 'app-page-intermediaire',
  templateUrl: './page-intermediaire.component.html',
  styleUrl: './page-intermediaire.component.scss'
})
export class PageIntermediaireComponent {

afficherMessage:any
  constructor(
    private router :Router,
    private localStorage:LocalStorageService
  ){

    this.afficherMessage = this.localStorage.getInfoMessage();
  }

  redirectToList(){
    console.log("info");
    
    if (this.afficherMessage === 1) {
      this.router.navigate(['/page/liste-fun-site']);

    }
    else if (this.afficherMessage === 2) {
      this.router.navigate(['/page/liste-franchise']);

    }

    else if (this.afficherMessage === 3) {
      this.router.navigate(['/page/liste-marketplace-business']);

    }
    else if (this.afficherMessage === 4) {
      this.router.navigate(['/page/liste-marketplace-depot']);

    }
    else if (this.afficherMessage === 5) {
      this.router.navigate(['/page/liste-incubateur']);

    }
  }
}
