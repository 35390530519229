export class ResetUser {

  
    new_password?: any="";
    confirm_password?: any="";
    email?: any="";
    token ?: any="";
   
  
  
    private errorMessage: string;
  
    constructor(){
      this.errorMessage = "";
    }
    
    //get error message
    getErrorMessage(): string {
      return this.errorMessage;
    }//end getErrorMessage
    
    //check for save
    canSave(): boolean {
    
      //nom

      if(this.new_password === undefined || this.new_password === null || this.new_password.trim() === ""){
        this.errorMessage = "Veuillez saisir le nouveau mot de passe"; return false;
      }


      if(this.confirm_password === undefined || this.confirm_password === null || this.confirm_password.trim() === ""){
        this.errorMessage = "Veuillez confirmer le nouveau mot de passe"; return false;
      }
    
    
      if (this.new_password !== this.confirm_password) {
        this.errorMessage = "Le mot de passe et le  mot de passe de confirmation ne correspondent pas";
        return false;
      }
      
      return true;


    }//end canSave

    
  
  
    
   
    
   
  }