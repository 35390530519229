
export class MaFranchise {

    nom_entreprise: any
    personnage_film: any
    description_activite: any
    annees_existence: any
    secteur_activite: any
    produits_phares: any
    grande_reussite
    portefeuille_client: any
    chiffre_affaires: any
    evaluation_scalabilite: any
    raison_transformation_franchise: any
    autre_raison_transformation_franchise: any
    source_excitation: any
    implantation_multiple: any
    projection_nombre_franchises: any
    explication_scalabilite: any
    ressources_cles: any
    donnee_geographique_croissance: any
    pourquoi_franchiser_maintenant: any
    valeurs_cles: any
    devise_inspirante: any
    importance_valeurs: any
    valeurs_futur_franchise: any
    connaissance_principaux_concurents: any
    disponibilite_donnees_analyse: any
    besoin_aide_analyse: any
    role_developpement_franchise: any
    formation_futurs_franchises: any
    outils_essentiels_franchises: any
    entreprise_en_chanson: any
    premiere_action_reussite_franchise: any
    mentor_franchise: any
    user_reference: string;


    private errorMessage: string;

    constructor() {
        this.errorMessage = "";
    }

    canSend(): boolean {
        // Vérification des champs obligatoires
        if (!this.nom_entreprise || this.nom_entreprise.trim() === "") {
            this.errorMessage = "Veuillez renseigner le nom de votre entreprise";
            return false;
        }

        if (!this.personnage_film || this.personnage_film.trim() === "") {
            this.errorMessage = "Si votre entreprise était un personnage de film, lequel serait-ce et pourquoi ?";
            return false;
        }

        if (!this.description_activite || this.description_activite.trim() === "") {
            this.errorMessage = "Décrivez votre activité en une phrase, comme si vous deviez l'expliquer à un enfant de 5 ans ";
            return false;
        }

        if (!this.annees_existence || this.annees_existence.trim() === "") {
            this.errorMessage = "Depuis combien de temps votre entreprise existe-t-elle ?";
            return false;
        }

        if (!this.secteur_activite || !this.secteur_activite) {
            this.errorMessage = "Quel est votre secteur d’activité ?";
            return false;
        }

        if (!this.produits_phares || this.produits_phares.trim() === "") {
            this.errorMessage = "Quels sont les 3 produits/services phares de votre entreprise qui la rendent unique ?";
            return false;
        }

        // Vérification de l'excitation
        if (!this.grande_reussite || this.grande_reussite.trim() === "") {
            this.errorMessage = "Votre entreprise cartonne, mais quelle est la plus grande réussite dont vous êtes fier ?";
            return false;
        }

        // Vérification du plan B
        if (!this.portefeuille_client || this.portefeuille_client.trim() === "") {
            this.errorMessage = "Combien de clients avez-vous servi jusqu’à aujourd’hui ?";
            return false;
        }

        // Vérification du mode de rentabilité
        if (!this.chiffre_affaires) {
            this.errorMessage = "Quel est votre chiffre d’affaires actuel ?";
            return false;
        }

        // // Vérification du public cible
        if (!this.evaluation_scalabilite) {
            this.errorMessage = "Pourquoi souhaitez-vous transformer votre entreprise en franchise ?"
        }
        if (!this.raison_transformation_franchise) {
            this.errorMessage = "Pourquoi souhaitez-vous transformer votre entreprise en franchise  ?";
            return false;
        }
        if (!this.source_excitation) {
            this.errorMessage = "Qu’est-ce qui vous excite le plus à l’idée de voir votre marque à plusieurs endroits";
            return false;
        }


        // Vérification de la stratégie marketing
        if (!this.implantation_multiple || this.implantation_multiple.trim() === "") {
            this.errorMessage = "Votre entreprise est-elle déjà implantée dans plusieurs villes/régions ?";
            return false;
        }

        // Vérification de la plateforme sociale
        if (!this.projection_nombre_franchises || !this.projection_nombre_franchises) {
            this.errorMessage = "Avez-vous déjà une idée du nombre de franchises que vous souhaitez lancer dans les 3 prochaines années ?";
            return false;
        }

        // Vérification de l'engagement
        if (!this.explication_scalabilite) {
            this.errorMessage = "Comment votre entreprise peut-elle être reproduite facilement dans d’autres villes ou pays ?";
            return false;
        }

        //  Vérification des disponibilités
        if (!this.ressources_cles) {
            this.errorMessage = "Quelles sont les ressources clés nécessaires pour lancer une franchise de votre entreprise ?";
            return false;
        }

        // Vérification du soutien
        if (!this.pourquoi_franchiser_maintenant) {
            this.errorMessage = "Votre secteur est-il en pleine croissance ?";
            return false;
        }

        if (!this.donnee_geographique_croissance) {
            this.errorMessage = "Avez-vous déjà envisagé des données géographiques ou des études de marché pour vos futures implantations ?";
            return false;
        }
        if (!this.valeurs_cles) {
            this.errorMessage = "Quelles sont les valeurs clés de votre entreprise ? ";
            return false;
        }

        if (!this.devise_inspirante) {
            this.errorMessage = "Si votre entreprise était une devise inspirante, ce serait…";
            return false;
        }

        if (!this.importance_valeurs) {
            this.errorMessage = "En quoi ces valeurs seront-elles essentielles pour vos futurs franchisés ?";
            return false;
        }
        if (!this.valeurs_futur_franchise) {
            this.errorMessage = "Que recherchez-vous chez un futur franchisé ?";
            return false;
        }

        if (!this.connaissance_principaux_concurents) {
            this.errorMessage = "Connaissez-vous les principaux concurrents dans les zones où vous souhaitez vous implanter ?";
            return false;
        }
        if (!this.disponibilite_donnees_analyse) {
            this.errorMessage = "Disposez-vous déjà de données d’analyse pour identifier les meilleures zones d’implantation ? ";
            return false;
        }

        if (!this.besoin_aide_analyse) {
            this.errorMessage = "Si non, aimeriez-vous que ZeroInvestissement.com vous aide à analyser ces données pour trouver les meilleurs emplacements ?";
            return false;
        }
        if (!this.role_developpement_franchise) {
            this.errorMessage = "Quel rôle souhaitez-vous jouer dans le développement de votre franchise ?";
            return false;
        }
        if (!this.formation_futurs_franchises) {
            this.errorMessage = "Comment imaginez-vous la formation de vos futurs franchisés ?";
            return false;
        }

        if (!this.outils_essentiels_franchises) {
            this.errorMessage = "Quels sont les outils essentiels que vous pensez devoir fournir à vos franchisés ?";
            return false;
        }

        if (!this.entreprise_en_chanson) {
            this.errorMessage = "Si votre entreprise était une chanson, laquelle serait-ce et pourquoi ?";
            return false;
        }

        if (!this.premiere_action_reussite_franchise) {
            this.errorMessage = "Imaginez que votre entreprise devienne la franchise la plus célèbre du pays, quelle serait la  première chose que vous feriez ?";
            return false;
        }




        if (!this.mentor_franchise) {
            this.errorMessage = "Si vous deviez déjeuner avec une autre grande marque franchisée pour obtenir des conseils,laquelle choisiriez-vous et pourquoi ?";
            return false;
        }
        // Si toutes les vérifications sont passées
        this.errorMessage = ""; // Réinitialiser le message d'erreur
        return true;
    }

    getErrorMessage(): string {
        return this.errorMessage;
    }
}
