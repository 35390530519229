<section class="h-100 gradient-form" style="background-color: #eee;">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-xl-10">
        <div class="card rounded-3 text-black">
          <div class="row g-0">
            <div id="gauche" class="col-lg-6 d-none d-lg-flex flex-column align-items-center justify-content-center">
              <div class="card-body p-md-5 mx-md-4 text-center">
                <img src="../../../../assets/images/zero.png" style="width: 185px;" alt="logo">
                <h4 id="family" class="mt-1 mb-5 pb-1">Zéro Investissement, transformez vos idées en réalités.</h4>
              </div>
            </div>
            <div class="col-lg-6 gradient-custom-2 d-flex align-items-center justify-content-center">
              <div class="text-white px-3 py-4 p-md-5 mx-md-4">
                <form>
                  <p id="black" class="text-center">Veuillez vous connecter à votre compte</p>

                  <div class="form-outline mb-4">
                    <label id="black" class="form-label" for="form2Example11">Login</label>
                    <input [(ngModel)]="item.email_address" name="email_address" (keydown.enter)="onSubmit()"type="email" id="form2Example11" class="form-control" placeholder=" Adresse mail " />
                  </div>

                  <div class="form-outline mb-4" style="position: relative;">
                    <label id="black" class="form-label" for="form2Example22">Mot de passe</label>
                    <input [(ngModel)]="item.password" [type]="passwordFieldType" (keydown.enter)="onSubmit()" name="password" id="form2Example22" class="form-control" placeholder="Mot de passe" />
                    
                    <!-- Icône pour afficher/masquer le mot de passe -->
                    <i class="pi" [ngClass]="passwordFieldType === 'password' ? 'pi-eye' : 'pi-eye-slash'"
                       style="position: absolute; right: 10px; top: 50px; cursor: pointer; color: black;" 
                       (click)="togglePasswordVisibility()"></i>
                  </div>
                

                  <div class="text-center pt-1 mb-5 pb-1">

                    <button (click)="onSubmit()" (keydown.enter)="onSubmit()" style="background-color: #6a00b8;"
                      class="btn btn-block fa-lg mb-3" type="button">Connexion</button>
                    &nbsp; <a class="text-muted" href="#!" routerLink="/reset-password">Mot de passe oublié ?</a>
                  </div>

                  <div class="d-flex align-items-center justify-content-center pb-4">
                    <p class="mb-0 me-2" style="color: black;">Vous n'avez pas de compte ? <a href=""
                        routerLink="/signup">S'inscrire</a> </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> 
 <p-toast position="top-center" />
  <ngx-ui-loader></ngx-ui-loader>