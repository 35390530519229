import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProfilComponent } from '../account/auth/profil/profil.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FranchiseComponent } from './projet-soumis/franchises/franchise/franchise.component';
import { IncubateurComponent } from './projet-soumis/incubateurs/incubateur/incubateur.component';
import { MarketbusnessComponent } from './projet-soumis/marketb/marketbusness/marketbusness.component';
import { MarketplaceComponent } from './projet-soumis/marketD/marketplace/marketplace.component';
import { SiteComponent } from './projet-soumis/sites/site/site.component';
import { SoumissionProjetComponent } from './projet-soumis/soumission-projet/soumission-projet.component';
import { SoumissionComponent } from './projet-soumis/soumission/soumission.component';
import { ListingSiteComponent } from './projet-soumis/sites/listing-site/listing-site.component';
import { ListingFranchiseComponent } from './projet-soumis/franchises/listing-franchise/listing-franchise.component';
import { ListingMarketbComponent } from './projet-soumis/marketb/listing-marketb/listing-marketb.component';
import { ListingMarketDComponent } from './projet-soumis/marketD/listing-market-d/listing-market-d.component';
import { ListingIncubateurComponent } from './projet-soumis/incubateurs/listing-incubateur/listing-incubateur.component';
import { PageIntermediaireComponent } from './projet-soumis/sites/page-intermediaire/page-intermediaire.component';
import { PremiereConnexionComponent } from '../account/auth/premiere-connexion/premiere-connexion.component';
;

const routes: Routes = [
    { path: 'dashboard', component: DashboardComponent }, 




    { path: 'projet-soumis', component: SoumissionProjetComponent }, 
    { path: 'soumission', component: SoumissionComponent }, 
    { path: 'incubateur', component: IncubateurComponent }, 
    { path: 'fun-site', component: SiteComponent }, 
    { path: 'franchise', component: FranchiseComponent }, 
    { path: 'marketplace', component: MarketplaceComponent }, 
    { path: 'marketplace-business', component: MarketbusnessComponent }, 

    //listing

    { path: 'liste-fun-site', component: ListingSiteComponent }, 

    { path: 'liste-franchise', component: ListingFranchiseComponent }, 
    { path: 'liste-marketplace-business', component: ListingMarketbComponent }, 
    { path: 'liste-marketplace-depot', component: ListingMarketDComponent }, 
    { path: 'liste-incubateur', component: ListingIncubateurComponent }, 

    { path: 'projet-success', component: PageIntermediaireComponent }, 

    { path: 'profil', component: ProfilComponent }, 
    { path: 'pages', loadChildren: () => import('./utility/utility.module').then(m => m.UtilityModule) },
    { path: 'ui', loadChildren: () => import('./ui/ui.module').then(m => m.UIModule) },
    { path: 'icons', loadChildren: () => import('./icons/icons.module').then(m => m.IconsModule) },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
exports: [RouterModule]
})
export class PagesRoutingModule { }
