<div class="container-fluid ">

    <div>
        <button routerLink="/page/liste-fun-site" type="button" id="retour" class="btn btn-secondary"
            style="right: 10px; width: 100px; position: fixed; z-index: 1000;">
            <i class="pi pi-arrow-left" style="color: white; font-size: 0.8rem;"></i> Retour
        </button>
        <h4 id="upper" class="titre">Questionnaire fun SITE/APPLI sans investissement</h4>
    </div>
    
    <html lang="fr">

    <body>
        <p id="paragraphe" class="text-center">Bienvenue dans notre <strong> questionnaire fun et interactif Mon
                site/Appli ! </strong>Chez
            <strong>ZeroInvestissement.com</strong>, on adore
            transformer des idées en réalité, surtout quand elles sont brillantes comme la vôtre ! Pour qu’on puisse
            bien comprendre votre projet et votre motivation, répondez à ces quelques questions. C’est parti,
            amusez-vous et soyez créatif, on vous attend avec impatience !
        </p>


        <form>
            <h2><span class="emoji">🚀</span>L’Idée qui va tout changer</h2>
            <div class="question">
                <label for="projet-nom">Nom de votre projet (soyez créatif !) :</label>
                <textarea class="form-control" [(ngModel)]="item.nom_projet" id="projet-nom" name="projet-nom"
                    rows="3"></textarea>
            </div>
            <div class="question">
                <label for="secteur">En une phrase, décrivez votre idée comme si vous la pitchiez à un alien (oui, un
                    alien)
                    :</label>
                <textarea class="form-control" [(ngModel)]="item.idee_description" type="text" id="secteur"
                    name="idee_description" rows="3"></textarea>

            </div>
            <div class="question">
                <label for="animal">Si votre projet était un animal, lequel serait-il et pourquoi ?</label>
                <textarea class="form-control" [(ngModel)]="item.animal" type="text" id="animal" name="animal"
                    rows="3"></textarea>
            </div>
            <div class="question">
                <label for="probleme">Quel est le problème majeur que votre idée va résoudre ? </label>
                <textarea class="form-control" [(ngModel)]="item.probleme" type="text" id="probleme" name="probleme"
                    rows="3" placeholder="(On adore les solutions aux problèmes !)"></textarea>
            </div>


            <div class="question">
                <label>Fonctionnalité phare : Quelle est La fonctionnalité qui rendra votre site ou appli indispensable
                    ?</label>
                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.fonctionnalitesphare" type="radio" id="vente" name="fonctionnalite"
                        value="Vente en ligne">
                    <label for="vente" class="me-2">Vente en ligne</label>
                </div>
                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.fonctionnalitesphare" type="radio" id="reservation" name="fonctionnalite"
                        value="Réservation">
                    <label for="reservation" class="me-2">Réservation</label>
                </div>
                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.fonctionnalitesphare" type="radio" id="service_innovant"
                        name="fonctionnalite" value="Service innovant">
                    <label for="service_innovant" class="me-2">Service innovant </label>
                </div>
                <div class=" mb-2">
                    <label for="autre" class="me-2">Autre (expliquez-nous !)</label>
                    <textarea class="form-control" [(ngModel)]="item.autre_fonctionnalitesphare" id="autre" name="autre"
                        placeholder="Autre fonctionnalité" rows="3"></textarea>
                </div>
            </div>




            <h2><span class="emoji">🎯</span>Pourquoi cette idée est-elle la bonne ?</h2>
            <div class="question">
                <label for="public-cible">Comment vous est venue cette idée ?</label>
                <textarea class="form-control" class="form-control" [(ngModel)]="item.idee_origine" id="idee_origine"
                    name="idee_origine" rows="4"
                    placeholder="(Coup de génie en pleine nuit, inspiration de la vie quotidienne, ou conversation avec votre chat ?)"></textarea>
            </div>
            <div class="question">
                <label for="besoins-cible">En une phrase, pourquoi pensez-vous que votre idée est meilleure que ce qui
                    existe déjà ?</label>
                <textarea class="form-control" [(ngModel)]="item.meilleure_idee" id="meilleur_idee" name="meilleur_idee"
                    rows="4" placeholder=""></textarea>
            </div>
            <div class="question">
                <label for="besoins-cible">Qu’est-ce qui vous rend super excité à l’idée de lancer ce projet ?</label>
                <textarea class="form-control" [(ngModel)]="item.super_idee_projet" id="besoins-cible"
                    name="super_idee_projet" rows="4"
                    placeholder="(On veut sentir votre passion à travers l’écran !)"></textarea>
            </div>
            <div class="question">
                <label for="besoins-cible">Quel est votre plan B si tout ne se passe pas comme prévu ?</label>
                <textarea class="form-control" [(ngModel)]="item.plan_b" id="besoins-cible" name="plan_b" rows="4"
                    placeholder="(Ne vous inquiétez pas, c’est juste pour être prêt à tout !)"></textarea>
            </div>

            <h2><span class="emoji">🛠️</span> Votre vision du futur : la rentabilité</h2>
            <div class="question">
                <label for="besoins-cible">Comment votre projet va-t-il rapporter des sous ?</label>
                <textarea class="form-control" [(ngModel)]="item.rentabilite" id="besoins-cible" name="rentabilite"
                    rows="4" placeholder="(ex : Vente directe, abonnements, publicité… soyez clair !)"></textarea>
            </div>



            <div class="question">
                <label>Combien de personnes pensez-vous que votre projet touchera dans sa première année ? (Soyez
                    ambitieux, mais réaliste.)</label>
                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.public_cible" type="radio" id="moins-cent" name="public_cible"
                        value="Moins de 1 000" class="me-2">
                    <label for="moins-cent">Moins de 1 000</label>
                </div>
                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.public_cible" type="radio" id="mille-dixmille" name="public_cible"
                        value="Entre 1 000 et 10 000" class="me-2">
                    <label for="mille-dixmille">Entre 1 000 et 10 000</label>
                </div>
                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.public_cible" type="radio" id="plus-dixmille" name="public_cible"
                        value="Plus de 10 000" class="me-2">
                    <label for="plus-dixmille">Plus de 10 000</label>
                </div>
            </div>


            <div class="question">
                <label>D’ici 2 ans, votre projet aura généré… (une estimation amusante mais réaliste !)</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.seuil_rentabilite" type="radio" id="dixmille" name="seuil_rentabilite"
                        value="10 000 €" class="me-2">
                    <label for="dixmille">10 000 €</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.seuil_rentabilite" type="radio" id="cinquantemille"
                        name="seuil_rentabilite" value="50 000 €" class="me-2">
                    <label for="cinquantemille">50 000 €</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.seuil_rentabilite" type="radio" id="centmille" name="seuil_rentabilite"
                        value="Plus de 100 000 €" class="me-2">
                    <label for="centmille">Plus de 100 000 €</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.seuil_rentabilite" type="radio" id="exploser" name="seuil_rentabilite"
                        value="Ça va exploser tous les compteurs, vous allez voir !" class="me-2">
                    <label for="exploser">Ça va exploser tous les compteurs, vous allez voir !</label>
                </div>
            </div>




            <h2><span class="emoji">🎨</span> Public cible et stratégie marketing (on ne rigole plus)</h2>
            <div class="question">
                <label for="couleurs">Votre cible idéale : Décrivez votre client idéal comme si c’était votre meilleur
                    ami:
                </label>
                <textarea class="form-control" placeholder="(âge, centres d'intérêt, comportements) "
                    [(ngModel)]="item.cible_ideal" type="text" id="cible_ideal" name="cible_ideal" rows="3"></textarea>
            </div>
            <div class="question">
                <label for="couleurs">Avez-vous déjà pensé à une stratégie pour atteindre ces personnes ? Si oui,
                    laquelle ?
                </label>

                <textarea class="form-control" [(ngModel)]="item.strategie_marketing" type="text" id="couleurs"
                    name="strategie_marketing" placeholder="(Campagne publicitaire, réseaux sociaux, influenceurs ?)"
                    rows="3"></textarea>

            </div>

            <div class="question">
                <label>Si vous deviez choisir une plateforme de réseaux sociaux pour promouvoir votre projet, laquelle
                    serait-elle ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.canaux_marketing" type="radio" id="instagram" name="plateforme_sociale"
                        value="Instagram" class="me-2">
                    <label for="instagram">Instagram</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.canaux_marketing" type="radio" id="tiktok" name="plateforme_sociale"
                        value="TikTok" class="me-2">
                    <label for="tiktok">TikTok</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.canaux_marketing" type="radio" id="linkedin" name="plateforme_sociale"
                        value="LinkedIn" class="me-2">
                    <label for="linkedin">LinkedIn</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.canaux_marketing" type="radio" id="facebook" name="plateforme_sociale"
                        value="Facebook" class="me-2">
                    <label for="facebook">Facebook</label>
                </div>

                <div class="mb-2">
                    <label for="autre">Autre</label>
                    <textarea class="form-control" [(ngModel)]="item.autre_canaux_marketing" type="text" id="autre"
                        name="plateforme_sociale_autre" placeholder="Précisez une autre plateforme" rows="3"></textarea>
                </div>
            </div>



            <h2><span class="emoji">💻</span> Rentabilité et Engagement
            </h2>

            <div class="question">
                <label>Êtes-vous prêt à vous engager à 100% pour faire grandir ce projet ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.engagement" type="radio" id="a_fonds" name="engagement"
                        value="Oui, je suis à fond !" class="me-2">
                    <label for="a_fonds">Oui, je suis à fond !</label>
                </div>

                <div class="d-flex align-items-center">
                    <input [(ngModel)]="item.engagement" type="radio" id="autre" name="engagement"
                        value="Oui, mais je continue d'autres projets en parallèle" class="me-2">
                    <label for="autre">Oui, mais je continue d'autres projets en parallèle.</label>
                </div>
            </div>



            <div class="question">
                <label>Quelles sont vos disponibilités ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.seuil_disponibilite" type="radio" id="temps_plein" name="disponibilite"
                        value="Temps plein" class="me-2">
                    <label for="temps_plein">Temps plein</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.seuil_disponibilite" type="radio" id="temps_partiel" name="disponibilite"
                        value="Temps partiel" class="me-2">
                    <label for="temps_partiel">Temps partiel</label>
                </div>

                <div class="d-flex align-items-center">
                    <input [(ngModel)]="item.seuil_disponibilite" type="radio" id="soiree_weekend" name="disponibilite"
                        value="Pendant mes soirées/week-ends" class="me-2">
                    <label for="soiree_weekend">Pendant mes soirées/week-ends</label>
                </div>
            </div>



            <div class="question">
                <label for="hebergement">Avez-vous des fonds, investisseurs ou partenaires prêts à vous soutenir
                    ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.fond_investissement" type="radio" id="yesInvestisseur" name="soutien"
                        value="Oui, j’ai déjà des investisseurs" class="me-2">
                    <label for="yesInvestisseur">Oui, j’ai déjà des investisseurs</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.fond_investissement" type="radio" id="pret_achercher" name="soutien"
                        value="Pas encore, mais je suis prêt à en chercher" class="me-2">
                    <label for="pret_achercher">Pas encore, mais je suis prêt à en chercher</label>
                </div>

                <div class="d-flex align-items-center">
                    <input [(ngModel)]="item.fond_investissement" type="radio" id="noInvestisseur" name="soutien"
                        value="Non, je compte sur ZeroInvestissement.com !" class="me-2">
                    <label for="noInvestisseur">Non, je compte sur ZeroInvestissement.com !</label>
                </div>
            </div>

            <div class="question">
                <label for="hebergement">Si vous deviez vendre votre idée à un investisseur en une phrase, que
                    diriez-vous ?
                </label>
                <textarea class="form-control" [(ngModel)]="item.vendre_idee" type="text" id="couleurs"
                    name="vendre_idee" rows="3"></textarea>


            </div>

            <h2><span class="emoji">📈</span>Laissez votre créativité parler : le design</h2>

            <div class="question">
                <label>Si votre site/appli avait une couleur dominante, ce serait…</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.couleur_dominante" type="radio" id="rouge" name="couleur_dominante"
                        value="Rouge" class="me-2">
                    <label for="rouge">Rouge (énergique, passionné)</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.couleur_dominante" type="radio" id="bleu" name="couleur_dominante"
                        value="Bleu" class="me-2">
                    <label for="bleu">Bleu (calme, fiable)</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.couleur_dominante" type="radio" id="vert" name="couleur_dominante"
                        value="Vert" class="me-2">
                    <label for="vert">Vert (naturel, apaisant)</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.couleur_dominante" type="radio" id="noir" name="couleur_dominante"
                        value="Noir" class="me-2">
                    <label for="noir">Noir (élégant, sophistiqué)</label>
                </div>

                <label for="autre">Autre (surprenez-nous !)</label>
                <textarea class="form-control" [(ngModel)]="item.autre_couleur_dominante" type="text" id="autre"
                    name="autre_couleur_dominante" placeholder="Entrez votre couleur" rows="3"></textarea>
            </div>


            <div class="question">
                <label for="strategie-lancement">Quel est le style de design qui vous attire le plus ?
                </label>
                <textarea class="form-control" [(ngModel)]="item.style_design" id="strategie-lancement"
                    name="style_design" rows="4"
                    placeholder="(Simple et épuré, funky et moderne, ou autre chose… décrivez-nous ça !)"></textarea>
            </div>
            <div class="question">
                <label for="strategie-lancement">Avez-vous des exemples de sites/applis qui vous inspirent ? Si oui,
                    lesquels ?

                </label>
                <textarea class="form-control" [(ngModel)]="item.exemple_sites_applis" id="strategie-lancement"
                    name="exemple_sites_applis" rows="4" placeholder=""></textarea>
            </div>


            <div class="question">
                <label>Avez-vous déjà des éléments graphiques comme un logo ou une charte graphique ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.disponibilite_logo_charte" type="radio" id="yeslogo_app"
                        name="disponibilite_logo_charte" value="Oui, tout est prêt !" class="me-2">
                    <label for="yeslogo_app">Oui, tout est prêt !</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.disponibilite_logo_charte" type="radio" id="nologo_app"
                        name="disponibilite_logo_charte" value="Non, j’aurais besoin d’aide sur ce point" class="me-2">
                    <label for="nologo_app">Non, j’aurais besoin d’aide sur ce point.</label>
                </div>
            </div>



            <h2><span class="emoji">🔧</span>Technique et plateformes</h2>

            <div class="question">
                <label>Pour quel support souhaitez-vous développer votre projet ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.support_projet" type="radio" id="site_web" name="support_projet"
                        value="Site web" class="me-2">
                    <label for="site_web">Site web</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.support_projet" type="radio" id="app_mobile" name="support_projet"
                        value="Application mobile" class="me-2">
                    <label for="app_mobile">Application mobile</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.support_projet" type="radio" id="site_app" name="support_projet"
                        value="Les deux" class="me-2">
                    <label for="site_app">Les deux !</label>
                </div>
            </div>


            <div class="question">
                <label>Sur quelle(s) plateforme(s) souhaitez-vous que votre appli soit disponible ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.plateforme_deploiment" type="radio" id="android" name="plateforme_projet"
                        value="Android" class="me-2">
                    <label for="android">Android</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.plateforme_deploiment" type="radio" id="ios" name="plateforme_projet"
                        value="iOS" class="me-2">
                    <label for="ios">iOS</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.plateforme_deploiment" type="radio" id="android_ios"
                        name="plateforme_projet" value="Les deux" class="me-2">
                    <label for="android_ios">Les deux</label>
                </div>
            </div>

            <div class="question">
                <label>Avez-vous des exigences spécifiques en termes de technologie ou d’hébergement ?
                    (Si oui, dites-nous tout !)

                </label>
                <textarea class="form-control" [(ngModel)]="item.exigence_techniques" id="strategie-lancement"
                    name="exigence_techniques" rows="4"
                    placeholder="(Simple et épuré, funky et moderne, ou autre chose… décrivez-nous ça !)"></textarea>

            </div>

            <h2><span class="emoji">🔧</span>Un peu plus sur vous (on aime bien en savoir plus)</h2>
            <div class="question">
                <label>Quel est votre superpouvoir en tant qu’entrepreneur ?</label>
                <!-- <input class="form-control"  [(ngModel)]="item.super_power" type="text" id="couleurs" name="couleurs"
                    placeholder="(Ex : Je suis une machine à idées, je gère bien le stress, je suis ultra-organisé)"> -->
                <textarea class="form-control" [(ngModel)]="item.super_povoir" type="text" id="couleurs" name="couleurs"
                    placeholder="(Ex : Je suis une machine à idées, je gère bien le stress, je suis ultra-organisé)"
                    rows="3"></textarea>


            </div>
            <div class="question">
                <label>Que faites-vous quand vous ne travaillez pas sur votre projet ?
                </label>
                <textarea class="form-control" [(ngModel)]="item.loisir" type="text" id="couleurs" name="couleurs"
                    placeholder="(On parle de loisirs, bien sûr… ou même de siestes !)" rows="3"></textarea>

            </div>
            <div class="question">
                <label>Si vous pouviez déjeuner avec un entrepreneur célèbre, qui serait-ce et pourquoi ?
                </label>
                <textarea class="form-control" [(ngModel)]="item.entrepreneur_celebre" type="text" id="couleurs"
                    name="couleurs" placeholder="" rows="3"></textarea>

            </div>
            <div class="question">
                <label>
                    <div class="question">
                        <label>Quelle serait la première chose que vous ferez une fois que votre projet sera un succès
                            mondial ?
                        </label>
                        <textarea class="form-control" [(ngModel)]="item.action_sucess_projet" type="text" id="couleurs"
                            name="couleurs" placeholder="" rows="3"></textarea>

                    </div>
                </label>
            </div>

        </form><br>
        <hr>
        <!-- <p id="paragraphe" class="text-left"><strong>Merci d’avoir joué le jeu !
            </strong></p>
        <p id="paragraphe" class="text-left">Nous allons analyser votre projet avec attention et vous recevrez une
            réponse sous 10 jours ouvrés. Nous adorons les idées créatives, rentables et qui ont du punch ! Si votre
            projet est sélectionné, préparez-vous à embarquer dans une aventure extraordinaire avec
            ZeroInvestissement.com.


        </p>
        <p id="paragraphe" class="text-left"> <strong>Pourquoi ce questionnaire ?</strong></p>
        <p id="paragraphe" class="text-left">
            Parce que nous voulons être sûrs de bien comprendre votre vision, vos motivations et surtout le potentiel de
            votre idée. Et comme nous adorons la créativité, vos réponses amusantes nous donneront un vrai aperçu de qui
            vous êtes, et pourquoi votre projet mérite d’être propulsé par notre équipe. Plus nous vous connaissons,
            mieux nous pourrons vous accompagner vers le succès !
        </p> -->
        <div class="d-flex justify-content-center mt-4">
          
            <button (click)="onSubmit()" type="submit" id="but" class="btn btn-primary">Soumettre mon projet <i
                    class="pi pi-send" style="color: white; font-size: 0.8rem;"></i></button>
        </div>

    </body>

    </html>

</div>

<p-toast position="top-right" />
<p-scrollTop styleClass="custom-scrolltop" [threshold]="100" icon="pi pi-arrow-up" />

<ngx-ui-loader></ngx-ui-loader>