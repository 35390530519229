import { Component } from '@angular/core';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';

@Component({
  selector: 'app-premiere-connexion',
  templateUrl: './premiere-connexion.component.html',
  styleUrl: './premiere-connexion.component.scss'
})
export class PremiereConnexionComponent {

  constructor (
    private localStorage:LocalStorageService
    
  ){}
}
