export class registerUser {
    name: string;
    surname: string;
    phone: string;
    postal_code: string;
    city: string;
    country_id : string;
    password: string;
    confirm_password: string;
    email_address: string;

    private errorMessage: string;

    constructor() {
        this.errorMessage = "";
    }

    canSend(): boolean {
        // Vérification du champ "Nom"
        if (!this.name || this.name.trim() === "") {
            this.errorMessage = "Veuillez renseigner votre nom.";
            return false;
        }

        // Vérification du champ "Prénom"
        if (!this.surname || this.surname.trim() === "") {
            this.errorMessage = "Veuillez renseigner votre prénom";
            return false;
        }
        // Vérification de l'adresse email
        if (!this.email_address || this.email_address.trim() === "") {
            this.errorMessage = "Veuillez renseigner votre adresse email";
            return false;
        }
        // Vérification du format de l'adresse email
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(this.email_address.trim())) {
            this.errorMessage = "Veuillez saisir une adresse email valide";
            return false;
        }
        // Vérification du champ "Téléphone"
        if (!this.phone || this.phone.trim() === "") {
            this.errorMessage = "Veuillez renseigner votre numéro de téléphone";
            return false;
        }
        // Format de numéro de téléphone simple (à adapter si besoin)
        // const phonePattern = /^[0-9]{10,15}$/;
        // if (!phonePattern.test(this.phone.trim())) {
        //     this.errorMessage = "Veuillez saisir un numéro de téléphone valide.";
        //     return false;
        // }

        if (!this.country_id  || this.country_id .trim() === "") {
            this.errorMessage = "Veuillez renseigner votre pays";
            return false;
        }
        
         // Vérification du champ "Ville"
         if (!this.city || this.city.trim() === "") {
            this.errorMessage = "Veuillez renseigner votre ville";
            return false;
        }
        // Vérification du champ "Code postal"
        if (!this.postal_code || this.postal_code.trim() === "") {
            this.errorMessage = "Veuillez renseigner votre code postal";
            return false;
        }

       



        // Vérification du mot de passe
        if (!this.password || this.password.trim() === "") {
            this.errorMessage = "Veuillez renseigner votre mot de passe.";
            return false;
        }
        // Vérification de la longueur du mot de passe
        if (this.password.trim().length <= 3) {
            this.errorMessage = "Votre mot de passe est trop court.";
            return false;
        }

        // Vérification de la confirmation du mot de passe
        if (!this.confirm_password || this.confirm_password.trim() === "") {
            this.errorMessage = "Veuillez confirmer votre mot de passe.";
            return false;
        }

        // Vérification que les deux mots de passe correspondent
        if (this.password.trim() !== this.confirm_password.trim()) {
            this.errorMessage = "Les mots de passe ne correspondent pas.";
            return false;
        }

        // Si toutes les vérifications sont passées
        this.errorMessage = ""; // Réinitialiser le message d'erreur
        return true;
    }

    getErrorMessage(): string {
        return this.errorMessage;
    }
}
