<div class="container-fluid ">
    <h4 id="upper" class="titre"> Questionnaire 1 POLE Marketplace Dépôt
    </h4>
    <div class="d-flex justify-content-end">
      <button routerLink="/page/liste-marketplace-depot" type="button" id="retour" class="btn btn-secondary"
            style="right: 10px; width: 100px; position: fixed; z-index: 1000;">
            <i class="pi pi-arrow-left" style="color: white; font-size: 0.8rem;"></i> Retour
        </button>
  </div>
    <html lang="fr">

    <body>
        <!-- <h1>Formulaire de Création de Site Web / Application Mobile</h1> -->
        <p id="paragraphe" class="text-left">Bienvenue dans l’espace <strong>Marketplace Dépôt</strong> de
            <strong>ZeroInvestissement.com </strong>!
            Vous
            avez une idée
            brillante ou un business prêt à décoller et que vous souhaitez vendre sur notre marketplace ? Parfait ! Nous
            voulons en savoir plus sur votre concept, son potentiel, et pourquoi vous pensez qu’il mérite d’être mis en
            avant. Soyez créatif, amusez-vous, et faites-nous rêver !
        </p>
        <form>
            <h2><span class="emoji">🚀</span>L’Essence de Votre Business
            </h2>
            <div class="question">
                <label for="projet-nom">Nom de votre projet ou entreprise (et pourquoi ce nom vous semble parfait ?)
                    :</label>
                <textarea [(ngModel)]="item.nom_projet_entreprise" class="form-control" type="text" id="projet-nom"
                    name="projet-nom" rows="3"></textarea>

            </div>
            <div class="question">
                <label for="secteur">En une phrase, vendez-nous votre business comme si vous parliez à quelqu'un dans
                    l'ascenseur :</label>
                <textarea [(ngModel)]="item.elevator_pitch" class="form-control" type="text" id="secteur"
                    name="elevator_pitch" rows="3"></textarea>

            </div>
            <div class="question">
                <label for="secteur">Si votre projet était un plat, lequel serait-il et pourquoi ?
                </label>
                <textarea [(ngModel)]="item.description_plat_prefere" class="form-control" type="text" id="secteur"
                    name="description_plat_prefere" rows="3"></textarea>
            </div>

            <div class="question">
                <label>Quelle est la mission principale de votre business ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.mission_principale" type="radio" id="site-oui" name="mission_principale"
                        value="Vendre des produits uniques">
                    <label for="site-oui">Vendre des produits uniques</label>
                </div>
                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.mission_principale" type="radio" id="site-non" name="mission_principale"
                        value="Offrir des services innovants">
                    <label for="site-non">Offrir des services innovants</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.mission_principale" type="radio" id="site-non" name="mission_principale"
                        value="Changer le monde (ou au moins votre secteur !)">
                    <label for="site-non">Changer le monde (ou au moins votre secteur !)</label>
                </div>

                <div class="mb-2">
                    <label for="site-non">Autre
                    </label>
                    <textarea rows="3" [(ngModel)]="item.autre_mission_principale" class="form-control" type="text" id="site-non"
                        name="autre_mission_principale"> </textarea>
                </div>

            </div>
            <div class="question">
                <label for="secteur">
                    <div class="question">
                        <label for="secteur">Dans quel secteur d’activité opère votre business et pourquoi l’avez-vous
                            choisi ?
                        </label>

                        <textarea [(ngModel)]="item.secteur_activite" class="form-control" type="text" id="secteur"
                            name="secteur_activite" placeholder="" rows="3"></textarea>

                    </div>

                </label>

            </div>
            <!-- <div class="question">
                <label for="secteur">Quels sont les 3 produits/services phares de votre entreprise qui la rendent unique
                    ?</label>
                <textarea [(ngModel)]="item.services_phares"  class="form-control" type="text" id="secteur" name="services_phares" rows="3"></textarea>

            </div> -->

            <h2><span class="emoji">🎯</span>Potentiel et Scalabilité (Rêvons en grand !)
            </h2>
            <div class="question">
                <label for="public-cible">Qu’est-ce qui fait de votre business une machine à succès ?
                </label>
                <textarea [(ngModel)]="item.explication_potentiel" class="form-control" id="public-cible"
                    name="explication_potentiel" rows="4"
                    placeholder="(Technologie, produit unique, modèle d’affaires ?)"></textarea>
            </div>
            <div class="question">
                <label>Sur une échelle de 1 à 10, à quel point pensez-vous que ce business est duplicable ailleurs ?
                </label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.capacite_scalabilite" type="radio" id="site-oui" name="capacite_scalabilite"
                    value="1 : Très difficile">
                <label for="site-oui">1 : Très difficile</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.capacite_scalabilite" type="radio" id="site-non" name="capacite_scalabilite"
                    value="5 : Ça pourrait marcher">
                <label for="site-non">5 : Ça pourrait marcher</label>
                </div>

                <div  class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.capacite_scalabilite" type="radio" id="site-non" name="capacite_scalabilite"
                    value="10 : Facile à déployer n’importe où">
                <label for="site-non">10 : Facile à déployer n’importe où</label>
                </div>

            </div>
            <div class="question">
                <label>Combien de clients avez-vous actuellement ou espérez-vous toucher ?
                </label>

                <div  class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.potentiel_nombre_clients" type="radio" id="site-oui"
                    name="potentiel_nombre_clients" value=" Moins de 500">
                <label for="site-oui"> Moins de 500</label>
                </div>

                <div  class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.potentiel_nombre_clients" type="radio" id="site-non"
                    name="potentiel_nombre_clients" value="Entre 500 et 2000">
                <label for="site-non"> Entre 500 et 2000</label>
                </div>

               <div  class="d-flex align-items-center mb-2">
                <input [(ngModel)]="item.potentiel_nombre_clients" type="radio" id="site-non"
                name="potentiel_nombre_clients" value="Plus de 2000€">
            <label for="site-non"> Plus de 2000</label>
               </div>
            </div>
            <div class="question">
                <label>Quel chiffre d’affaires annuel ce projet a-t-il déjà généré (ou peut-il générer) ?
                </label>

                <div  class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.chiffre_affaires" type="radio" id="site-oui" name="chiffre_affaires"
                    value="Moins de 50 000 €">
                <label for="site-oui"> Moins de 50 000 €
                </label>
                </div>
                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.chiffre_affaires" type="radio" id="site-non" name="chiffre_affaires"
                    value="Entre 50 000 € et 200 000 €">
                <label for="site-non">Entre 50 000 € et 200 000 €
                </label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.chiffre_affaires" type="radio" id="site-non" name="chiffre_affaires"
                    value="Plus de 200 000 €">
                <label for="site-non"> Plus de 200 000 €</label>
                </div>

            </div>
            <div class="question">
                <label for="public-cible">Si votre business était une licorne, en quoi serait-elle magique ?
                    ?
                </label>
                <textarea [(ngModel)]="item.caracteristique_cle" class="form-control" id="public-cible"
                    name="caracteristique_cle" rows="4" placeholder="(Une caractéristique qui fait toute la différence !)
              "></textarea>
            </div>
            <h2><span class="emoji">🛠️</span>3. Opérations et Technique : Parlons Pratique !
            </h2>
            <div class="question">
                <label for="secteur">Quelles sont les 3 fonctionnalités clés de votre business qui le rendent
                    irrésistible ?
                </label>
                <textarea [(ngModel)]="item.fonctionnalite_cle" class="form-control" type="text" id="secteur"
                    name="fonctionnalite_cle" rows="3"></textarea>

            </div>
            <div class="question">
                <label> Faut-il des compétences spécifiques pour gérer ce business, ou est-il prêt à être pris en
                    main par n’importe qui ?
                </label>


                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.necessite_competences_specifiques" type="radio" id="ecommerce"
                    name="necessite_competences_specifiques" value="Oui, des compétences techniques sont nécessaires">
                    <label>Oui, des compétences techniques sont nécessaires</label>

                </div>
                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.necessite_competences_specifiques" type="radio" id="contact"
                        name="necessite_competences_specifiques" value="Non, il est simple à gérer">
                    <label for="ecommerce">Non, il est simple à gérer</label>

                </div>


            </div>
            <div class="question">
                <label>Avez-vous déjà des outils ou technologies en place (site web, logiciels, plateformes) ?   </label>

                <div class="d-flex align-items-center mb-2" >
                    <input [(ngModel)]="item.outils_numeriques_existants" type="radio" id="ecommerce"
                    name="outils_numeriques_existants" value="Oui, tout est en place">
                <label for="ecommerce"> Oui, tout est en place</label>
                </div>

               <div class="d-flex align-items-center mb-2">
                <input [(ngModel)]="item.outils_numeriques_existants" type="radio" id="contact"
                name="outils_numeriques_existants" value="Non, ça reste à développer">
            <label for="contact"> Non, ça reste à développer</label>
               </div>

            </div>
            <div class="question">
                <label>Avez-vous une équipe qui fonctionne déjà ou tout est prêt à être repris par le futur propriétaire
 ? </label>

                    <div class="d-flex align-items-center mb-2">
                        <input [(ngModel)]="item.disponibilite_equipe" type="radio" id="ecommerce" name="disponibilite_equipe"
                        value="Équipe en place">
                    <label for="ecommerce">Équipe en place</label>
                    </div>

                    <div class="d-flex align-items-center mb-2">
                        <input [(ngModel)]="item.disponibilite_equipe" type="radio" id="contact" name="disponibilite_equipe"
                        value="Business prêt à être repris en solo">
                    <label for="contact">Business prêt à être repris en solo</label>
                    </div>


            </div>
            <h2><span class="emoji">🎨</span>Proposition de Valeur : Pourquoi votre projet est un must-have ?
            </h2>
            <div class="question">
                <label for="couleurs">Qu’est-ce qui rend votre business unique et irrésistible pour un acheteur
                    potentiel ?
                </label>
                <textarea [(ngModel)]="item.explication_unicite_business" class="form-control" type="text" id="couleurs"
                    name="explication_unicite_business" placeholder="" rows="3"></textarea>
            </div>
            <div class="question">
                <label>Quel est le prix que vous avez en tête pour vendre ce projet ? </label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.montant_cession" type="radio" id="ecommerce" name="montant_cession"
                    value="Moins de 10 000 €">
                <label for="ecommerce">Moins de 10 000 €</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.montant_cession" type="radio" id="contact" name="montant_cession"
                    value="Entre 10 000 € et 50 000 €">
                <label for="contact">Entre 10 000 € et 50 000 €</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.montant_cession" type="radio" id="contact" name="montant_cession"
                    value="Plus de 50 000 €">
                <label for="contact">Plus de 50 000 €</label>
                </div>


            </div>
            <div class="question">
                <label for="couleurs">Que comprend exactement l’achat ?</label>

                <textarea [(ngModel)]="item.contenu_cession" class="form-control" type="text" id="couleurs"
                    name="contenu_cession" placeholder="(Ex : Clients existants, stock, manuel d’opération, support…)v" rows="3"></textarea>

            </div>
            <div class="question">
                <label>Si votre business devait apparaître dans une pub télé, quel serait le slogan ? </label>
                <textarea [(ngModel)]="item.slogan_business" class="form-control" type="text" id="couleurs"
                    name="slogan_business" placeholder="" rows="3"></textarea>
            </div>

            <h2><span class="emoji">💻</span>Support et Suivi : Vous accompagnez l’acheteur ?</h2>
            <div class="question">
                <label>Seriez-vous prêt à accompagner l’acheteur avec une formation initiale ou des conseils ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.disponibilite_accompagnement_acheteur" type="radio" id="site-oui"
                    name="disponibilite_accompagnement_acheteur"
                    value=" Oui, je suis ouvert à l'accompagnement initial">
                <label for="site-oui"> Oui, je suis ouvert à l'accompagnement initial
                </label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.disponibilite_accompagnement_acheteur" type="radio" id="site-non"
                    name="disponibilite_accompagnement_acheteur" value="Non, je préfère tout céder d’un coup">
                <label for="site-non"> Non, je préfère tout céder d’un coup
                </label>
                </div>
            </div>
            <div class="question">
                <label>Avez-vous déjà des partenaires, fournisseurs, ou collaborateurs avec qui l'acheteur pourrait
                    travailler ?
                </label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.existence_partenaire" type="radio" id="site-oui" name="existence_partenaire"
                    value="Oui, ils sont prêts à collaborer">
                <label for="site-oui"> Oui, ils sont prêts à collaborer
                </label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.existence_partenaire" type="radio" id="site-non" name="existence_partenaire"
                    value="Non, mais je peux fournir des contacts utiles">
                <label for="site-non">Non, mais je peux fournir des contacts utiles
                </label>
                </div>

            </div>
            <h2><span class="emoji">📈</span>Finalement, un peu de fun pour terminer !
            </h2>

            <div class="question">
                <label for="strategie-lancement">Si vous deviez résumer votre business en 3 mots, quels seraient-ils ?
                </label>
                <textarea [(ngModel)]="item.resume_business" class="form-control" id="strategie-lancement"
                    name="resume_business" rows="4" placeholder=""></textarea>
            </div>
            <div class="question">
                <label for="strategie-lancement">Imaginez que vous deviez convaincre Elon Musk d’acheter votre business
                    en
                    30 secondes, que lui diriez-vous ?
                </label>
                <textarea [(ngModel)]="item.pitch_30s" class="form-control" id="strategie-lancement" name="pitch_30s"
                    rows="4" placeholder=""></textarea>
            </div>
            <div class="question">
                <label for="strategie-lancement">Quel serait votre prochain projet si vous vendiez celui-ci ?
                    (Racontez-nous
                    votre prochain rêve entrepreneurial)
                </label>
                <textarea [(ngModel)]="item.prochain_projet" class="form-control" id="strategie-lancement"
                    name="prochain_projet" rows="4" placeholder=""></textarea>
            </div>


        </form>

        <!-- <p id="paragraphe" class="text-left"><strong>Merci de nous avoir soumis votre projet !</strong></p>
        <p id="paragraphe" class="text-left">
            Notre équipe analysera votre candidature et vous recevrez une réponse sous <strong>10 jour ouvrée</strong>.
            Si votre business
            est sélectionné, il sera publié sur notre marketplace et mis en vente.
            <strong>ZeroInvestissement.com</strong> est prêt à
            vous accompagner pour que votre projet rencontre son futur propriétaire idéal !!!!!!!



        </p> -->

        <div class="d-flex justify-content-center mt-4">
            
            <button (click)="onSubmit()" type="submit" id="but" class="btn btn-primary">Soumettre mon projet <i
                    class="pi pi-send" style="color: white; font-size: 0.8rem;"></i></button>
        </div>
    </body>

    </html>

</div>

<p-toast position="top-right" />
<p-scrollTop styleClass="custom-scrolltop" [threshold]="100" icon="pi pi-arrow-up" />

<ngx-ui-loader></ngx-ui-loader>
