<div class="container-fluid">

    <br><br>
    <h4 id="upper" class="titre">Incubateur / Accélérateur Zéro</h4>

    <div class="d-flex justify-content-end">
      <button routerLink="/page/liste-incubateur" type="button" id="retour" class="btn btn-secondary"
            style="right: 10px; width: 100px; position: fixed; z-index: 1000;">
            <i class="pi pi-arrow-left" style="color: white; font-size: 0.8rem;"></i> Retour
        </button>
  </div>


    <html lang="fr">

    <body>
        <form>
            <p id="paragraphe" class="text-center">
                Bienvenue dans l’univers de l’*Accélérateur Zéro ! Ici, vos idées et entreprises prennent leur envol, sans
                contrainte financière. Que vous soyez au début d’une idée innovante ou à la tête d’une entreprise existante,
                nous sommes là pour vous accompagner à chaque étape. Répondez à ces questions funs, engageantes et précises
                pour que nous puissions analyser votre potentiel et déterminer comment nous pouvons vous aider à transformer
                votre projet en un véritable succès. </p>
            <h2><span class="emoji">🚀</span>Votre Projet : Parlons du cœur de votre idée</h2>
            <div class="question">
                <label for="projet-nom">Nom de votre projet ou entreprise (et pourquoi ce nom est parfait) :</label>
                <textarea  class="form-control" [(ngModel)]="item.nom_projet" type="text" id="projet-nom" name="nom_projet" rows="3"></textarea>
            </div>
            <div class="question">
                <label for="projet-nom">Décrivez votre idée ou entreprise en une phrase qui nous fera tomber sous le
                    charme:
                    </label>
                <textarea class="form-control" [(ngModel)]="item.description_projet" type="text" id="projet-nom" name="description_projet" rows="3"></textarea>

            </div>
            <div class="question">
                <label>À quel stade en êtes-vous avec ce projet ?</label>

                <div class="d-flex align-items-center mb-2">

                    <input type="radio" [(ngModel)]="item.stade_projet" id="site-oui" name="stade_projet" value="simple_idée">
                    <label for="site-oui">Simple idée</label>

                </div>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.stade_projet" id="site-non" name="stade_projet" value="en_developpement">
                    <label for="site-non">En développement
                    </label>
                </div>


                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.stade_projet" id="site-non" name="stade_projet" value="entreprise_existante">
                    <label for="site-non">Entreprise déjà existante </label>
                </div>


            </div>
            <div class="question">
                <label for="secteur">Si votre projet était un film, quel en serait le titre et pourquoi ?

                </label>
                <textarea class="form-control" [(ngModel)]="item.presentation_projet_film" type="text" id="secteur" name="presentation_projet_film" rows="3"></textarea>

            </div>
            <div class="question">
                <label for="secteur">Quelle est la mission principale de votre projet ? </label>
                <textarea class="form-control" [(ngModel)]="item.mission_principale" type="text"  id="secteur" name="mission_principale" placeholder="(Changer une industrie, résoudre un problème, proposer un produit/service unique)
            " rows="3"></textarea>

            </div>


            <h2><span class="emoji">🎯</span>Origine et Motivation : Pourquoi cette idée vous passionne-t-elle ?</h2>
            <div class="question">
                <label for="secteur">Comment l'idée de ce projet vous est-elle venue ?
                </label>

                <textarea class="form-control" [(ngModel)]="item.genese_idee" type="text" id="secteur" name="genese_idee"
                    placeholder="(Un moment eureka, une discussion avec un ami, un besoin non satisfait ?)"
                    rows="3"></textarea>

            </div>
            <div class="question">
                <label>Qu’est-ce qui vous passionne le plus dans ce projet ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.passion_principale" id="site-oui" name="passion_principale" value="defi_entrepreneurial">
                    <label for="site-oui">Le défi entrepreneurial
                    </label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.passion_principale" id="site-non" name="passion_principale" value="impact_projet">
                    <label for="site-non">L'impact que ce projet peut avoir
                    </label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.passion_principale" id="site-non" name="passion_principale" value="potentiel_croissance">
                    <label for="site-non"> Le potentiel de croissance
                    </label>
                </div>

                <div class=" mb-2">
                    <label for="site-non"> Autre </label>
                    <textarea rows="3" class="form-control" [(ngModel)]="item.autre_passion_principale" type="text" id="site-non" name="autre_passion_principale">
                    </textarea>
                    </div>

            </div>

            <div class="question">
                <label>Si vous deviez décrire la plus grande force de votre projet en une phrase, quelle serait-elle ?
                </label>
                <textarea class="form-control" [(ngModel)]="item.force_principale" type="text" id="secteur" name="force_principale" rows="3"></textarea>

            </div>
            <div class="question">
                <label>Quel est le plus grand obstacle auquel vous avez fait face jusqu'à présent dans ce projet ?
                </label>
                <textarea class="form-control" [(ngModel)]="item.obstacle_principal" type="text" id="secteur" name="obstacle_principal" rows="3"></textarea>

            </div>
            <h2><span class="emoji">🛠️</span>L’Avenir : Quels sont vos rêves pour ce projet ?
            </h2>

            <div class="question">
                <label>Quel est votre objectif principal avec ce projet sur les 5 prochaines années ?
                </label>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.objectif_principal" id="ecommerce" name="objectif_principal" value="concquerir_nouveau_marche">
                    <label for="ecommerce">Conquérir un nouveau marché
                    </label>
                </div>


                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.objectif_principal" id="contact" name="objectif_principal" value="creer_marque_incontournable">
                    <label for="contact"> Créer une marque incontournable</label>

                </div>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.objectif_principal" id="blog" name="objectif_principal" value="innover_industrie">
                    <label for="blog">Innover dans une industrie</label>
                </div>

                <div class=" mb-2">
                    <label for="site-non"> Autre </label>
                    <textarea rows="3" class="form-control" [(ngModel)]="item.autre_objectif_principal" type="text" id="site-non" name="autre_objectif_principal" rows="3"></textarea>
                </div>

            </div>

            <div class="question">
                <label>Dans combien de temps pensez-vous que votre projet pourrait être rentable ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.seuil_temps_rentabilite" id="ecommerce" name="seuil_temps_rentabilite" value="moins_6mois">
                    <label for="ecommerce">Moins de 6 mois</label><br>

                </div>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.seuil_temps_rentabilite" id="contact" name="seuil_temps_rentabilite" value="6_12_mois">
                    <label for="contact">Entre 6 et 12 mois</label>

                </div>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.seuil_temps_rentabilite" id="contact" name="seuil_temps_rentabilite" value="Plus">
                    <label for="contact">Plus d’un an</label>

                </div>

            </div>
            <div class="question">
                <label for="couleurs">Quelle est votre stratégie pour atteindre cet objectif ?</label>
                <textarea class="form-control" [(ngModel)]="item.strategie_rentabilite" type="text" id="ecommerce" name="strategie_rentabilite"
                    placeholder="(Développer un produit/service unique, accroître la notoriété, etc.)" value="" rows="3">
                </textarea>
                </div>

            <div class="question">
                <label>Si vous deviez lever des fonds pour ce projet, quel serait votre montant cible ?
                </label>
                <div class="d-flex align-items-center mb-2">
                    <input type="radio"[(ngModel)]="item.montant_levee_fonds" id="ecommerce" name="montant_levee_fonds" value="moins_100000">
                    <label for="ecommerce">Moins de 100 000 €</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.montant_levee_fonds" id="contact" name="montant_levee_fonds" value="100000_500000">
                    <label for="contact"> Entre 100 000 € et 500 000 €</label>
                </div>

                <div class="d-flex align-items-center mb-2" >
                    <input type="radio" [(ngModel)]="item.montant_levee_fonds" id="contact" name="montant_levee_fonds" value="plus_500000">
                    <label for="contact">Plus de 500 000 € </label>
                </div>

            </div>
            <div class="question">
                <label>Comment voyez-vous la croissance de votre projet sur les 12 prochains mois ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.evolution_croissance_12mois" id="ecommerce" name="evolution_croissance_12mois" value="Stable">
                    <label for="ecommerce">Stable, avec une croissance continue
                    </label><br>
                </div>

               <div class="d-flex align-items-center mb-2">
                <input type="radio" [(ngModel)]="item.evolution_croissance_12mois" id="contact" name="evolution_croissance_12mois" value="accelerer">
                <label for="contact"> Accélérée, en atteignant de nouveaux marchés</label>
               </div>

                 <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.evolution_croissance_12mois" id="contact" name="evolution_croissance_12mois" value="explosive">
                    <label for="contact">Explosive, avec une expansion rapide</label><br>
                 </div>

            </div>



            <h2><span class="emoji">🎨</span>Potentiel et Scalabilité
            </h2>
            <div class="question">
                <label for="couleurs">Pourquoi pensez-vous que votre projet peut se développer à grande échelle ?
                </label>
                <textarea rows="3" class="form-control" [(ngModel)]="item.capacite_scalabilite" type="text" id="secteur" name="capacite_scalabilite"
                    placeholder="(ex : Modèle d'affaires flexible, demande élevée du marché, innovation unique) ">
                </textarea>
                    <label for="ecommerce">Dans quel secteur opère votre projet et en quoi est-il innovant ?</label>

                    <div class="d-flex align-items-center mb-2">
                        <input type="radio" [(ngModel)]="item.secteur_projet" id="ecommerce" name="secteur_projet" value="ecommerce">
                        <label for="ecommerce"> E-commerce </label>

                    </div>

                    <div class="d-flex align-items-center mb-2">
                        <input type="radio" [(ngModel)]="item.secteur_projet" id="ecommerce" name="secteur_projet" value="technologie">
                        <label for="ecommerce">Technologie</label>
                    </div>

                    <div class="d-flex align-items-center mb-2">
                        <input type="radio" [(ngModel)]="item.secteur_projet" id="ecommerce" name="secteur_projet" value="santé">
                        <label for="ecommerce"> Santé </label>
                    </div>

                    <div class="mb-2">
                        <label for="ecommerce"> Autre </label>

                        <textarea class="form-control" [(ngModel)]="item.autre_secteur_projet" type="text" id="ecommerce" name="autre_secteur_projet" rows="3"></textarea>
                    </div>


            </div>

            <div class="question">
                <label for="couleurs">Quel est le marché cible de votre projet ?</label>
                <textarea class="form-control" [(ngModel)]="item.marche_cible" type="text" id="ecommerce" name="marche_cible"
                    placeholder="(ex : Enfants, entreprises, consommateurs, etc.)" value="" rows="3"></textarea>
            </div>
            <div class="question">
                <label for="couleurs">Quelles sont les trois principales caractéristiques qui rendent votre projet unique et
                    compétitif ?
                </label>

                <textarea class="form-control" [(ngModel)]="item.caracteristiques_principales" type="text" id="ecommerce" name="caracteristiques_principales"
                    placeholder="" rows="3"></textarea>
            </div>
            <div class="question">
                <label for="couleurs">Qu’est-ce qui pourrait limiter la croissance de votre projet ?

                </label>
                <textarea class="form-control" [(ngModel)]="item.obstacle_croissance" type="text" id="ecommerce" name="obstacle_croissance"
                    placeholder="(Concurrence, manque de financement, obstacles techniques)" value=""
                    rows="3"></textarea>

            </div>



            <h2><span class="emoji">💻</span>Analyse du Marché et Concurrence
            </h2>

            <div class="question">
                <label for="couleurs">Avez-vous déjà réalisé une analyse de marché pour ce projet ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.disponibilite_etude_marche" id="ecommerce" name="disponibilite_etude_marche" value="oui">
                    <label for="ecommerce"> Oui, une analyse complète</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.disponibilite_etude_marche" id="ecommerce" name="disponibilite_etude_marche" value="non">
                    <label for="ecommerce"> Non, je compte sur votre soutien</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.disponibilite_etude_marche" id="ecommerce" name="disponibilite_etude_marche" value="partiellement">
                    <label for="ecommerce"> Partiellement, je travaille encore dessus </label>
                </div>



            </div>
            <div class="question">
                <label for="couleurs">Qui sont vos principaux concurrents, et qu’est-ce qui vous différencie d’eux ?
                </label>

                <textarea class="form-control" [(ngModel)]="item.principaux_concurents" type="text" id="ecommerce" name="principaux_concurents" placeholder=""
                    rows="3"></textarea>
            </div>
            <div class="question">
                <label for="couleurs">Votre projet a-t-il déjà eu des clients ou utilisateurs ? </label>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.clients_existants" id="ecommerce" name="clients_existants" value="oui_retour_excellents">
                    <label for="ecommerce"> Oui, et les retours sont excellents</label>
                </div>

                <div class=" d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.clients_existants" id="ecommerce" name="clients_existants" value="oui_encore_amelorer">
                    <label for="ecommerce"> Oui, mais je dois encore l'améliorer</label>
                </div>


                <div class=" d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.clients_existants" id="ecommerce" name="clients_existants" value="non">
                    <label for="ecommerce">Non, pas encore de </label>
                </div>

            </div>
            <div class="question">
                <label for="couleurs">Comment comptez-vous conquérir votre marché cible ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.strategie_marketing" id="ecommerce" name="strategie_marketing" value="publicite_digitale">
                    <label for="ecommerce"> Publicité digitale</label>
                </div>

                <div class="d-flex align-items-center  mb-2">
                    <input type="radio" [(ngModel)]="item.strategie_marketing" id="ecommerce" name="strategie_marketing" value="reseaux_sociaux">
                    <label for="ecommerce"> Réseaux sociaux </label>
                </div>

                <div class="d-flex  align-items-center  mb-2">
                    <input type="radio" [(ngModel)]="item.strategie_marketing" id="ecommerce" name="strategie_marketing" value="bouche_a_oreille">
                    <label for="ecommerce">Bouche-à-oreille </label>
                </div>

                <div class=" mb-2">
                    <label for="ecommerce">Autres</label>
                    <textarea class="form-control" [(ngModel)]="item.autre_strategie_marketing" type="text" id="ecommerce" name="autre_strategie_marketing" placeholder=""
                    rows="3"></textarea>

                </div>

            </div>
            <h2><span class="emoji">📈</span>Financement et Croissance : Parlons des chiffres ! </h2>
            <div class="question">
                <label>Avez-vous déjà levé des fonds pour ce projet ?
                </label>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.existence_levee_fonds" id="seo-oui" name="existence_levee_fonds" value="oui">
                    <label for="seo-oui">Oui, avec succès </label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.existence_levee_fonds" id="seo-non" name="existence_levee_fonds" value="Non_pas_encore">
                    <label for="seo-non">Non, pas encore
                    </label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.existence_levee_fonds" id="seo-non" name="existence_levee_fonds" value="non_avec_piste">
                    <label for="seo-non"> Non, mais j’ai des pistes</label>
                    </div>



            </div>
            <div class="question">
                <label for="strategie-lancement">Quel budget initial avez-vous prévu pour ce projet ?
                </label>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.budget_initial" id="seo-non" name="budget_initial" value="moins_50000
                    ">
                    <label for="seo-non"> Moins de 50 000 €

                    </label>
                </div>

                <div  class=" d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.budget_initial" id="seo-non" name="budget_initial" value="50000_200000
                    ">
                    <label for="seo-non">Entre 50 000 € et 200 000 €

                    </label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.budget_initial" id="seo-non" name="budget_initial" value="plus">
                <label for="seo-non"> Plus de 200 000 €</label>
                </div>

            </div>
            <div class="question">
                <label for="strategie-lancement ">Si vous aviez accès à un financement illimité, quelle serait la première
                    chose que vous feriez pour faire grandir votre projet ? </label>
                <textarea [(ngModel)]="item.financement_illimite" class="form-control" type="text" id="ecommerce" name="financement_illimite" placeholder=""
                    rows="3"></textarea>

            </div>
            <div class="question">
                <label>De quoi avez-vous le plus besoin pour accélérer la croissance de votre projet ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.action_cle_croissance" id="seo-non" name="action_cle_croissance" value="plus_fonds">
                    <label for="seo-non"> Plus de fonds
                    </label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.action_cle_croissance" id="seo-non" name="action_cle_croissance" value="developper_technologie">
                    <label for="seo-non">Développer une technologie ou un produit
                    </label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.action_cle_croissance" id="seo-non" name="action_cle_croissance" value="ameliorer_marketing">
                    <label for="seo-non"> Améliorer le marketing
                    </label>
                </div>

                <div>
                    <label for="seo-non"> Autre
                    </label>
                    <textarea class="form-control" [(ngModel)]="item.autre_action_cle_croissance" type="text" id="seo-non" name="autre_action_cle_croissance" placeholder=""
                        rows="3"></textarea>
                </div>


            </div>

            <h2><span class="emoji">🔧</span>Soutien Stratégique et Partenariats</h2>

            <div class="question">
                <label>Dans quel domaine avez-vous le plus besoin de notre soutien stratégique ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.strategie_croissance" id="seo-non" name="strategie_croissance" value="developpement_commercial">
                    <label for="seo-non"> Développement commercial
                    </label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.strategie_croissance" id="seo-non" name="strategie_croissance" value="financement_levee_fond">
                    <label for="seo-non"> Financement et levée de fonds</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.strategie_croissance" id="seo-non" name="strategie_croissance" value="strategie_marketing">
                    <label for="seo-non">Stratégie marketing</label>
                </div>

              <div class="d-flex align-items-center mb-2">
                <input type="radio" [(ngModel)]="item.strategie_croissance" id="seo-non" name="strategie_croissance" value="expertise_technologique">
                <label for="seo-non"> Expertise technologique
                </label>
              </div>

              <div class="mb-2">
                <label for="seo-non"> Autre </label>
                <textarea class="form-control" [(ngModel)]="item.autre_strategie_croissance" type="text" id="seo-non" name="autre_strategie_croissance" placeholder=""
                rows="3"></textarea>
              </div>

            </div>
            <div class="question">
                <label>Êtes-vous ouvert(e) à la co-gestion de votre projet avec notre équipe d’experts pour maximiser
                    son
                    potentiel ? </label>

                    <div class=" d-flex align-items-center mb-2">
                        <input type="radio" [(ngModel)]="item.ouverture_cogestion" id="seo-non" name="ouverture_cogestion" value="oui">
                        <label for="seo-non">Oui, je suis prêt(e) à travailler en étroite collaboration</label>
                    </div>

                    <div class=" d-flex align-items-center mb-2">
                        <input type="radio" [(ngModel)]="item.ouverture_cogestion" id="seo-non" name="ouverture_cogestion" value="non">
                        <label for="seo-non"> Non, je préfère gérer seul(e)</label>
                    </div>

                    <div class=" d-flex align-items-center mb-2">
                        <input type="radio" [(ngModel)]="item.ouverture_cogestion" id="seo-non" name="ouverture_cogestion" value="depend_projet">
                        <label for="seo-non">Je ne sais pas encore, cela dépend du projet</label>
                    </div>

                    <div class="mb-2">
                        <label for="seo-non"> Autre </label>

                <textarea class="form-control" [(ngModel)]="item.autre_ouverture_cogestion" type="text" id="seo-non" name="autre_ouverture_cogestion" placeholder=""
                rows="3"></textarea>
                    </div>

            </div>
            <div class="question">
                <label>Quelles connexions ou partenaires aimeriez-vous avoir dans votre réseau ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.partenaires_reseau" id="maj-oui" name="partenaires_reseau" value="investisseurs">
                    <label for="maj-oui">Investisseurs</label>
                </div>

                <div class="d-flex align-items-center mb-2">

                    <input type="radio" [(ngModel)]="item.partenaires_reseau" id="maj-non" name="partenaires_reseau" value="experts_secteur">
                    <label for="maj-non">Experts du secteur</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.partenaires_reseau" id="maj-non" name="partenaires_reseau" value="fournisseurs_distributeurs">
                    <label for="maj-non"> Fournisseurs ou distributeurs
                    </label>
                </div>

                <div class="mb-2">
                    <label for="seo-non"> Autre </label>
                    <textarea class="form-control" [(ngModel)]="item.autre_partenaires_reseaux" type="text" id="seo-non" name="autre_partenaires_reseaux" placeholder="" rows="3"></textarea>
                </div>

            </div>
            <h2><span class="emoji">🔧</span>Impact et Valeurs</h2>
            <div class="question">
                <label for="couleurs">En quoi votre projet a-t-il un impact positif (social, environnemental,
                    économique) ?
                </label>
                <textarea class="form-control" [(ngModel)]="item.impact_projet" type="text" id="ecommerce" name="impact_projet" placeholder=""
                    rows="3"></textarea>

            </div>

            <div class="question">
                <label>Quelles sont les valeurs fondamentales de votre projet ou entreprise ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.valeurs_fondamentales" id="maj-oui" name="valeurs_fondamentales" value="innovation">
                    <label for="maj-oui">Innovation</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.valeurs_fondamentales" id="maj-non" name="valeurs_fondamentales" value="durabilité">
                    <label for="maj-non">Durabilité</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input type="radio" [(ngModel)]="item.valeurs_fondamentales" id="maj-non" name="valeurs_fondamentales" value="service_client_exceptionnel">
                    <label for="maj-non"> Service client exceptionnel
                    </label>
                </div>

                <div class="mb-2">
                    <label for="seo-non"> Autre </label>
                    <textarea class="form-control" [(ngModel)]="item.autre_valeurs_fondamentales" type="text" id="seo-non" name="autre_valeurs_fondamentales" placeholder=""
                    rows="3"></textarea>
                </div>

            </div>
            <div class="question">
                <label for="couleurs">Comment ces valeurs se traduisent-elles dans votre quotidien professionnel ?
                </label>
                <textarea class="form-control" [(ngModel)]="item.traduction_valeurs_quotidien"  type="text" id="seo-non" name="traduction_valeurs_quotidien" placeholder=""
                    rows="3"></textarea>
            </div>
            <div class="question">
                <label for="couleurs">Si votre projet devenait une référence mondiale, quel impact aimeriez-vous qu'il
                    ait
                    sur la société ?
                </label>
                <textarea class="form-control" [(ngModel)]="item.impact_recherche" type="text" id="ecommerce" name="impact_recherche" placeholder="" value="">
                </textarea>
                </div>
            <h2><span class="emoji">🔧</span>Petites dernières pour le Fun </h2>
            <div class="question">
                <label for="couleurs">Si votre projet était un personnage de dessin animé, qui serait-il et pourquoi ?
                </label>
                <textarea class="form-control" [(ngModel)]="item.personnage_dessin_anime" type="text" id="ecommerce" name="personnage_dessin_anime" placeholder=""
                    rows="3"></textarea>
            </div>
            <div class="question">
                <label for="couleurs">Imaginez que votre projet soit transformé en série TV. Quel en serait le synopsis
                    ?
                </label>
                <textarea class="form-control" [(ngModel)]="item.sypnopsis_serie_tv" type="text" id="ecommerce" name="sypnopsis_serie_tv" placeholder=""
                    rows="3"></textarea>
            </div>
            <div class="question">
                <label for="couleurs">Quel serait le titre du documentaire Netflix sur la réussite de votre projet ?
                </label>
                <textarea class="form-control" [(ngModel)]="item.documentaire_reussite" type="text" id="ecommerce" name="documentaire_reussite" placeholder=""
                    rows="3"></textarea>
            </div>
            <div class="question">
                <label for="couleurs">Si vous pouviez organiser une fête pour célébrer le succès de votre projet, où et
                    comment se déroulerait-elle ?
                </label>
                <textarea class="form-control" [(ngModel)]="item.condition_celebration_sucess" type="text" id="ecommerce" name="condition_celebration_sucess" placeholder=""
                    rows="3"></textarea>
            </div>

            <div class="question">
                <label for="couleurs">Si votre projet pouvait parler, que dirait-il à ses futurs clients en un seul
                    slogan ?
                </label>
                <textarea class="form-control" [(ngModel)]="item.message_futurs_clients" type="text" id="ecommerce" name="message_futurs_clients" placeholder=""
                    rows="3"></textarea>
            </div>
        </form><br>
        <div class="description">
            <!-- <p id="paragraphe" class="text-left"> <strong> Merci d’avoir partagé votre projet avec nous ! </strong> <br>
                Nous allons analyser votre dossier et vous recevrez un retour sous 7 jour ouvré. Si votre projet est
                sélectionné, nous organiserons un entretien pour discuter en détail de vos besoins spécifiques et de la
                manière dont ZeroInvestissement.com peut vous accompagner dans cette aventure. Préparez-vous à atteindre de
                nouveaux sommets !



            </p>
            <p id="paragraphe" class="text-left"> <strong>Pourquoi ce questionnaire ? </strong> <br>
                Ce questionnaire est conçu pour nous permettre de bien comprendre votre projet, vos ambitions, et les défis
                auxquels vous faites face. Cela nous aide à identifier comment nous pouvons vous soutenir au mieux et vous
                aider à transformer vos idées en un succès commercial durable.




            </p> -->
            <div class="d-flex justify-content-center mt-4">
                <!-- <button routerLink="/page/soumission" type="button" id="retour" class="btn btn-secondary"
                    style="margin-right: 10px; width: 100px;"> <i class="pi pi-arrow-left"
                        style="color: white; font-size: 0.8rem;"></i> Retour</button> -->
                <button (click)="onSubmit()" type="submit" id="but" class="btn btn-primary">Soumettre mon projet <i class="pi pi-send"
                    style="color: white; font-size: 0.8rem;"></i></button>
            </div><br>
        </div>

    </body>

    </html>

    <br>


</div>
<p-toast position="top-right" />
<ngx-ui-loader></ngx-ui-loader>
<p-scrollTop  styleClass="custom-scrolltop" [threshold]="100" icon="pi pi-arrow-up" />
