<div class="container-fluid ">
    <h4 id="upper" class="titre text text-center">Questionnaire fun Ma franchise sans investissement
    </h4>

    <div>
        <button routerLink="/page/liste-franchise" type="button" id="retour" class="btn btn-secondary"
            style="right: 10px; width: 100px; position: fixed; z-index: 1000;">
            <i class="pi pi-arrow-left" style="color: white; font-size: 0.8rem;"></i> Retour
        </button>
    </div>
    
    <html lang="fr">

    <body>

        <p id="paragraphe" class="text-left">Bienvenue dans notre questionnaire <strong> hyper fun et essentiel</strong>
            pour faire de
            votre entreprise une <strong>franchise à succès sans dépenser un centime</strong> ! Si vous avez une
            entreprise qui cartonne déjà et que vous
            voulez la voir grandir et conquérir de nouveaux territoires, vous êtes au bon endroit. Chez
            <strong> ZeroInvestissement.com</strong>, on adore les entreprises qui rêvent en grand, et on est là pour
            vous aider à
            franchiser sans stress ! <br>
            Alors, prêt à transformer votre entreprise en la prochaine grande franchise ? Répondez à ces questions et
            voyons comment on peut faire décoller tout ça !

        <form>

            <h2><span class="emoji">🚀</span>Votre Entreprise Actuelle : Montrez-nous ce que vous avez !
            </h2>
            <div class="question">
                <label for="projet-nom">Nom de votre entreprise (et pourquoi ce nom ?) :</label>
                <textarea [(ngModel)]="item.nom_entreprise" class="form-control" type="text" id="projet-nom"
                    name="projet-nom" rows="3"></textarea>

            </div>
            <div class="question">
                <label for="secteur">Si votre entreprise était un personnage de film, lequel serait-ce et pourquoi
                    ?:</label>
                <textarea [(ngModel)]="item.personnage_film" class="form-control" class="form-control" type="text"
                    id="secteur" name="secteur" rows="3"></textarea>
            </div>
            <div class="question">
                <label for="secteur">Décrivez votre activité en une phrase, comme si vous deviez l'expliquer à un enfant
                    de
                    5 ans :</label>
                <textarea [(ngModel)]="item.description_activite" class="form-control" class="form-control" type="text"
                    id="description_activite" name="description_activite" rows="3"></textarea>

            </div>

            <div class="question">
                <label>Depuis combien de temps votre entreprise existe-t-elle ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.annees_existence" type="radio" id="option1" name="site-existant"
                        value="Moins d’un an" class="me-2">
                    <label for="option1">Moins d’un an</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.annees_existence" type="radio" id="option2" name="site-existant"
                        value="1 à 3 ans" class="me-2">
                    <label for="option2">1 à 3 ans</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.annees_existence" type="radio" id="option3" name="site-existant"
                        value="Plus de 3 ans" class="me-2">
                    <label for="option3">Plus de 3 ans</label>
                </div>
            </div>

            <div class="question">
                <label for="secteur">Quel est votre secteur d’activité ?
                </label>
                <textarea [(ngModel)]="item.secteur_activite" class="form-control" type="text" id="secteur"
                    name="secteur" placeholder="(ex : restauration, beauté, services, retail, etc.)"
                    rows="3"></textarea>

            </div>
            <div class="question">
                <label for="secteur">Quels sont les 3 produits/services phares de votre entreprise qui la rendent unique
                    ?

                </label>
                <textarea [(ngModel)]="item.produits_phares" class="form-control" type="text" id="secteur"
                    name="secteur" placeholder=""
                    rows="3"></textarea>
            </div>

            <h2><span class="emoji">🎯</span>Le Succès : Racontez-nous votre aventure !
            </h2>
            <div class="question">
                <label for="public-cible">Votre entreprise cartonne, mais quelle est la plus grande réussite dont vous
                    êtes
                    fier ?</label>
                <textarea [(ngModel)]="item.grande_reussite" class="form-control" id="public-cible" name="public-cible"
                    rows="4" placeholder="(Prix, gros contrat, super croissance… impressionnez-nous !)"></textarea>
            </div>
            <div class="question">
                <label>Combien de clients avez-vous servi jusqu’à aujourd’hui ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.portefeuille_client" type="radio" id="client1" name="site-existant"
                        value="Moins de 100" class="me-2">
                    <label for="client1">Moins de 100</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.portefeuille_client" type="radio" id="client2" name="site-existant"
                        value="100 à 500" class="me-2">
                    <label for="client2">100 à 500</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.portefeuille_client" type="radio" id="client3" name="site-existant"
                        value="Plus de 500" class="me-2">
                    <label for="client3">Plus de 500</label>
                </div>
            </div>

            <div class="question">
                <label>Quel est votre chiffre d’affaires actuel ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.chiffre_affaires" type="radio" id="chiffre1" name="chiffre_affaires"
                        value="Moins de 100 000 €" class="me-2">
                    <label for="chiffre1">Moins de 100 000 €</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.chiffre_affaires" type="radio" id="chiffre2" name="chiffre_affaires"
                        value="Entre 100 000 € et 500 000 €" class="me-2">
                    <label for="chiffre2">Entre 100 000 € et 500 000 €</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.chiffre_affaires" type="radio" id="chiffre3" name="chiffre_affaires"
                        value="Plus de 500 000 €" class="me-2">
                    <label for="chiffre3">Plus de 500 000 €</label>
                </div>
            </div>

            <div class="question">
                <label>Sur une échelle de 1 à 10, comment évaluez-vous la scalabilité de votre modèle ?
                    (Indice : Scalabilité = possibilité de reproduire facilement votre concept ailleurs)
                </label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.evaluation_scalabilite" type="radio" id="scalabilite1"
                        name="evaluation_scalabilite" value="1 : Pas encore prêt à se déployer" class="me-2">
                    <label for="scalabilite1">1 : Pas encore prêt à se déployer</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.evaluation_scalabilite" type="radio" id="scalabilite2"
                        name="evaluation_scalabilite" value="5 : Ça pourrait marcher ailleurs" class="me-2">
                    <label for="scalabilite2">5 : Ça pourrait marcher ailleurs</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.evaluation_scalabilite" type="radio" id="scalabilite3"
                        name="evaluation_scalabilite" value="10 : Mon modèle est prêt à conquérir le monde !"
                        class="me-2">
                    <label for="scalabilite3">10 : Mon modèle est prêt à conquérir le monde !</label>
                </div>
            </div>

            <h2><span class="emoji">🛠️</span>Le Grand Saut : Pourquoi franchiser votre entreprise ?</h2>

            <div class="question">
                <label>Pourquoi souhaitez-vous transformer votre entreprise en franchise ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.raison_transformation_franchise" type="radio" id="visibilite"
                        name="raison_transformation_franchise" value="Accroître ma visibilité" class="me-2">
                    <label for="visibilite">Accroître ma visibilité</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.raison_transformation_franchise" type="radio" id="chiffre"
                        name="raison_transformation_franchise" value="Augmenter mon chiffre d’affaires" class="me-2">
                    <label for="chiffre">Augmenter mon chiffre d’affaires</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.raison_transformation_franchise" type="radio" id="partager"
                        name="raison_transformation_franchise" value="Partager mon succès avec d’autres entrepreneurs"
                        class="me-2">
                    <label for="partager">Partager mon succès avec d’autres entrepreneurs</label>
                </div>

                <div class=" mb-2">
                    <label for="autre">Autre (précisez) :</label>
                    <textarea [(ngModel)]="item.autre_raison_transformation_franchise" class="form-control ms-2"
                        id="autre" name="autre_raison_transformation_franchise" rows="3"></textarea>
                </div>
            </div>

            <div class="question">
                <label for="secteur">Qu’est-ce qui vous excite le plus à l’idée de voir votre marque à plusieurs
                    endroits
                </label>
                <textarea [(ngModel)]="item.source_excitation" class="form-control" type="text" id="secteur"
                    name="secteur" placeholder="" rows="3"></textarea>

            </div>
            <div class="question">
                <label>Votre entreprise est-elle déjà implantée dans plusieurs villes/régions ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.implantation_multiple" type="radio" id="implantationOui"
                        name="implantation_multiple" value="Oui" class="me-2">
                    <label for="implantationOui">Oui</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.implantation_multiple" type="radio" id="implantationNon"
                        name="implantation_multiple" value="Non, mais c’est le rêve !" class="me-2">
                    <label for="implantationNon">Non, mais c’est le rêve !</label>
                </div>
            </div>

            <div class="question">
                <label>Avez-vous déjà une idée du nombre de franchises que vous souhaitez lancer dans les 3 prochaines années ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.projection_nombre_franchises" type="radio" id="franchise1" name="projection_nombre_franchises" value="1 à 5 franchises" class="me-2">
                    <label for="franchise1">1 à 5 franchises</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.projection_nombre_franchises" type="radio" id="franchise2" name="projection_nombre_franchises" value="5 à 10 franchises" class="me-2">
                    <label for="franchise2">5 à 10 franchises</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.projection_nombre_franchises" type="radio" id="franchise3" name="projection_nombre_franchises" value="Plus de 10 franchises, je vise grand !" class="me-2">
                    <label for="franchise3">Plus de 10 franchises, je vise grand !</label>
                </div>
            </div>

            <h2><span class="emoji">🎨</span>Les Détails qui Comptent : Scalabilité et Potentiel</h2>
            <div class="question">
                <label for="couleurs">Comment votre entreprise peut-elle être reproduite facilement dans d’autres villes
                    ou
                    pays ?
                </label>
                <textarea [(ngModel)]="item.explication_scalabilite" class="form-control" type="text" id="couleurs"
                    name="explication_scalabilite"
                    placeholder="(Décrivez-nous en quoi votre modèle est adaptable et duplicable)" rows="3"></textarea>

            </div>
            <div class="question">
                <label for="couleurs">Quelles sont les ressources clés nécessaires pour lancer une franchise de votre
                    entreprise ?
                </label>
                <textarea [(ngModel)]="item.ressources_cles" class="form-control" type="text" id="couleurs"
                    name="ressources_cles" placeholder="(Ex : Personnel, matériel spécifique, formation)"
                    rows="3"></textarea>

            </div>
            <div class="question">
                <label for="couleurs">Votre secteur est-il en pleine croissance ? </label>
                <textarea [(ngModel)]="item.pourquoi_franchiser_maintenant" class="form-control" type="text"
                    id="couleurs" name="pourquoi_franchiser_maintenant"
                    placeholder="(Expliquez-nous pourquoi c’est le bon moment de franchiser dans votre domaine)"
                    rows="3"></textarea>

            </div>
            <div class="question">
                <label>Avez-vous déjà envisagé des données géographiques ou des études de marché pour vos futures
                    implantations ?
                </label>


                <textarea [(ngModel)]="item.donnee_geographique_croissance" class="form-control" type="text"
                    id="couleurs" name="donnee_geographique_croissance"
                    placeholder="(Ex : population cible, concurrence locale)" rows="3"></textarea>

            </div>

            <h2><span class="emoji">💻</span>Valeurs et Vision (C’est là qu’on connecte !)
            </h2>

            <div class="question">
                <label for="hebergement">Quelles sont les valeurs clés de votre entreprise ? </label>
                <textarea [(ngModel)]="item.valeurs_cles" class="form-control" type="text" id="couleurs"
                    name="valeurs_cles"
                    placeholder="(Innovation, respect, qualité, service client… dites-nous ce qui vous fait vibrer) "
                    rows="3"></textarea>

            </div>
            <div class="question">
                <label for="hebergement">Si votre entreprise était une devise inspirante, ce serait…</label>
                <textarea [(ngModel)]="item.devise_inspirante" class="form-control" type="text" id="couleurs"
                    name="devise_inspirante"
                    placeholder=""
                    rows="3"></textarea>
            </div>
            <div class="question">
                <label for="hebergement">En quoi ces valeurs seront-elles essentielles pour vos futurs franchisés ?
                </label>
                <textarea [(ngModel)]="item.importance_valeurs" class="form-control" type="text" id="couleurs"
                    name="importance_valeurs" placeholder="(Pourquoi est-ce important qu’ils partagent ces valeurs ?)"
                    rows="3"></textarea>
            </div>
            <div class="question">
                <label for="hebergement">Que recherchez-vous chez un futur franchisé ?
                </label>
                <textarea [(ngModel)]="item.valeurs_futur_franchise" class="form-control" type="text" id="couleurs"
                    name="valeurs_futur_franchise"
                    placeholder="(Engagement, passion, vision d’ensemble… on veut savoir !)" rows="3"></textarea>

            </div>
            <h2><span class="emoji">📈</span>Data et Stratégie (La partie sérieuse mais essentielle)
            </h2>
            <div class="question">
                <label>Connaissez-vous les principaux concurrents dans les zones où vous souhaitez vous implanter ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.connaissance_principaux_concurents" type="radio" id="concurrents-oui" name="concurrents" value="Oui, je les ai bien identifiés" class="me-2">
                    <label for="concurrents-oui">Oui, je les ai bien identifiés</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.connaissance_principaux_concurents" type="radio" id="concurrents-non" name="concurrents" value="Non, pas encore" class="me-2">
                    <label for="concurrents-non">Non, pas encore</label>
                </div>
            </div>

            <div class="question">
                <label for="strategie-lancement">Disposez-vous déjà de données d’analyse pour identifier les meilleures
                    zones d’implantation ? </label>
                <textarea [(ngModel)]="item.disponibilite_donnees_analyse" class="form-control" id="strategie-lancement"
                    name="disponibilite_donnees_analyse" rows="4"
                    placeholder="(Population, revenu moyen, etc.)"></textarea>
            </div>
            <div class="question">
                <label for="strategie-lancement">Si non, aimeriez-vous que ZeroInvestissement.com vous aide à analyser ces données pour trouver les meilleurs emplacements ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.besoin_aide_analyse" type="radio" id="aideOui" name="besoin_aide_analyse" value="Oui, j’ai besoin d’aide !" class="me-2">
                    <label for="aideOui">Oui, j’ai besoin d’aide !</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.besoin_aide_analyse" type="radio" id="aideNon" name="besoin_aide_analyse" value="Non, j’ai déjà tout ce qu’il me faut!" class="me-2">
                    <label for="aideNon">Non, j’ai déjà tout ce qu’il me faut!</label>
                </div>
            </div>



            <h2><span class="emoji">🔧</span>La Gestion et l’Accompagnement : Allons-nous bien bosser ensemble ?</h2>
            <div class="question">
                <label>Quel rôle souhaitez-vous jouer dans le développement de votre franchise ?</label>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.role_developpement_franchise" type="radio" id="role-complet" name="role_developpement" value="Gérer tous les aspects avec nos conseils" class="me-2">
                    <label for="role-complet">Gérer tous les aspects avec nos conseils</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.role_developpement_franchise" type="radio" id="role-deleguer" name="role_developpement" value="Déléguer une partie à nos experts, tout en gardant un œil sur les opérations" class="me-2">
                    <label for="role-deleguer">Déléguer une partie à nos experts, tout en gardant un œil sur les opérations</label>
                </div>

                <div class="d-flex align-items-center mb-2">
                    <input [(ngModel)]="item.role_developpement_franchise" type="radio" id="role-zero" name="role_developpement" value="Laisser ZeroInvestissement.com gérer entièrement le développement et l’accompagnement" class="me-2">
                    <label for="role-zero">Laisser ZeroInvestissement.com gérer entièrement le développement et l’accompagnement</label>
                </div>
            </div>

            <div class="question">
                <label>Comment imaginez-vous la formation de vos futurs franchisés ? </label>
                <textarea [(ngModel)]="item.formation_futurs_franchises" class="form-control" type="text"
                    id="formation_futurs_franchises" name="formation_futurs_franchises"
                    placeholder="(Structure, contenu, durée… partagez-nous votre vision) " rows="3"></textarea>
            </div>
            <div class="question">
                <label>Quels sont les outils essentiels que vous pensez devoir fournir à vos franchisés ?
                </label>
                <textarea [(ngModel)]="item.outils_essentiels_franchises" class="form-control" id="strategie-lancement"
                    name="outils_essentiels_franchises" rows="4"
                    placeholder="(Ex : Manuels d’opération, outils marketing, formations)"></textarea>

            </div>

            <h2><span class="emoji">📈</span>L’aventure commence ! (On s’amuse un peu)</h2>
            <div class="question">
                <label>Si votre entreprise était une chanson, laquelle serait-ce et pourquoi ?
                    ?</label>
                <textarea [(ngModel)]="item.entreprise_en_chanson" class="form-control" type="text" id="couleurs"
                    name="entreprise_en_chanson" placeholder="" rows="3"></textarea>

            </div>
            <div class="question">
                <label>Imaginez que votre entreprise devienne la franchise la plus célèbre du pays, quelle serait la
                    première chose que vous feriez ?</label>
                <textarea [(ngModel)]="item.premiere_action_reussite_franchise" class="form-control" type="text"
                    id="couleurs" name="premiere_action_reussite_franchise" placeholder=" " rows="3"></textarea>
            </div>
            <!-- <div class="question">
                <label>Si vous pouviez déjeuner avec un entrepreneur célèbre, qui serait-ce et pourquoi ?
                </label>
                <textarea [(ngModel)]="item.entrepreneur_celebre" class="form-control" type="text" id="couleurs"
                    name="entrepreneur_celebre" placeholder="" rows="3"></textarea>

            </div> -->
            <div class="question">
                <label>
                    <div class="question">
                        <label>Si vous deviez déjeuner avec une autre grande marque franchisée pour obtenir des
                            conseils,
                            laquelle choisiriez-vous et pourquoi ?
                        </label>
                        <textarea [(ngModel)]="item.mentor_franchise" class="form-control" type="text" id="couleurs"
                            name="mentor_franchise" placeholder="" rows="3"></textarea>
                    </div>
                </label>
            </div>
        </form>

<!-- 
        <p id="paragraphe" class="text-left mb-4 mt-4"> <strong> Merci d’avoir partagé votre vision !</strong></p>
        <p id="paragraphe" class="text-left mb-4 mt-4"> Notre équipe d’experts analysera votre projet avec attention et
            vous recevrez une réponse sous <strong>15 jour
                ouvrée</strong> .
            Si votre modèle est validé, <strong> ZeroInvestissement.com</strong> vous accompagnera
            pour faire de votre entreprise
            la prochaine grande franchise, sans investissement de votre part. On est prêts à vous propulser vers le
            succès, et vous ?
        </p>
        <p id="paragraphe" class="text-left"> <strong>Pourquoi ce questionnaire ?</strong> </p>

        <p id="paragraphe" class="text-left">Nous voulons nous assurer que votre entreprise est prête à franchir un
            cap, que votre modèle est duplicable,
            et que vous partagez nos valeurs. Plus on en sait sur vous et votre vision, mieux on pourra vous accompagner
            pour transformer votre réussite locale en succès national… voire mondial !</p><br> -->
            <div class="d-flex justify-content-center mt-4">
                <button (click)="onSubmit()" type="submit" id="but" class="btn btn-primary">Soumettre mon projet <i class="pi pi-send"
                    style="color: white; font-size: 0.8rem;"></i></button>
            </div>
    </body>

    </html>

</div>
<p-toast position="top-right" />
<ngx-ui-loader></ngx-ui-loader>
<p-scrollTop styleClass="custom-scrolltop" [threshold]="100" icon="pi pi-arrow-up" />
