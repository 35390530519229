import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { latLng, tileLayer } from 'leaflet';


import { ChartType, Stat, Chat, Transaction } from './dashboard.model';

import { statData, revenueChart, salesAnalytics, sparklineEarning, sparklineMonthly, chatData, transactions, spark3Chart, spark2Chart, spark1Chart } from './data';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

/**
 * Dashboard Component
 */
export class DashboardComponent implements OnInit {

  term: any;
  chatData: Chat[];
  transactions: Transaction[];
  statData: Stat[];

  constructor(public formBuilder: UntypedFormBuilder,
    private paramService: ParameterService,
    private localStorage: LocalStorageService
  ) {
    this.user_reference = this.localStorage.getUsers()?.data?.user?.reference;

  }

  // bread crumb items
  breadCrumbItems: Array<{}>;
  statsProjet: any ={};

  projetStatut : any =[];
  user_reference: any;
  // Form submit
  chatSubmit: boolean;
  ngOnInit(): void {
    this.getStatistique();

  }

  getStatistique() {
    const requestData = {
      "user_reference":this.user_reference
    }
    this.paramService.getStatistiqueProjet(requestData).subscribe(
      (response: any) => {
        this.statsProjet = response.data;

        this.projetStatut = this.statsProjet.projets;
        console.log("this.projetStatut",this.projetStatut);
        
        console.log("la reponse du web service", this.statsProjet);
      },
      (error) => {
        console.log("une erreur est survenu", error);
      }
    )
  }


}
