import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AuthenticationService } from '../../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../../core/services/authfake.service';

import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { User } from 'src/app/shared/models/user';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: UntypedFormGroup;
  submitted = false;
  error = '';
  returnUrl: string;

  // set the currenr year
  year: number = new Date().getFullYear();

  // tslint:disable-next-line: max-line-length
  constructor(private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute, private router: Router,
    public authenticationService: AuthenticationService,
    public authFackservice: AuthfakeauthenticationService,
    private messageService: MessageService,
    private authService: AuthService,
    private localStorage: LocalStorageService,
    private ngxService: NgxUiLoaderService

  ) { }

  ngOnInit() {

  }

  item: User = new User()
  premiere_connexion: any;
  isLoading = false; // Variable pour contrôler l'affichage du spinner
  passwordFieldType: string = 'password';

  onSubmit() {

    if (!this.item.canSend()) {
      //  console.log("aa");

      this.messageService.add({ severity: 'info', summary: 'zeroInvestissement', detail: this.item.getErrorMessage() });
      return;
    }

    if (this.item.canSend()) {
      this.ngxService.start();
      this.isLoading = true; // Affiche le spinner

      this.authService.login(this.item).subscribe(
        (response: any) => {
          this.messageService.add({ severity: 'success', summary: 'zeroInvestissement', detail: response.message });
          this.ngxService.stop();

          this.premiere_connexion = response.data.user.premiere_connexion;
          console.log(" this.premiere_connexion", this.premiere_connexion);

          if (this.premiere_connexion === false) {
            this.router.navigate(['bienvenue']);

          }
          if (this.premiere_connexion === true) {
            
            this.router.navigate(['/page/dashboard']);
          }
          console.log("la reponse du webservice", response);

          this.localStorage.saveUsers(response)
        },
        (error) => {
          console.log("une erreur est survenue", error);
          this.messageService.add({ severity: 'error', summary: 'zeroInvestissement', detail: error });
          this.ngxService.stop();

        }
      ).add(() => {
        this.isLoading = false; // Masque le spinner

      });
    }
  }//end onSubmit
  togglePasswordVisibility() {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  }


}
