<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box d-flex justify-content-center" >
        <a href="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/zero.png" alt="" height="70">
          </span>
          <span class="logo-lg">
            <img src="assets/images/zero.png" alt="" height="60">
          </span>
        </a>

        <a href="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/zero.png" alt="" height="60">
          </span>
          <span class="logo-lg" >
            <img src="assets/images/zero.png" alt="" height="120">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="ri-menu-2-line align-middle"></i>
      </button>

      <!-- App Search-->
      

     
    </div>

    <div class="d-flex">

    


      
      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button type="button" class="btn header-item noti-icon waves-effect" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="ri-fullscreen-line"></i>
        </button>
      </div>

   

      <div class="dropdown d-inline-block user-dropdown" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item waves-effect" id="page-header-user-dropdown"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img class="rounded-circle header-profile-user" src="assets/images/profil.png" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1">Admin</span>
          <!-- <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i> -->
        </button>

        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" href="javascript: void(0);"><i class="ri-user-line align-middle me-1"></i>
            Mon profil</a>

          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="ri-shut-down-line align-middle me-1 text-danger"></i>
              Se déconnecter </a>
        </div>
      </div>

     

    </div>
  </div>
</header>
