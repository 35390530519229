
export class Site {
    nom_projet: string; 
    idee_description: string; 
    animal: string; 
    probleme: string; 
    fonctionnalitesphare:any
    autre_fonctionnalitesphare:any
    idee_origine: string; 
    super_idee_projet: string; 
    meilleure_idee: string; 
    plan_b: string;
    platforme_sociale: string; 
    rentabilite: string; 
    public_cible:any;
    seuil_rentabilite:any;
    cible_ideal: string; 
    strategie_marketing: string; 
    exemple_sites_applis:any;
    canaux_marketing:any;
    autre_canaux_marketing:any;
    engagement:any;
    seuil_disponibilite:any; 
    fond_investissement:any; 
    vendre_idee: string; 
    couleur_dominante: any; 
    autre_couleur_dominante: any; 
    style_design: string; 
    disponibilite_logo_charte:any; 
    support_projet: any
    plateforme_deploiment: any; 
    exigence_techniques: string; 
    super_povoir:any;
    loisir:any;
    entrepreneur_celebre;
    action_sucess_projet :any;    
    user_reference: string; 

    private errorMessage: string;

    constructor() {
        this.errorMessage = "";
    }

    canSend(): boolean {
        // Vérification des champs obligatoires
        if (!this.nom_projet || this.nom_projet.trim() === "") {
            this.errorMessage = "Veuillez renseigner le nom de votre projet";
            return false;
        }

        if (!this.idee_description|| this.idee_description.trim() === "") {
            this.errorMessage = "En une phrase, décrivez votre idée comme si vous la pitchiez à un alien (oui, un alien)";
            return false;
        }

        if (!this.animal || this.animal.trim() === "") {
            this.errorMessage = "Si votre projet était un animal, lequel serait-il et pourquoi ?";
            return false;
        }

        if (!this.probleme || this.probleme.trim() === "") {
            this.errorMessage = "Quel est le problème majeur que votre idée va résoudre ? (On adore les solutions aux problèmes !)";
            return false;
        }

        // Vérification de la fonctionnalité phare
        if (!this.fonctionnalitesphare )  {
            this.errorMessage = "Veuillez indiquer au moins une fonctionnalité phare";
            return false;
        }

        if (!this.idee_origine || this.idee_origine.trim() === "") {
            this.errorMessage = "Veuillez expliquer comment vous est venue cette idée";
            return false;
        }
        if (!this.meilleure_idee || this.meilleure_idee.trim() === "") {
            this.errorMessage = "En une phrase, pourquoi pensez-vous que votre idée est meilleure que ce qui existe déjà ?";
            return false;
        }
        if (!this.super_idee_projet || this.super_idee_projet.trim() === "") {
            this.errorMessage = "Qu’est-ce qui vous rend super excité à l’idée de lancer ce projet";
            return false;
        }
       
        // Vérification du plan B
        if (!this.plan_b || this.plan_b.trim() === "") {
            this.errorMessage = "Veuillez indiquer quel est votre plan B";
            return false;
        }

        // Vérification du mode de rentabilité
        if (!this.rentabilite ) {
            this.errorMessage = "Comment votre projet va-t-il rapporter des sous ?";
            return false;
        }

        // // Vérification du public cible
        if (!this.public_cible ) {
            this.errorMessage = "Combien de personnes pensez-vous que votre projet touchera dans sa première année ?";
            return false;
        }
        if (!this.seuil_rentabilite ) {
            this.errorMessage = "D’ici 2 ans, votre projet aura généré… (une estimation amusante mais réaliste !)";
            return false;
        }
        if (!this.cible_ideal ) {
            this.errorMessage = "D’ici 2 ans, votre projet aura généré… (une estimation amusante mais réaliste !)";
            return false;
        }


        // Vérification de la stratégie marketing
        if (!this.strategie_marketing || this.strategie_marketing.trim() === "") {
            this.errorMessage = "Avez-vous déjà pensé à une stratégie pour atteindre ces personnes ? Si oui,laquelle ?";
            return false;
        }

        // Vérification de la plateforme sociale
        if (!this.canaux_marketing ) {
            this.errorMessage = "Si vous deviez choisir une plateforme de réseaux sociaux pour promouvoir votre projet, laquelle serait-elle ?";
            return false;
        }

        // Vérification de l'engagement
        if (this.engagement === undefined) {
            this.errorMessage = "Veuillez indiquer si vous êtes prêt à vous engager à 100%";
            return false;
        }

      //  Vérification des disponibilités
        if (!this.seuil_disponibilite ) {
            this.errorMessage = "Veuillez indiquer vos disponibilités";
            return false;
        }

       // Vérification du soutien
        if (!this.fond_investissement ) {
            this.errorMessage = "Avez-vous des fonds, investisseurs ou partenaires prêts à vous soutenir?";
            return false;
        }
        if (!this.vendre_idee ) {
            this.errorMessage = "Si vous deviez vendre votre idée à un investisseur en une phrase, que diriez-vous ? ";
            return false;
        }

        if (!this.couleur_dominante ) {
            this.errorMessage = "Veuillez choisir votre couleur dominante";
            return false;
        }

        if (!this.style_design ) {
            this.errorMessage = "Quel est le style de design qui vous attire le plus ?";
            return false;
        }
        if (!this.exemple_sites_applis ) {
            this.errorMessage = "Avez-vous des exemples de sites/applis qui vous inspirent ? Si oui,lesquels ?";
            return false;
        }

        if (!this.disponibilite_logo_charte ) {
            this.errorMessage = "Avez-vous déjà des éléments graphiques comme un logo ou une charte graphique ?";
            return false;
        }
        if (!this.support_projet ) {
            this.errorMessage = "Pour quel support souhaitez-vous développer votre projet ?";
            return false;
        }

        if (!this.plateforme_deploiment ) {
            this.errorMessage = "Sur quelle(s) plateforme(s) souhaitez-vous que votre appli soit disponible ?";
            return false;
        }
        if (!this.exigence_techniques ) {
            this.errorMessage = "Avez-vous des exigences spécifiques en termes de technologie ou d’hébergement ?(Si oui, dites-nous tout !)";
            return false;
        }
        if (!this.super_povoir ) {
            this.errorMessage = "Quel est votre superpouvoir en tant qu’entrepreneur ?";
            return false;
        }

        if (!this.loisir ) {
            this.errorMessage = "Que faites-vous quand vous ne travaillez pas sur votre projet ?";
            return false;
        }

        if (!this.entrepreneur_celebre ) {
            this.errorMessage = "Si vous pouviez déjeuner avec un entrepreneur célèbre, qui serait-ce et pourquoi ?";
            return false;
        }

        
        if (!this.action_sucess_projet ) {
            this.errorMessage = "Quelle serait la première chose que vous ferez une fois que votre projet sera un succès mondial ?";
            return false;
        }
        // Si toutes les vérifications sont passées
        this.errorMessage = ""; // Réinitialiser le message d'erreur
        return true;
    }

    getErrorMessage(): string {
        return this.errorMessage;
    }
}
