<div class="container-fluid">
  <app-pagetitle title="Tableau de bord" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <div class="d-flex justify-content-center">
    <div class="col-md-12">
      <div class="container my-5">
        <div class="row g-4">
          <!-- Derniers projets -->
          <div class="col-md-4 d-flex">
            <div class="project-list card h-100 w-100 p-3">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <h5>Mes réalisations</h5>
                <a href="#" class="text-purple">Voir tout</a>
              </div>
              <ul class="list-unstyled" *ngFor="let item of projetStatut | slice: 0:3">
                <li class="d-flex justify-content-between align-items-center mb-3">
                  <div class="d-flex align-items-center">
                    <div class="rounded-circle bg-violet text-white d-flex justify-content-center align-items-center" style="width: 40px; height: 40px;">
                      
                    </div>
                    <div class="ms-3">
                      <strong>{{item.nom_projet}}</strong><br>
                      <small>Jul, 25</small>
                    </div>
                  </div>
                  <span class="text-muted">En attente</span>
                </li>
              </ul>
            </div>
          </div>

          <!-- Statistiques -->
          <div class="col-md-4 d-flex">
            <div class="stats card h-100 w-100 p-3" style="background-color: #6a00b8; color: white;">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <h5 style="color: white;">Statistiques</h5>
                <a href="#" class="text-white">Voir tout</a>
              </div>
              <p>Nombre projets soumis <span class="float-end">{{statsProjet.projects_soumis}}</span></p>
              <div class="progress mb-3">
                <div class="progress-bar" role="progressbar" [ngStyle]="{'width': statsProjet.projects_soumis + '%' }"></div>
              </div>

              <p>Nombre projet rejetés <span class="float-end">{{statsProjet.rejets}}</span></p>
              <div class="progress mb-3">
                <div class="progress-bar" role="progressbar" [ngStyle]="{'width': statsProjet.rejets + '%' }"></div>
              </div>

              <p>En attente de validation <span class="float-end">{{statsProjet.en_attente}}</span></p>
              <div class="progress">
                <div class="progress-bar" role="progressbar" [ngStyle]="{'width': statsProjet.en_attente + '%' }"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
