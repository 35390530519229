<section class="h-100 gradient-form" style="background-color: #eee;">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-xl-10">
        <div class="card rounded-3 text-black">
          <div class="row g-0">
            <!-- Partie gauche avec image et texte -->
            <div id="gauche" class="col-lg-4 d-none d-lg-flex flex-column align-items-center justify-content-center">
              <div class="p-md-5 mx-md-4 text-center">
                <img src="../../../../assets/images/zero.png" style="width: 185px;" alt="logo">
                <h4 id="family" class="mt-1 mb-5 pb-1">Zéro Investissement, transformez vos idées en réalités.</h4>
              </div>
            </div>

            <!-- Partie droite avec le formulaire d'inscription -->
            <div class="col-lg-8 gradient-custom-2 d-flex align-items-center justify-content-center">
              <div class="text-white px-3 py-4 p-md-5 mx-md-4 w-100">

                <!-- Step 1 Form -->
                <form >
                  <p id="black" class="text-center mb-4">Veuillez vous inscrire à votre compte</p>
                  <h3 class="text-center"> Rejoignez notre communauté en remplissant les informations ci-dessous.</h3>

                  <!-- Nom et Prénom -->
                  <div class="row">
                    <div class="col-md-6 form-group mb-4">
                      <label id="black" class="form-label" for="firstName">Nom</label>
                      <input [(ngModel)]="item.name" name="nom" type="text" id="firstName" class="form-control" placeholder="Nom" />
                    </div>
                    <div class="col-md-6 form-group mb-4">
                      <label id="black" class="form-label" for="lastName">Prénom</label>
                      <input [(ngModel)]="item.surname" name="surname" type="text" id="lastName" class="form-control" placeholder="Prénom" />
                    </div>
                  </div>

                  <!-- Email et Téléphone -->
                  <div class="row">
                    <div class="col-md-6 form-group mb-4">
                      <label id="black" class="form-label" for="email">Email</label>
                      <input [(ngModel)]="item.email_address" name="email_address" type="email" id="email" class="form-control" placeholder="Email" />
                    </div>
                    <div class="col-md-6 form-group mb-4">
                      <label id="black" class="form-label" for="telephone">Téléphone</label>
                      <input #numeroInput [(ngModel)]="item.phone" name="phone" type="tel" class="form-control" placeholder="Téléphone"  />

                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 form-group mb-4">
                      <label id="black" class="form-label" for="Pays">Pays</label>
                      <select name="pays" id="" class="form-select" [(ngModel)]="item.country_id">

                        <option value="null"> Sélectionner un pays</option>
                        <option *ngFor="let item of paysItems" [value]="item.id" > {{item.name}}</option>
                      </select>
                    </div>
                    <div class="col-md-6 form-group mb-4">
                      <label id="black" class="form-label" for="Ville">Ville</label>
                      <!-- <select [(ngModel)]="item.city_id" name="ville" id="ville-select" class="form-select">
                        <option [ngValue]="null">Sélectionner une ville</option>
                        <option *ngFor="let item of villeItems" [value]="item.id">{{ item.name }}</option>
                      </select> -->
                      <input [(ngModel)]="item.city" name="ville" type="text" id="ville" class="form-control" placeholder="Ville" />

                    </div>
                  </div>

                  <!-- Bouton Suivant -->
                   <!-- Pays-->
                   <div class="row">
                    <div class="col-md-12 form-group mb-4">
                      <label id="black" class="form-label" for="CodePostal">Code Postal</label>
                      <input [(ngModel)]="item.postal_code" name="postal_code" type="text" id="CodePostale" class="form-control" placeholder="Code Postal" />
                    </div>
                  </div>
                  <!-- Mot de passe et Confirmation de mot de passe -->
                  <div class="row">
                    <div class="form-outline mb-4 col-md-6" style="position: relative;">
                      <label id="black" class="form-label" for="form2Example22">Mot de passe</label>
                      <input [(ngModel)]="item.password" [type]="passwordFieldType" name="password" id="form2Example22" class="form-control" placeholder="Mot de passe" />
                      
                      <!-- Icône pour afficher/masquer le mot de passe -->
                      <i class="pi" [ngClass]="passwordFieldType === 'password' ? 'pi-eye' : 'pi-eye-slash'"
                         style="position: absolute; right: 20px; top: 50px; cursor: pointer; color: black;" 
                         (click)="togglePasswordVisibility()"></i>
                    </div>

                    <div class="form-outline mb-4 col-md-6" style="position: relative;">
                      <label id="black" class="form-label" for="form2Example22">Confirmer le mot de passe</label>
                      <input  [(ngModel)]="item.confirm_password" [type]="passwordFieldTypeConfirm" name="confirm_password" id="formExample22" class="form-control" placeholder="Confirmer le mot de passe" />
                      
                      <!-- Icône pour afficher/masquer le mot de passe -->
                      <i class="pi" [ngClass]="passwordFieldTypeConfirm === 'password' ? 'pi-eye' : 'pi-eye-slash'"
                         style="position: absolute; right: 20px; top: 50px; cursor: pointer; color: black;" 
                         (click)="togglePasswordVisibilityConfirm()"></i>
                    </div>
                  </div>

                  <div class="text-center pt-1 mb-5 pb-1">
                    <button (click)="onSubmit()" style="background-color: #6a00b8; color: white; width: 200px;"
                      class="btn btn-block fa-lg" type="submit">Soumettre
                    </button>
                  </div>
                  <div class="d-flex align-items-center justify-content-center pb-4">
                    <p class="mb-0 me-2" style="color: black;">Vous avez déjà un compte ? <a href=""
                        routerLink="/login">Se
                        connecter </a></p>
                  </div>
                </form>

               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<p-toast position="top-center" />
<ngx-ui-loader></ngx-ui-loader>