
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { reference } from '@popperjs/core';



@Injectable()
export class OperationService {

  constructor(private http: HttpClient) {
  }

  addProjetSite(item: any): Observable<any> {
    //console.log("webservice");

    return this.http.post(environment.apiUrl + 'operation/site', item);
  }

  addProjetMaFranchise(item: any): Observable<any> {
    //console.log("webservice");

    return this.http.post(environment.apiUrl + 'operation/franchise', item);
  }

  addProjetMarketPlaceBusiness(item: any): Observable<any> {
    //console.log("webservice");

    return this.http.post(environment.apiUrl + 'operation/market-business', item);
  }


  
  addProjetMarketDepot(item: any): Observable<any> {
    //console.log("webservice");

    return this.http.post(environment.apiUrl + 'operation/market-depot', item);
  }

  addProjetIncubateur(item: any): Observable<any> {
    //console.log("webservice");

    return this.http.post(environment.apiUrl + 'operation/incubateur', item);
  }



  
  getSite(item:any): Observable<any> {
    return this.http.post(`${environment.apiUrl + 'operation/user-sites'}`,item);
  }

  

  getMafranchise(item:any): Observable<any> {
    return this.http.post(`${environment.apiUrl + 'operation/franchises'}`,item);
  }

  getMarketPlaceBusiness(item:any): Observable<any> {
    return this.http.post(`${environment.apiUrl + 'operation/markets-business'}`,item);
  }
  getMarketplaceDepot(item:any): Observable<any> {
    return this.http.post(`${environment.apiUrl + 'operation/markets-depots'}`,item);
  }
  getIncubateur(item:any): Observable<any> {
    return this.http.post(`${environment.apiUrl + 'operation/poles-incubateurs'}`,item);
  }

}

