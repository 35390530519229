<div class="container-fluid">

    <h4 class="text-center" id="upper">Listes de vos Projets Ma franchise
    </h4>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div>
                        <!--  -->
                        <a routerLink="/page/franchise" href="javascript:void(0);" class="btn btn-success mb-2" id="gras">
                            <i class="mdi mdi-plus me-2"></i> Soumettre un nouveau projet
                        </a>
                    </div>
                    <!-- <div class="col-md-12 d-flex justify-content-end">
                        <div id="tickets-table_filter" class="dataTables_filter d-flex align-items-center ">
                            <label  style="white-space: nowrap;" class=" ">  Rechercher par statut:</label>
                            <input type="text" name="searchTerm" class="form-control form-control-sm ms-2" aria-controls="tickets-table">
                        </div>
                    </div> -->

                    <div class="container d-flex justify-content-center align-items-center mt-4" >
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table table-centered datatable dt-responsive nowrap"
                                    style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                                    <thead class="thead-light rwd-table">
                                        <tr>
                                            <th style="width: 20px;"></th>
                                            <th>Projet</th>
                                            <th>Statut</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of Items">
                                            <td></td>
                                            <td>{{item.nom_entreprise}}</td>
                                            <td>{{item.statut}}</td>
                                            <td>
                                                <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Voir plus">
                                                    <i class="pi pi-window-maximize" style="color: #672A90; font-size: 2rem;"></i>

                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center" *ngIf="isLoading === true">
                        <div class="container">
                          <div class="row">
                            <div class="text-center">
                              <h5 id="graycolor">Chargement en cours...</h5>
                              <div class="loader4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                </div>
            </div>

        </div>

    </div>
</div>

<p-confirmDialog />
<p-toast />
<ngx-ui-loader></ngx-ui-loader>